import axios from "axios"

export const checkThread = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/checkThread`,
            params: data
        })

        if(response.data.status == "active") {
            return {
                status: "active",
                content: response.data.content.data
            }
        }

        return {
            thread_status: response.data.data.status,
            status: "success",
            cid: response.data.data.cid
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const sendMessage = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/sendMessage`,
            params: data
        })

        return {
            status: "success",
            content: response.data.data.data
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const sendContact = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://api.wizhull.com/applyLead`,
            data: data
        })

        return {
            status: response.data.status
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const sendNewsletter = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://api.wizhull.com/appleNewsletter`,
            data: data
        })

        return {
            status: response.data.status
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const fetchBlogRecent = async (token) => {
    try {
        let response = await axios.get('https://blog.wizhull.com/api/blogs?sort=createdAt:desc&pagination[limit]=3&fields[0]=Title&fields[1]=createdAt', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return {
            status: response.status == 200 ? "success" : "failed",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const fetchBlogCategories = async (token) => {
    try {
        let response = await axios.get('https://blog.wizhull.com/api/categories?populate[blogs][count]=true', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return {
            status: response.status == 200 ? "success" : "failed",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const fetchBlogs = async (token, page, category) => {
    try {
        if(category != "") category = `&filters[categories][Title][$in]=${category}`
        let response = await axios.get(`https://blog.wizhull.com/api/blogs?sort=createdAt:desc${category}&pagination[page]=${page}&pagination[pageSize]=2&fields[0]=Title&fields[1]=Excerpt&fields[2]=createdAt&fields[3]=slug&populate=Image,categories`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        let checkLimit = response.data.meta.pagination.total / 2  <= response.data.meta.pagination.page ? true : false

        return {
            status: response.status == 200 ? "success" : "failed",
            data: response.data.data,
            limit: checkLimit
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const fetchBlogDetail = async (token, blogId) => {
    try {
        let response = await axios.get(`https://blog.wizhull.com/api/blogs?filters[slug][$eq]=${blogId}&populate=*`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return {
            status: response.status == 200 ? "success" : "failed",
            data: response.data.data[0]
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const fetchRandomBlog = async (token) => {
    try {
        let response = await axios.get(`https://blog.wizhull.com/api/blogs?&sort=createdAt:desc&pagination[limit]=2&fields[0]=Title&fields[1]=slug&populate=Image,categories`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return {
            status: response.status == 200 ? "success" : "failed",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}

export const saveChatbotLead = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://api.wizhull.com/applyChatbotLead`,
            data: data
        })

        return {
            status: response.data.status
        }
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message
        }
    }
}