import React, { useState } from "react"
import SideFooter from "../Component/Footers/SideFooter"
import funFactBG from "../assets/img/fun-fact-bg.png"
import SideHeaders from "../Component/Headers/SideHeaders"
import Lottie from "react-lottie"
import animJson from "../assets/lottie/hero-ad.json"
import successJson from "../assets/lottie/tick.json"

import campaignImg1 from "../assets/img/campaign-img-1.jpg"
import campaignImg2 from "../assets/img/campaign-img-2.jpg"
import campaignImg3 from "../assets/img/campaign-img-3.jpg"
import campaignImg4 from "../assets/img/campaign-img-4.jpg"

import silhoutte from "../assets/img/backgroundSvg.svg"
import progress1 from "../assets/img/progress-1.svg"
import progress2 from "../assets/img/progress-2.svg"
import progress4 from "../assets/img/progress-4.svg"
import Calculator from "../Component/Calculator"
import { Link } from "react-router-dom"
import { Button, Col, DatePicker, Input, Modal, Row, Skeleton, Spin } from "antd"
import { validate } from "../Component/Validations/AdPageValidation"
import { saveChatbotLead } from "../Actions/commonController"


const initialValue = {
    fullName: "",
    phone: "",
    email: "",
    salary: "",
    empName: "",
    dob: ""
}

const AdPage60Minute = () => {

    const [modalShow, setModalShow] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [loader, setLoader] = useState(false)
    const [formValue, setFormValue] = useState(initialValue)
    const [validation, setValidation] = useState([])

    const { fullName, phone, email, salary, empName, dob } = formValue

    const openModal = () => { setModalShow(true) }
    const closeModal = () => { setModalShow(false); setModalSuccess(false); setFormValue(initialValue) }

    const handleChange = (e) => {
        try {
            let { name, value } = e.target;
            setFormValue({ ...formValue, [name]: value });
        } catch (err) {
            console.log("An error has occured when inputting data in the form", err.message)
        }
    }

    const handleDate = (date, dateString) => {
        try {
            setFormValue({ ...formValue, dob: dateString })
        } catch (err) {
            console.log("An error has occured when inputting data in the form", err.message)
        }
    }

    const handleSubmit = async () => {
        try {
            let checkValidate = validate(formValue)
            setValidation(checkValidate)
            if (Object.keys(checkValidate).length > 0) return

            // Call in the API to get the data into the server
            let data = {
                name: formValue.fullName,
                email: formValue.email,
                phone: formValue.phone,
                salary: formValue.salary,
                empname: formValue.salary,
                dob: formValue.dob
            }
            setLoader(true)
            let { status } = await saveChatbotLead(data)
            if (status == "success") {
                setModalSuccess(true)
            }
            setLoader(false)
        } catch (err) {
            console.log("An error has occured when inputting data in the form", err.message)
        }
    }

    return (
        <>
            <SideHeaders />
            {/* Hero Section */}
            <section className="about-area w-full lg:py-[120px] py-[60px] adPage">
                <div className="theme-container mx-auto">
                    <div className="w-full grid lg:grid-cols-2 grid-cols-1 lg:gap-[66px] gap-[30px] items-center relative">
                        <div className="about-article-area" data-aos="fade-up" >
                            <h2 className="headline-default text-primary-900">
                                Get Your Mortgage Solutions in <br className="lg:block hidden" />
                                <div className="glow">
                                    60 Minutes
                                </div>
                            </h2>
                            <p className="xl:w-full max-w-4xl text-base text-primary-100 mb-14">
                                Explore swift and reliable mortgage services with Wizhull. Our dedicated team ensures
                                a personalized response within 60 minutes, streamlining your path to homeownership
                                with ease and efficiency.
                            </p>
                            <div class="px-[46px] py-[20px] bg-primary-500 rounded-lg text-white text-lg spline-sans leading-none hover:bg-primary-900 common-trans inline cursor-pointer" onClick={() => { openModal() }}>
                                <span>
                                    Apply now
                                </span>
                            </div>
                        </div>
                        <div className="thumbnail-area 2xl: w-full relative">
                            <div className="about-shape2 gear-2 xl:w-[385px] xl:h-[385px] md:w-[385px] md:h-[385px] w-[385px] h-[385px]" data-aos="fade-in" data-aos-delay="800"></div>
                            <Lottie
                                options={{
                                    animationData: animJson,
                                    loop: false, // Optional
                                    autoplay: true, // Optional
                                }}
                                height={400} // Optional
                                width={400} // Optional
                            />
                        </div>
                    </div>
                </div>
                {/* <img className="campaignImg3" src={campaignImg3} style={{minHeight: "300px", width: "auto"}}/> */}
            </section>

            {/* Clickbait section */}
            {/* <section className="about-area w-full lg:pt-[120px] pt-[60px] adPage">
                <div className="theme-container clickBait mx-auto">
                    <div className="about-article-area" data-aos="fade-up" >
                        <h2 className="headline-default text-primary-900 text-center">
                            Ready, Set, Home!
                        </h2>
                        <p className="xl:w-full text-base text-primary-100 py-5 text-center">
                            Start your journey home—secure your mortgage effortlessly with Wizhull today and move closer to your dream!
                        </p>
                        <div className="text-center pt-[60px] pb-[120px]">
                            <div class="px-[46px] py-[20px] bg-primary-500 rounded-lg text-white text-lg spline-sans leading-none hover:bg-primary-900 common-trans inline cursor-pointer" onClick={() => {openModal()}}>
                                <span>
                                    Apply now
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="silhoutte" src={silhoutte} style={{minHeight: "300px", width: "auto"}}/>
            </section> */}

            <section className="fun-fact-area lg:pt-[120px] xl:pb-0 lg:pb-[120px] py-[60px] xl:min-h-[1000px]"
                style={{
                    backgroundImage: `url(${funFactBG})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}
            >
                <div className="theme-container mx-auto relative mb-[-90px]">
                    <Calculator />
                </div>

                <div className="theme-container mx-auto">
                    <div className="about-article-area" data-aos="fade-up" >
                        <h2 className="headline-default text-white text-center">
                            Your Path to Homeownership
                        </h2>
                        <p className="xl:w-full text-base text-white py-5 text-center">
                            Three simple steps to securing your dream home.
                        </p>

                        <div className="grid lg:grid-cols-3 sm:grid-cols-2 sm:gap-[30px] gap-5 py-[60px]">
                            <div
                                data-aos="fade-left"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                                className="item p-[30px] bg-white rounded"
                            >
                                <div className="flex justify-between items-center mb-5">
                                    <div
                                        className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                                    >
                                        <img src={progress1} alt="progress" />
                                    </div>
                                    <h2 className="counter spline-sans">1</h2>
                                </div>
                                <h2
                                    className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                                >
                                    Apply
                                </h2>
                                <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                                    Start with your application on Wizhull.
                                </p>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                                className="item p-[30px] bg-white rounded"
                            >
                                <div className="flex justify-between items-center mb-5">
                                    <div
                                        className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                                    >
                                        <img src={progress4} alt="progress" />
                                    </div>
                                    <h2 className="counter spline-sans">2</h2>
                                </div>
                                <h2
                                    className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                                >
                                    Connect
                                </h2>
                                <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                                    An agent will guide you through the details.
                                </p>
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="1000"
                                data-aos-delay="400"
                                className="item p-[30px] bg-white rounded"
                            >
                                <div className="flex justify-between items-center mb-5">
                                    <div
                                        className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                                    >
                                        <img src={progress4} alt="progress" />
                                    </div>
                                    <h2 className="counter spline-sans">3</h2>
                                </div>
                                <h2
                                    className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                                >
                                    Complete
                                </h2>
                                <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                                    Relax as we handle everything, end-to-end.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <h2 className="headline-default text-white text-center">
                    Make It Happen Today
                </h2>
                <p className="xl:w-full text-base text-white py-5 text-center">
                    Take the first step towards your new home with Wizhull now!
                </p>
                <div className="text-center pt-[60px] pb-[240px]">
                    <div class="lg:px-10 hover:border-transparent inline lg:py-6 px-8 py-5 border border-white hover:bg-white common-trans rounded-lg text-lg font-semibold leading-4 spline-sans text-white hover:text-primary-500" onClick={() => { openModal() }}>
                        Apply now
                    </div>
                </div>
            </section>

            {/* 
                Name
                Phone Number
                Email
                Salary
                Employer Name
                Date of Birth
            */}

            <Modal
                title={<span className="pr-[18px]">Application Registration</span>}
                open={modalShow}
                onCancel={closeModal}
                width={800}
                footer={!modalSuccess ? !loader ? <Button onClick={handleSubmit}>Submit</Button> : <Spin /> : ""}>
                {
                    modalSuccess ?
                        <>
                            <p>
                                You have successfully registered for our mortgage service. Rest assured, you will be called by one of our expert agents for further inquery.
                            </p>
                            <Lottie
                                options={{
                                    animationData: successJson,
                                    loop: false, // Optional
                                    autoplay: true, // Optional
                                }}
                                height={300} // Optional
                                width={300} // Optional
                            />
                        </>
                        :
                        <>
                            {
                                loader ?
                                    <Skeleton />
                                    :
                                    <>
                                        <div>
                                            It's very simple. Fill in the fields, submit your application, and let us do the rest!
                                        </div>
                                        <Row className="pt-5" gutter={16}>
                                            <Col className="gutter-row" span={12}>
                                                <Input name="fullName" value={fullName} onChange={handleChange} placeholder="Full Name" />
                                                {validation.fullName == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Input name="phone" value={phone} onChange={handleChange} placeholder="Phone Number" />
                                                {validation.phone == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                        </Row>
                                        <Row className="pt-5" gutter={16}>
                                            <Col className="gutter-row" span={12}>
                                                <Input name="email" value={email} onChange={handleChange} placeholder="Email Address" />
                                                {validation.email == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <DatePicker name="dob" onChange={handleDate} style={{ width: "100%" }} />
                                                {validation.dob == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                        </Row>
                                        <Row className="py-5" gutter={16}>
                                            <Col className="gutter-row" span={12}>
                                                <Input name="salary" value={salary} onChange={handleChange} placeholder="Your Salary" />
                                                {validation.salary == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Input name="empName" value={empName} onChange={handleChange} placeholder="Employer Name" />
                                                {validation.empName == "REQUIRED" && <span className="errorMsg">*Field is required</span>}
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </>
                }
            </Modal>

            <SideFooter />
        </>
    )
}

export default AdPage60Minute