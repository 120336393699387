import React, { useEffect, useState } from "react"
import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

import { fetchBlogRecent, fetchBlogs } from "../Actions/commonController"
import { fetchBlogCategories } from "../Actions/commonController"
import { formatDate } from "../Component/lib"
import { Popover } from "antd"

const secKey = process.env.REACT_APP_STRAPI_KEY


const Blog = () => {
    const [recentData, setRecentData] = useState({})
    const [categoryData, setCategoryData] = useState({})
    const [blogData, setBlogData] = useState([])
    const [filterCat, setFilterCat] = useState("")
    const [page, setPage] = useState(1)
    const [initialLoad, setInitialLoad] = useState(false)
    const [loader, setLoader] = useState(false)
    const [pageLimit, setPageLimit] = useState(false)

    const loadRecentData = async() => {
        try {
            let { status, data } = await fetchBlogRecent(secKey)
            if(status == "success") setRecentData(data)
        } catch (err) {
            alert(err.message)
        }
    }

    const loadCategoryData = async() => {
        try {
            let { status, data } = await fetchBlogCategories(secKey)
            if(status == "success") {
                setCategoryData(data.sort((a, b) => {
                    return b.attributes.blogs.data.attributes.count - a.attributes.blogs.data.attributes.count;
                }))
            }
        } catch (err) {
            alert(err.message)
        }
    }

    const loadBlogData = async(pg, ct, opt) => {
        try {
            setLoader(true)
            let { status, data, limit } = await fetchBlogs(secKey, pg, ct)
            if(status == "success") {
                if(!opt) setBlogData([...blogData, ...data])
                else setBlogData(data)
                
                if(limit) setPageLimit(true)
            }
            setLoader(false)
        } catch (err) {
            alert(err.message)
        }
    }

    const copyClipboard = (txt) => {
        navigator.clipboard.writeText(txt).then(() => {

        })
    }

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])
    
    useEffect(() => {
        if(secKey != undefined) {
            loadRecentData()
            loadCategoryData()
            loadBlogData(page, filterCat, false)
            setInitialLoad(true)
        }
    }, [secKey])

    useEffect(() => {
        if(initialLoad) {
            setPageLimit(false)
            setPage(1)
            loadBlogData(1, filterCat, true)
        }
    }, [filterCat])

    useEffect(() => {
        if(initialLoad) {
            loadBlogData(page, filterCat, false)
        }
    }, [page])

    return (
        <>
            <SideHeaders />

            <main className="w-full min-h-screen">
                <section className="page-title-wrapper w-full h-[345px]">
                    <div className="theme-container mx-auto h-full">
                    <div
                        className="w-full h-full flex sm:flex-row flex-col sm:justify-between justify-center space-y-3 sm:space-y-0 items-center"
                    >
                        <h2 className="headline-default text-primary-900">Blogs</h2>
                        <ul className="breadcrumb-wrapper flex space-x-5 items-center">
                        <li className="breadcrumb-item">
                            <a href="#">
                            <div className="flex space-x-5 items-center">
                                <span
                                className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                >Home</span
                                >
                                <span className="ico">
                                <svg
                                    width="9"
                                    height="13"
                                    viewBox="0 0 9 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                    fill="#028835"
                                    />
                                </svg>
                                </span>
                            </div>
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#">
                            <div className="flex space-x-5 items-center">
                                <span
                                className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                >Blogs</span
                                >
                                <span className="ico">
                                <svg
                                    width="9"
                                    height="13"
                                    viewBox="0 0 9 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                    fill="#028835"
                                    />
                                </svg>
                                </span>
                            </div>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </section>
                <div className="lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]">
                    <div className="theme-container mx-auto">
                    <div className="lg:grid grid-cols-12 lg:gap-[30px]">
                        <div className="body-content-wrapper lg:col-span-8 col-span-12 mb-[80px] lg:mb-0">
                            <div className="w-full flex flex-col space-y-[80px] mb-[80px]">
                                {
                                    blogData && blogData.length >= 0 && blogData.map((item) => (
                                        <div className="w-full">
                                            {/* Blog Image */}
                                            <div className="w-full h-[440px]">
                                                <div className="w-full h-full overflow-hidden rounded">
                                                    <img
                                                        src={"https://blog.wizhull.com/" + item.attributes.Image.data.attributes.formats.medium.url}
                                                        alt=""
                                                        className="w-full h-full object-cover"
                                                    />
                                                </div>
                                            </div>
                                            {/* Blog Detail */}
                                            <div className="w-full sm:px-[30px] -mt-[30px] relative z-10">
                                                <div
                                                    style={{ boxShadow: "0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06)" }}
                                                    className="w-full sm:p-10 p-5 bg-white rounded">
                                                <a href="blog-details.html">
                                                    <p className="sm:text-[30px] font-semibold spline-sans sm:leading-9 text-2xl text-primary-900 mb-2">
                                                        { item.attributes.Title }
                                                    </p>
                                                </a>
                                                <ul className="flex space-x-5 items-center mb-5">
                                                    {
                                                        item.attributes.categories.data.map((catItem) => (
                                                            <li className="flex sm:space-x-5 space-x-2.5 items-center">
                                                                <div className="w-2.5 h-2.5 rounded-full bg-primary-500"></div>
                                                                <span className="sm:text-base sm:leading-[27px] text-sm text-primary-100">
                                                                    { catItem.attributes.Title }
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                <p className="sm:text-base text-primary-100 text-sm sm:leading-[27px] mb-[30px]">
                                                    { item.attributes.Excerpt }
                                                </p>
                                                <div className="flex justify-between items-center">
                                                    <Link to={`/blog/${item.attributes.slug}`}>
                                                        <div className="px-[46px] py-[20px] bg-primary-500 rounded-lg text-white text-lg spline-sans leading-none hover:bg-primary-900 common-trans">
                                                            <span>Read More</span>
                                                        </div>
                                                    </Link>
                                                    <Popover className="cursor-pointer m-0" title="Copied to clipboard!" trigger="click" onClick={() => {copyClipboard("https://wizhull.com/blog/" + item.attributes.slug)}}>
                                                        <div className="w-[50px] h-[50px] rounded-full bg-secondary flex justify-center items-center hover:bg-primary-500 text-primary-500 common-trans hover:text-white">
                                                            <span>
                                                                <svg
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="fill-current"
                                                                >
                                                                    <path
                                                                        d="M11 6C12.6562 6 14 4.65625 14 3C14 1.34375 12.6562 0 11 0C9.34375 0 8 1.34375 8 3C8 3.125 8 3.25 8 3.375L5.0625 4.84375C4.53125 4.34375 3.78125 4 3 4C1.34375 4 0 5.34375 0 7C0 8.65625 1.34375 10 3 10C3.78125 10 4.53125 9.6875 5.0625 9.1875L8 10.6562C8 10.75 8 10.875 8 11C8 12.6562 9.34375 14 11 14C12.6562 14 14 12.6562 14 11C14 9.34375 12.6562 8 11 8C10.1875 8 9.4375 8.34375 8.90625 8.84375L5.96875 7.375C5.96875 7.25 6 7.15625 6 7C6 6.875 5.96875 6.75 5.96875 6.65625L8.90625 5.1875C9.4375 5.6875 10.1875 6 11 6Z"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                !pageLimit &&
                                    <div className="flex justify-center">
                                        <button
                                            type="button"
                                            onClick={() => {setPage(page+1)}}
                                            className="px-[30px] py-[25px] flex justify-center items-center bg-primary-900 rounded-lg text-white text-lg spline-sans leading-none hover:bg-primary-900 common-trans"
                                        >
                                            <div className="flex space-x-2 items-center">
                                                <span>Load More</span>

                                                {
                                                    loader &&
                                                        <span>
                                                            <svg
                                                                id="L9"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                viewBox="0 0 100 100"
                                                                xmlSpace="preserve"
                                                                className="w-10"
                                                            >
                                                                <path
                                                                fill="#fff"
                                                                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                                >
                                                                <animateTransform
                                                                    attributeName="transform"
                                                                    attributeType="XML"
                                                                    type="rotate"
                                                                    dur="1s"
                                                                    from="0 50 50"
                                                                    to="360 50 50"
                                                                    repeatCount="indefinite"
                                                                ></animateTransform>
                                                                </path>
                                                            </svg>
                                                        </span>
                                                }
                                            </div>
                                        </button>
                                    </div>
                            }
                        </div>
                        <aside className="aside-wrapper lg:col-span-4 col-span-12">
                        <div className="w-full mb-[60px]">
                            <p className="text-lg leading-7 spline-sans font-bold text-primary-900 mb-[30px]">
                                Recent Post
                            </p>
                            <ul className="flex flex-col space-y-5">
                                {
                                    recentData && recentData.length >= 0 && recentData.map((item) => (
                                        <li>
                                            <div className="w-full">
                                                <div className="flex space-x-2.5 items-center mb-2.5">
                                                    <span>
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.75 2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0C4.40625 0 4.75 0.34375 4.75 0.75V2ZM1.5 7.75H4V6H1.5V7.75ZM1.5 9.25V11.25H4V9.25H1.5ZM5.5 9.25V11.25H8.5V9.25H5.5ZM10 9.25V11.25H12.5V9.25H10ZM12.5 6H10V7.75H12.5V6ZM12.5 12.75H10V14.5H12C12.25 14.5 12.5 14.2812 12.5 14V12.75ZM8.5 12.75H5.5V14.5H8.5V12.75ZM4 12.75H1.5V14C1.5 14.2812 1.71875 14.5 2 14.5H4V12.75ZM8.5 6H5.5V7.75H8.5V6Z" fill="#028835"/>
                                                        </svg>
                                                    </span>
                                                    <span className="text-base text-primary-100">
                                                        { formatDate(item.attributes.createdAt) }
                                                    </span>
                                                </div>
                                                <p className="text-gray-700 text-lg font-bold spline-sans leading-7">
                                                    <Link to={`/blog/${item.attributes.slug}`} target={"_blank"}>
                                                        {item.attributes.Title}
                                                    </Link>
                                                </p>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                            <div className="w-full mb-[60px]">
                                <p className="text-lg leading-7 spline-sans font-bold text-primary-900 mb-[30px]">
                                    Category
                                </p>
                                <ul>
                                    {
                                        categoryData && categoryData.length >= 0 && categoryData.map((item) => (
                                            <li onClick={() => {setFilterCat(item.attributes.Title)}} className="cursor-pointer">
                                                <div className={`flex py-5 justify-between items-center border-b border-primaryBorder ${filterCat == item.attributes.Title ? "font-bold" : ""}`}>
                                                    <span className="text-primary-100 block hover:text-primary-500 text-base hover:font-medium">
                                                        { item.attributes.Title }
                                                    </span>
                                                    <span className="text-primary-500 text-base">
                                                        { item.attributes.blogs.data.attributes.count }
                                                    </span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                    
                                    <li onClick={() => {setFilterCat("")}}>
                                        <div className="flex py-5 justify-between items-center">
                                            <span className="text-primary-100 block hover:text-primary-500 text-base hover:font-medium">
                                                <strong>Clear</strong>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="mini-contact w-full h-[304px] flex justify-center items-center mb-[60px]"
                            >
                                <div>
                                <p
                                    className="text-white leading-[27px] text-base text-center mb-[45px]"
                                >
                                    For further information<br />
                                    Reach out to us today!
                                </p>
                                <div className="flex justify-center">
                                    <Link to="/contact">
                                        <div
                                            className="px-[42px] py-5 border border-white rounded text-lg font-semibold spline-sans text-white leading-none hover:bg-white hover:text-primary-500 common-trans"
                                        >
                                            <span>Contact Us</span>
                                        </div>
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    </div>
                </div>
            </main>

            <SideFooter />
        </>
    )
}

export default Blog