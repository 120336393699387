export const validate = (data) => {
    let error = {}
    if(data.fullName === "") error.fullName = "REQUIRED"
    if(data.phone === "") error.phone = "REQUIRED"
    if(data.email === "") error.email = "REQUIRED"
    if(data.salary === "") error.salary = "REQUIRED"
    if(data.empName === "") error.empName = "REQUIRED"
    if(data.dob === "") error.dob = "REQUIRED"

    console.log(data)

    return error
}