import React, { useEffect } from "react";
import SideHeaders from "../Component/Headers/SideHeaders";
import SideFooter from "../Component/Footers/SideFooter";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

const TermsAndConditionsApp = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <>
            <SideHeaders />

            <div className="lg:pt-[60px] lg:pb-[60px] pb-[180px]">
                <div className="theme-container mx-auto">
                    <div className="body-content-wrapper lg:col-span-8 mb-10 lg:mb-0">
                        <div className="grid items-center">
                            <div className="article-area">
                                <h2 className="headline-default text-primary-900">
                                    Terms and Conditions
                                </h2>
                                <p className="sm:text-sm sm:leading-8 text-sm font-bold spline-sans text-primary-500 mb-5">
                                    Last Updated: 3rd September, 2024
                                </p>
                                <p className="text-base leading-[27px] text-primary-100 mb-[20px]">
                                    These terms and conditions outline the rules and regulations for the use of WizHull's Website, located at www.wizhull.com. By accessing this website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use our Website.
                                </p>

                                <p className="items-center pb-[5px]">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        1. Introduction
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    These terms and conditions govern your use of our Website; by using our Website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our Website.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        2. Definitions
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>“User”</b>: Any individual who accesses or uses the Website.</li>
                                    <li><b>“Agent”</b>: A registered user who submits and manages real estate leads, including freelancers and licensed real estate agents.</li>
                                    <li><b>“Lead”</b>: A prospective real estate buyer or seller whose information is submitted by an Agent.</li>
                                    <li><b>“Services”</b>: The lead generation and processing services provided through the Website.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        3. Use of the Website
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>3.1 License</b>: We grant you a non-exclusive, non-transferable, limited license to access and use the Website for personal and commercial purposes, in accordance with these terms.</li>
                                    <li><b>3.2 Lawful Use</b>: You agree to use the Website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else’s use and enjoyment of the Website.</li>
                                    <li><b>3.3 Prohibited Activities</b>: You are prohibited from using the Website to engage in any illegal activities, transmit harmful or unlawful content, or attempt to gain unauthorized access to our systems.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        4. Registration and Accounts
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>4.1 Account Creation</b>: To access certain features of the Website, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account details.</li>
                                    <li><b>4.2 Account Security</b>: You must notify us immediately of any unauthorized use of your account. We are not liable for any loss or damage arising from your failure to comply with these requirements.</li>
                                    <li><b>4.3 Multiple Accounts</b>: You may not create more than one account unless explicitly authorized by us.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        5. Agent Responsibilities
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>5.1 Lead Submission</b>: Agents are responsible for submitting accurate and truthful information regarding Leads. Agents must ensure that they have obtained consent from the Lead before submitting their information to the Website.</li>
                                    <li><b>5.2 Compliance</b>: Agents agree to comply with all applicable laws, regulations, and industry standards when using the Website and submitting Leads.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        6. Intellectual Property
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>6.1 Ownership</b>: All content, trademarks, and other intellectual property on the Website are owned by WizHull or its licensors. Unauthorized use is prohibited.</li>
                                    <li><b>6.2 Restrictions</b>: You may not copy, reproduce, distribute, or create derivative works of any content on the Website without our prior written consent.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        7. Limitation of Liability
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>7.1 Disclaimer of Warranties</b>: The Website and Services are provided on an “as is” basis. We make no warranties, express or implied, regarding the accuracy, reliability, or availability of the Website.</li>
                                    <li><b>7.2 Limitation of Liability</b>: To the fullest extent permitted by law, WizHull shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, arising out of or in connection with your use of the Website or Services.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        8. Indemnification
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>You agree to indemnify and hold harmless WizHull, its affiliates, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising from your use of the Website or violation of these terms.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        9. Modifications to Terms
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>We reserve the right to modify these terms at any time. Changes will be posted on the Website, and the "Effective Date" will be updated. Your continued use of the Website following any changes constitutes your acceptance of the new terms.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        10. Termination
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li><b>10.1 Termination by Us</b>: We may terminate or suspend your access to the Website at any time, with or without notice, for any reason, including violation of these terms.</li>
                                    <li><b>10.2 Termination by You</b>: You may terminate your use of the Website at any time by ceasing to access the Website and deleting your account.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        11. Governing Law and Jurisdiction
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>These terms and conditions are governed by and construed in accordance with the laws of the United Arab Emirates. Any disputes arising out of or related to these terms shall be subject to the exclusive jurisdiction of the courts of Dubai, UAE.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        12. Contact Us
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>If you have any questions or concerns about our Terms and Conditions, please contact us at <a href="mailto:info@wizhull.com">info@wizhull.com</a>.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SideFooter />
        </>
    );
}

export default TermsAndConditionsApp;
