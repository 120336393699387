import React, { useState } from "react"
import useMessage from "antd/es/message/useMessage"
import ReactGA from 'react-ga'
import { Spin } from "antd"

// images
import logo from "../../assets/img/logo.png"

// context
import { Link } from "react-router-dom"
import { sendNewsletter } from "../../Actions/commonController"

const SideFooter = () => {
  const [lead, setLead] = useState(false)
  const [email, setEmail] = useState("")
  const [messageApi, messageTrigger] = useMessage()
  const [loader, setLoader] = useState(false)

  const successMessage = () => {
    messageApi.open({
      type: 'success',
      content: 'You have successfully signed up to our newsletter!',
      duration: 7,
    })
  }

  const loadingMessage = () => {
    messageApi.open({
      type: 'loading',
      content: 'Submitting your details...',
      duration: 3,
    })
  }

  const errorMessage = () => {
    messageApi.open({
      type: 'error',
      content: 'There was an error while submitting the form.',
    })
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    try {
      ReactGA.event({
        category: 'Button Click',
        action: 'Clicked on form submit on newsletter',
        label: 'Footer Newsletter',
      })
      e.preventDefault()
      setLoader(true)
      loadingMessage()

      let data = { email: email }

      let { status } = await sendNewsletter(data)

      if(status == "success") {
          setLead(true)
          messageApi.destroy()
          successMessage()
      } else {
          messageApi.destroy()
          errorMessage()
      }

      setLoader(false)
    } catch(err) {
      console.log("An error has occured when submitting form!", err.message)
    }
  }
    
  return (
    <footer>
      {messageTrigger}
      <section className="footer-wrapper-two w-full bg-secondary relative">
        <div className="theme-container mx-auto relative">
          <div
            className="discover-area z-20 w-full sm:h-[253px] h-[300px] rounded-[10px] left-0 absolute -top-[125px] lg:px-[60px] p-5 sm:px-[42px] flex sm:flex-row flex-col space-y-14 sm:space-y-0 sm:justify-between sm:items-center"
          >
            <div>
              <p className="text-base leading-7 text-white mb-1">
                For your homeownership, we will do everything
              </p>
              <h2 className="headline-default text-white">
                Let's get your finance & <br className="sm:block hidden" />
                lead a secure life
              </h2>
            </div>
            <div>
              <Link to="/contact">
                <div className="lg:px-10 hover:border-transparent inline lg:py-6 px-8 py-5 border border-white hover:bg-white common-trans rounded-lg text-lg font-semibold leading-4 spline-sans text-white hover:text-primary-500">
                  Contact us now
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="theme-container mx-auto relative z-10">
          <div className="w-full pt-[210px]">
            <div
              className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-[30px] items-center lg:justify-between pb-[60px] mb-10 border-b border-primaryBorder"
            >
              <Link to="/">
                <img src={logo} width="220px" alt="" />
              </Link>
              <div className="flex space-x-[17px] items-center">
                <div
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-white"
                >
                  <span>
                    <svg
                      width="15"
                      height="21"
                      viewBox="0 0 15 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5625 20.0312C4.53125 17.4922 0 11.4375 0 8C0 3.85938 3.32031 0.5 7.5 0.5C11.6406 0.5 15 3.85938 15 8C15 11.4375 10.4297 17.4922 8.39844 20.0312C7.92969 20.6172 7.03125 20.6172 6.5625 20.0312ZM7.5 10.5C8.86719 10.5 10 9.40625 10 8C10 6.63281 8.86719 5.5 7.5 5.5C6.09375 5.5 5 6.63281 5 8C5 9.40625 6.09375 10.5 7.5 10.5Z"
                        fill="#028835"
                      />
                    </svg>
                  </span>
                </div>
                <div>
                  <p
                    className="text-lg font-semibold spline-sans leading-[27px] mb-1.5 text-primary-900"
                  >
                    Our Address
                  </p>
                  
                  <p className="text-base leading-[27px] text-primary-100">
                    Office 112, Oasis Mall offices <br />
                    Sheikh Zayed Road - Dubai
                  </p>
                </div>
              </div>
              <div className="flex space-x-[17px] items-center">
                <div
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-white"
                >
                  <span>
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9609 15.6172L19.0234 19.5625C18.9062 20.1484 18.4375 20.5391 17.8516 20.5391C8.00781 20.5 0 12.4922 0 2.64844C0 2.0625 0.351562 1.59375 0.9375 1.47656L4.88281 0.539062C5.42969 0.421875 6.01562 0.734375 6.25 1.24219L8.08594 5.5C8.28125 6.00781 8.16406 6.59375 7.73438 6.90625L5.625 8.625C6.95312 11.3203 9.14062 13.5078 11.875 14.8359L13.5938 12.7266C13.9062 12.3359 14.4922 12.1797 15 12.375L19.2578 14.2109C19.7656 14.4844 20.0781 15.0703 19.9609 15.6172Z"
                        fill="#028835"
                      />
                    </svg>
                  </span>
                </div>
                <div>
                  <p
                    className="text-lg font-semibold spline-sans leading-[27px] mb-1.5 text-primary-900"
                  >
                    Phone Number
                  </p>
                  <p className="text-base leading-[27px] text-primary-100">
                    +971 4 873 0210
                  </p>
                </div>
              </div>
              <div className="flex lg:justify-end">
                <div className="flex space-x-2.5 items-center">
                  <a
                    href="https://www.facebook.com/"
                    className="transform translate-y-0 hover:-translate-y-1 common-trans"
                    target="_blank"
                  >
                    <div
                      style={{ boxShadow: "0 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0 4px 13px -2px rgba(19, 16, 34, 0.06)" }}
                      className="w-10 h-10 rounded-full bg-white flex justify-center items-center"
                    >
                      <span>
                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/wizhullmortgagebrokerage?igsh=d2pvdHF1ajYwOWM="
                    className="transform translate-y-0 hover:-translate-y-1 common-trans"
                    target="_blank"
                  >
                    <div
                      style={{ boxShadow: "0 4.8px 24.4px -6px rgba(19, 16, 34, 0.1),                            0 4px 13px -2px rgba(19, 16, 34, 0.06)" }}
                      className="w-10 h-10 rounded-full bg-white flex justify-center items-center"
                    >
                      <span>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3.40625C6 3.40625 4.40625 5.03125 4.40625 7C4.40625 9 6 10.5938 8 10.5938C9.96875 10.5938 11.5938 9 11.5938 7C11.5938 5.03125 9.96875 3.40625 8 3.40625ZM8 9.34375C6.71875 9.34375 5.65625 8.3125 5.65625 7C5.65625 5.71875 6.6875 4.6875 8 4.6875C9.28125 4.6875 10.3125 5.71875 10.3125 7C10.3125 8.3125 9.28125 9.34375 8 9.34375ZM12.5625 3.28125C12.5625 3.75 12.1875 4.125 11.7188 4.125C11.25 4.125 10.875 3.75 10.875 3.28125C10.875 2.8125 11.25 2.4375 11.7188 2.4375C12.1875 2.4375 12.5625 2.8125 12.5625 3.28125ZM14.9375 4.125C14.875 3 14.625 2 13.8125 1.1875C13 0.375 12 0.125 10.875 0.0625C9.71875 0 6.25 0 5.09375 0.0625C3.96875 0.125 3 0.375 2.15625 1.1875C1.34375 2 1.09375 3 1.03125 4.125C0.96875 5.28125 0.96875 8.75 1.03125 9.90625C1.09375 11.0312 1.34375 12 2.15625 12.8438C3 13.6562 3.96875 13.9062 5.09375 13.9688C6.25 14.0312 9.71875 14.0312 10.875 13.9688C12 13.9062 13 13.6562 13.8125 12.8438C14.625 12 14.875 11.0312 14.9375 9.90625C15 8.75 15 5.28125 14.9375 4.125ZM13.4375 11.125C13.2188 11.75 12.7188 12.2188 12.125 12.4688C11.1875 12.8438 9 12.75 8 12.75C6.96875 12.75 4.78125 12.8438 3.875 12.4688C3.25 12.2188 2.78125 11.75 2.53125 11.125C2.15625 10.2188 2.25 8.03125 2.25 7C2.25 6 2.15625 3.8125 2.53125 2.875C2.78125 2.28125 3.25 1.8125 3.875 1.5625C4.78125 1.1875 6.96875 1.28125 8 1.28125C9 1.28125 11.1875 1.1875 12.125 1.5625C12.7188 1.78125 13.1875 2.28125 13.4375 2.875C13.8125 3.8125 13.7188 6 13.7188 7C13.7188 8.03125 13.8125 10.2188 13.4375 11.125Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/wizhull-real-estate-mortgage-brokerage-llc"
                    className="transform translate-y-0 hover:-translate-y-1 common-trans"
                    target="_blank"
                  >
                    <div
                      style={{ boxShadow: "0 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0 4px 13px -2px rgba(19, 16, 34, 0.06)" }}
                      className="w-10 h-10 rounded-full bg-white flex justify-center items-center"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" fill="#028835"/>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[30px] lg:gap-0 pb-[80px] border-b border-primaryBorder"
            >
              <div className="article">
                <p
                  className="text-lg font-semibold text-primary-900 spline-sans mb-[30px]"
                >
                  About Us
                </p>
                <p className="text-base text-primary-100 leading-[27px]">
                  Your Trusted Mortgage Solution <br />
                  Empowering Homeownership
                </p>
              </div>
              <div className="colum-1">
                <p
                  className="text-lg font-semibold text-primary-900 spline-sans mb-[30px]"
                >
                  Services
                </p>
                <ul className="flex flex-col space-y-2.5">
                  <li>
                    <Link to="/calculator" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span>
                        <svg
                            width="13"
                            height="11"
                            viewBox="0 0 13 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                              fill="#028835"
                            />
                          </svg>
                        </span>
                        <span>Mortgage Calculator</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span>
                        <svg
                            width="13"
                            height="11"
                            viewBox="0 0 13 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                              fill="#028835"
                            />
                          </svg>
                        </span>
                        <span>Free Eligibility Check (Coming Soon)</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="colum-3">
                <p
                  className="text-lg font-semibold text-primary-900 spline-sans mb-[30px]"
                >
                  All Links
                </p>
                <ul className="flex flex-col space-y-2.5">
                  <li>
                    <Link to="/about" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span
                        ><svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                      <span>About Us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span
                        ><svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                      <span>Services</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span
                        ><svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                      <span>Blog</span>
                    </Link>
                  </li>
                  {/* <li>
                    <a
                      href="#"
                      className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center"
                    >
                      <span
                        ><svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                      <span>FAQ</span>
                    </a>
                  </li> */}
                  <li>
                    <Link to="/contact" className="text-base text-primary-100 leading-[27px] hover:underline hover:text-gray-900 hover:font-medium flex space-x-2.5 items-center">
                      <span
                        ><svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9766 6.37891L7.60156 10.7539C7.4375 10.918 7.21875 11 7 11C6.75391 11 6.53516 10.918 6.37109 10.7539C6.01562 10.4258 6.01562 9.85156 6.37109 9.52344L9.24219 6.625H0.875C0.382812 6.625 0 6.24219 0 5.75C0 5.28516 0.382812 4.875 0.875 4.875H9.24219L6.37109 2.00391C6.01562 1.67578 6.01562 1.10156 6.37109 0.773438C6.69922 0.417969 7.27344 0.417969 7.60156 0.773438L11.9766 5.14844C12.332 5.47656 12.332 6.05078 11.9766 6.37891Z"
                            fill="#028835"
                          />
                        </svg>
                      </span>
                      <span>Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="colum-3">
              {
                lead ?
                  <h2 className="text-primary-500 font-bold lg:leading-8 spline-sans lg:text-xl text-lg uppercase mb-2">
                    You are now signed up!
                  </h2>
                :
                  <>
                    <p className="text-lg font-semibold text-primary-900 spline-sans mb-[30px]">
                      Sign up to our newsletter
                    </p>
                    <div className="flex space-y-2.5">
                      <input type="text" name="email" value={email} onChange={handleChange} className="bg-gray-50 mr-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email here" required />
                      
                      {
                        !loader ?
                          <button
                            onClick={handleSubmit}
                            type="submit"
                            className="px-5 w-[155px] m-0 rounded text-white text-md font-semibold spline-sans bg-primary-500 hover:bg-primary-900 common-trans"
                          >
                            Submit
                          </button>
                        :
                        <button
                          disabled
                          className="px-5 w-[155px] m-0 rounded text-white text-md font-semibold spline-sans hover:bg-primary-900 common-trans"
                        >
                          <Spin />
                        </button>
                      }
                    </div>
                  </>
                }

                

              </div>
            </div>
            <div className="w-full h-[90px] flex justify-between items-center">
              <p className="text-base leading-[27px] text-primary-900">
                © Wizhull 2024 | All Rights Reserved
              </p>
              <ul className="lg:flex hidden space-x-[30px] items-center">
                
                <li>
                    <Link to={'/terms-conditions'}>
                        <span class="text-base leading-[27px] text-primary-900 hover:underline">
                            Terms & Condition
                        </span>
                    </Link>
                </li>
                <li>
                    <Link to={'/privacy-policy'}>
                        <span class="text-base leading-[27px] text-primary-900 hover:underline">
                            Privacy Policy
                        </span>
                    </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-base leading-[27px] text-primary-900 hover:underline">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute top-[250px] right-0">
          <img src="./assets/img/footer-two-shape.png" alt="" />
        </div>
      </section>
    </footer>
  )
}

export default SideFooter