import React from "react"

// images
import logoWhite from "../../assets/img/logoWhite.png"

// context
import { Link } from "react-router-dom"

const Footer = () => {

    return (
        <footer>
            <div class="footer-wrapper w-full bg-primary-900 pt-[90px] overflow-hidden relative">
                <div class="theme-container mx-auto">
                <div class="relative z-10">
                    <div class="w-full grid grid-cols-12 gap-10 lg:gap-0 pb-[60px]">
                    <div class="sm:col-span-4 col-span-12">
                        <div class="footer-logo mb-[30px]">
                        <img src={logoWhite} alt="" width="250" />
                        </div>
                        <p class="text-base leading-7 text-white mb-10">
                            Your Trusted Mortgage Solution  <br />
                            Empowering Homeownership
                        </p>
                    </div>
                    <div class="sm:col-span-8 col-span-12">
                        <div
                        class="flex sm:flex-row flex-col justify-between flex-wrap xl:flex-nowrap gap-y-10 xl:gap-y-0"
                        >
                        <div class="quick-links">
                            <div class="mb-[30px]">
                            <span
                                class="text-lg text-white font-bold spline-sans leading-7 mb-2.5 block"
                                >Quick Links</span
                            >
                            <div class="w-[52px] h-[2px] bg-primary-500"></div>
                            </div>
                            <div class="flex space-x-8">
                            <ul>
                                <li class="mb-2.5">
                                    <Link to="/">
                                        <span
                                        class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                        >Home</span
                                        >
                                    </Link>
                                </li>
                                <li class="mb-2.5">
                                    <Link to="/about">
                                        <span
                                        class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                        >About Us</span
                                        >
                                    </Link>
                                </li>
                                <li class="mb-2.5">
                                    <Link to="/services">
                                        <span
                                        class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                        >Service</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                        <span class="text-white text-base leading-7 hover:underline common-trans capitalize">
                                            Blog
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li class="mb-2.5">
                                    <Link to="/contact">
                                        <span
                                        class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                        >Support</span
                                        >
                                    </Link>
                                </li>
                                <li class="mb-2.5">
                                    <Link to="/terms-conditions">
                                        <span class="text-white text-base leading-7 hover:underline common-trans capitalize">
                                            Terms of Use
                                        </span>
                                    </Link>
                                </li>
                                <li class="mb-2.5">
                                    <Link to="/privacy-policy">
                                        <span class="text-white text-base leading-7 hover:underline common-trans capitalize">
                                            Privacy Policy
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div class="service-links">
                            <div class="mb-[30px]">
                            <span
                                class="text-lg text-white font-bold spline-sans leading-7 mb-2.5 block"
                                >Services</span>
                            <div class="w-[52px] h-[2px] bg-primary-500"></div>
                            </div>
                            <ul>
                            <li class="mb-2.5">
                                <Link to="/calculator">
                                    <span
                                        class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                        >Mortgage Calculator</span
                                    >
                                </Link>
                            </li>
                            <li class="mb-2.5">
                                {/* <a href="service-details.html"> */}
                                <span
                                    class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                    >Free Eligibility Check (Coming Soon)</span
                                >
                                {/* </a> */}
                            </li>
                            </ul>
                        </div>
                        <div class="contact-links">
                            <div class="mb-[30px]">
                            <span
                                class="text-lg text-white font-bold spline-sans leading-7 mb-2.5 block"
                                >Contact Us</span
                            >
                            <div class="w-[52px] h-[2px] bg-primary-500"></div>
                            </div>
                            <ul>
                            <li class="mb-2.5">
                                <a href="tel:+971 4 873 0210">
                                <div class="flex space-x-2.5 items-center">
                                    <span>
                                    <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M5.125 1.78125L6.375 4.78125C6.59375 5.28125 6.46875 5.875 6.03125 6.21875L4.5 7.5C5.53125 9.6875 7.3125 11.4688 9.5 12.5L10.7812 10.9688C11.125 10.5312 11.7188 10.4062 12.2188 10.625L15.2188 11.875C15.8125 12.0938 16.0938 12.75 15.9375 13.3438L15.1875 16.0938C15.0312 16.625 14.5625 17 14 17C6.25 17 0 10.75 0 3C0 2.4375 0.375 1.96875 0.90625 1.8125L3.65625 1.0625C4.25 0.90625 4.90625 1.1875 5.125 1.78125Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </span>
                                    <span
                                    class="text-white text-base leading-7 hover:underline common-trans capitalize"
                                    >+971 4 873 0210</span
                                    >
                                </div>
                                </a>
                            </li>
                            <li class="mb-2.5">
                                <a href="mailto:info@wizhull.com">
                                <div class="flex space-x-2.5 items-center">
                                    <span>
                                    <svg
                                        width="16"
                                        height="12"
                                        viewBox="0 0 16 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M1.5 0H14.5C15.3125 0 16 0.6875 16 1.5C16 2 15.75 2.4375 15.375 2.71875L8.59375 7.8125C8.21875 8.09375 7.75 8.09375 7.375 7.8125L0.59375 2.71875C0.21875 2.4375 0 2 0 1.5C0 0.6875 0.65625 0 1.5 0ZM0 3.5L6.78125 8.625C7.5 9.15625 8.46875 9.15625 9.1875 8.625L16 3.5V10C16 11.125 15.0938 12 14 12H2C0.875 12 0 11.125 0 10V3.5Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </span>
                                    <span
                                    class="text-white text-base leading-7 hover:underline common-trans"
                                    >info@wizhull.com</span
                                    >
                                </div>
                                </a>
                            </li>
                            <li class="mb-2.5">
                                <a href="mailto:info@wizhull.com">
                                <div class="flex space-x-2.5 items-center">
                                    <span>
                                    <svg
                                        width="12"
                                        height="17"
                                        viewBox="0 0 12 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625C3.65625 13.5938 0 8.75 0 6C0 2.6875 2.6875 0 6 0C9.3125 0 12 2.6875 12 6C12 8.75 8.34375 13.5938 6.71875 15.625ZM6 8C7.09375 8 8 7.125 8 6C8 4.90625 7.09375 4 6 4C4.875 4 4 4.90625 4 6C4 7.125 4.875 8 6 8Z"
                                        fill="white"
                                        />
                                    </svg>
                                    </span>
                                    <span className="text-white text-base leading-7 hover:underline common-trans capitalize">
                                        Office 112, 3rd Floor, Oasis Mall offices <br />
                                        Sheikh Zayed Road - Dubai
                                    </span>
                                </div>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                    class="w-full border-t border-[#E3E3E3] lg:py-[30px] py-3 flex items-center justify-between"
                    >
                    <span class="lg:text-base text-sm text-white">
                        © Wizhull 2024 | All Rights Reserved
                    </span>
                    <ul class="sm:flex hidden space-x-[30px] items-center">
                        <li>
                            <Link to={'/terms-conditions'}>
                                <span class="text-white lg:text-base text-sm leading-7 hover:underline common-trans capitalize">
                                    Terms & Condition
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/privacy-policy'}>
                                <span class="text-white lg:text-base text-sm leading-7 hover:underline common-trans capitalize">
                                    Privacy Policy
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact">
                                <span
                                class="text-white lg:text-base text-sm leading-7 hover:underline common-trans capitalize"
                                >Contact Us</span
                                >
                            </Link>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div class="footer-shape absolute">
                <span>
                    <svg
                    width="920"
                    height="841"
                    viewBox="0 0 920 841"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{opacity: "0.1"}}
                    class="gear"
                    >
                    <path
                        d="M517.721 395.613L510.706 457.46C510.697 457.859 510.387 458.252 509.982 458.442L453.058 483.258C452.654 483.448 452.256 483.338 451.861 483.129L401.952 446.097C401.657 445.89 401.469 445.385 401.479 444.985L408.493 383.138C408.503 382.739 408.812 382.346 409.217 382.156L466.141 357.34C466.546 357.15 466.943 357.26 467.338 357.469L517.248 394.501C517.543 394.708 517.735 395.013 517.725 395.413C517.725 395.413 517.723 395.513 517.721 395.613ZM410.77 384.094L403.89 444.544L452.72 480.749L508.429 456.504L515.309 396.054L466.48 359.849L410.77 384.094Z"
                        fill="white"
                    />
                    <path
                        d="M518.889 388.739L517.171 454.917C517.161 455.317 516.951 455.712 516.546 455.902L458.457 487.491C458.052 487.681 457.652 487.672 457.257 487.462L400.886 452.873C400.491 452.664 400.301 452.259 400.31 451.859L402.029 385.681C402.039 385.281 402.248 384.886 402.653 384.696L460.743 353.107C461.147 352.917 461.547 352.927 461.942 353.136L518.314 387.725C518.609 387.932 518.801 388.237 518.891 388.639C518.791 388.637 518.891 388.639 518.889 388.739ZM404.413 386.34L402.729 451.118L457.918 484.977L514.791 454.059L516.475 389.28L461.286 355.421L404.413 386.34Z"
                        fill="white"
                    />
                    <path
                        d="M523.127 452.658L464.459 491.536C464.155 491.728 463.652 491.816 463.257 491.606L400.307 460.158C399.912 459.948 399.72 459.643 399.632 459.141L395.35 388.815C395.36 388.415 395.569 388.02 395.877 387.728L454.545 348.851C454.849 348.658 455.352 348.57 455.747 348.78L518.697 380.228C519.092 380.438 519.284 380.743 519.372 381.245L523.654 451.571C523.642 452.071 523.532 452.468 523.127 452.658ZM455.386 351.272L397.937 389.379L402.153 458.302L463.818 489.119L521.267 451.012L517.051 382.089L455.386 351.272Z"
                        fill="white"
                    />
                    <path
                        d="M529.928 448.626L471.371 495.208C471.064 495.5 470.564 495.488 470.167 495.379L400.619 467.87C400.221 467.76 399.931 467.353 399.841 466.951L388.749 392.857C388.659 392.455 388.868 392.06 389.176 391.768L447.732 345.185C448.039 344.893 448.539 344.905 448.937 345.015L518.485 372.523C518.882 372.633 519.172 373.04 519.263 373.442L530.355 447.536L530.352 447.636C530.342 448.036 530.233 448.433 529.928 448.626ZM448.776 347.511L391.338 393.321L402.162 466.107L470.523 493.087L527.96 447.277L517.136 374.491L448.776 347.511Z"
                        fill="white"
                    />
                    <path
                        d="M536.648 443.791L478.991 498.598C478.684 498.891 478.282 498.981 477.884 498.871L401.616 476.2C401.218 476.09 400.926 475.783 400.836 475.381L382.224 397.902C382.134 397.5 382.244 397.103 382.551 396.81L440.208 342.003C440.515 341.711 440.918 341.621 441.315 341.73L517.584 364.402C517.981 364.511 518.274 364.819 518.364 365.221L536.975 442.699C536.973 442.799 536.973 442.799 536.97 442.899C537.063 443.201 536.953 443.599 536.648 443.791ZM441.359 344.032L384.623 397.961L402.867 474.13L477.843 496.47L534.578 442.54L516.335 366.372L441.359 344.032Z"
                        fill="white"
                    />
                    <path
                        d="M543.394 437.951L487.326 501.399C487.018 501.692 486.614 501.882 486.216 501.772L403.305 484.84C402.908 484.73 402.515 484.421 402.425 484.018L375.582 403.638C375.492 403.236 375.502 402.836 375.812 402.443L431.879 338.995C432.187 338.703 432.592 338.512 432.989 338.622L515.9 355.554C516.297 355.664 516.69 355.974 516.78 356.376L543.623 436.756C543.62 436.856 543.718 436.959 543.716 437.059C543.706 437.458 543.598 437.756 543.394 437.951ZM433.128 341.126L377.984 403.597L404.459 482.668L486.075 499.368L541.219 436.897L514.746 357.727L433.128 341.126Z"
                        fill="white"
                    />
                    <path
                        d="M550.059 431.211L496.473 503.623C496.265 503.918 495.861 504.108 495.361 504.096L405.886 493.805C405.486 493.795 405.093 493.486 404.903 493.081L368.914 410.376C368.724 409.971 368.834 409.574 369.043 409.179L422.63 336.767C422.837 336.472 423.242 336.282 423.742 336.294L513.217 346.585C513.617 346.595 514.009 346.904 514.199 347.309L550.188 430.014C550.286 430.116 550.281 430.316 550.278 430.416C550.271 430.716 550.164 431.013 550.059 431.211ZM424.18 338.806L371.421 410.137L406.839 491.628L495.017 501.786L547.777 430.455L512.358 348.965L424.18 338.806Z"
                        fill="white"
                    />
                    <path
                        d="M556.351 423.464L506.339 505.166C506.129 505.561 505.724 505.751 505.324 505.741L409.56 503.099C409.16 503.09 408.765 502.88 408.575 502.475L362.823 418.131C362.633 417.727 362.643 417.327 362.853 416.932L412.865 335.23C413.075 334.835 413.48 334.645 413.879 334.655L509.643 337.297C510.043 337.307 510.438 337.516 510.629 337.921L556.38 422.265C556.475 422.467 556.473 422.567 556.568 422.769C556.561 423.069 556.556 423.269 556.351 423.464ZM414.421 337.069L365.135 417.688L410.216 500.815L504.681 503.425L553.966 422.806L508.885 339.679L414.421 337.069Z"
                        fill="white"
                    />
                    <path
                        d="M562.369 414.607L516.923 505.923C516.713 506.318 516.409 506.511 515.906 506.599L414.126 512.712C413.727 512.703 413.332 512.493 413.039 512.186L356.805 426.985C356.613 426.681 356.525 426.178 356.734 425.783L402.178 334.567C402.388 334.172 402.693 333.979 403.195 333.892L504.975 327.778C505.375 327.788 505.77 327.998 506.062 328.305L562.399 413.408C562.494 413.61 562.589 413.812 562.584 414.012C562.576 414.312 562.472 414.51 562.369 414.607ZM404.038 336.213L359.224 426.244L414.787 510.228L515.165 504.18L559.98 414.149L504.416 330.165L404.038 336.213Z"
                        fill="white"
                    />
                    <path
                        d="M567.911 404.743L528.231 505.703C528.121 506.1 527.714 506.39 527.311 506.481L419.991 522.362C419.589 522.452 419.194 522.242 418.901 521.935L351.358 436.958C351.066 436.651 351.078 436.151 351.188 435.754L390.868 334.794C390.978 334.396 391.385 334.106 391.787 334.016L499.108 318.135C499.51 318.045 499.905 318.254 500.198 318.562L567.74 403.538C567.935 403.743 567.93 403.943 568.023 404.245C568.018 404.445 568.013 404.645 567.911 404.743ZM392.831 336.342L353.682 436.015L420.45 519.972L526.366 504.257L565.514 404.584L498.747 320.627L392.831 336.342Z"
                        fill="white"
                    />
                    <path
                        d="M572.881 393.758L540.167 504.391C540.057 504.789 539.75 505.081 539.348 505.172L427.157 532.037C426.755 532.127 426.357 532.017 426.065 531.71L346.589 447.942C346.296 447.635 346.206 447.233 346.316 446.835L379.03 336.202C379.14 335.804 379.447 335.512 379.85 335.422L492.04 308.556C492.442 308.466 492.84 308.576 493.132 308.883L572.608 392.651C572.803 392.856 572.896 393.158 572.891 393.358C572.989 393.461 572.984 393.661 572.881 393.758ZM381.001 337.45L348.718 446.794L427.316 529.64L538.197 503.143L570.48 393.8L491.882 310.953L381.001 337.45Z"
                        fill="white"
                    />
                    <path
                        d="M577.179 381.658L552.733 501.896C552.623 502.294 552.313 502.686 551.911 502.776L435.526 541.643C435.123 541.733 434.724 541.723 434.331 541.413L342.295 459.939C342.003 459.632 341.813 459.227 341.922 458.83L366.369 338.591C366.479 338.194 366.789 337.801 367.191 337.711L483.576 298.845C483.978 298.755 484.378 298.764 484.771 299.074L576.806 380.549C577.001 380.753 577.194 381.058 577.187 381.358C577.184 381.458 577.182 381.558 577.179 381.658ZM368.542 339.745L344.427 458.691L435.485 539.241L550.658 500.845L574.773 381.899L483.715 301.349L368.542 339.745Z"
                        fill="white"
                    />
                    <path
                        d="M580.503 368.44L565.826 498.22C565.816 498.62 565.506 499.013 565.102 499.203L445.295 551.19C444.89 551.38 444.493 551.27 444.098 551.06L338.968 473.267C338.673 473.06 338.483 472.655 338.495 472.155L353.372 342.38C353.382 341.98 353.692 341.587 354.096 341.397L473.903 289.411C474.308 289.221 474.705 289.33 475.1 289.54L580.23 367.333C580.525 367.541 580.718 367.846 580.708 368.245C580.505 368.34 580.505 368.34 580.503 368.44ZM355.552 343.233L341.009 471.616L445.056 548.683L563.648 497.267L578.091 368.882L474.144 291.817L355.552 343.233Z"
                        fill="white"
                    />
                    <path
                        d="M582.855 354.093L579.359 492.949C579.349 493.349 579.139 493.744 578.735 493.934L456.579 560.168C456.174 560.358 455.774 560.348 455.379 560.138L336.822 487.418C336.427 487.209 336.236 486.804 336.246 486.404L339.842 347.55C339.852 347.15 340.061 346.755 340.466 346.565L462.619 280.432C463.024 280.242 463.424 280.251 463.819 280.461L582.377 353.181C582.672 353.389 582.864 353.693 582.955 354.096C582.855 354.093 582.855 354.093 582.855 354.093ZM342.124 348.306L338.56 485.86L456.035 557.854L576.974 492.291L580.538 354.737L463.063 282.743L342.124 348.306Z"
                        fill="white"
                    />
                    <path
                        d="M592.602 487.269L469.375 568.681C469.07 568.873 468.567 568.961 468.172 568.751L335.748 502.694C335.353 502.484 335.16 502.18 335.072 501.677L325.975 354.211C325.985 353.811 326.194 353.416 326.502 353.123L449.829 271.714C450.134 271.521 450.636 271.434 451.031 271.643L583.456 337.701C583.851 337.91 584.043 338.215 584.131 338.717L593.126 486.281C593.216 486.684 593.006 487.079 592.602 487.269ZM450.57 274.133L328.362 354.769L337.391 500.934L468.631 566.362L590.841 485.625L581.812 339.461L450.57 274.133Z"
                        fill="white"
                    />
                    <path
                        d="M606.809 478.915L483.684 576.634C483.376 576.927 482.876 576.915 482.479 576.805L336.046 519.108C335.648 518.998 335.358 518.591 335.268 518.188L311.961 362.772C311.87 362.37 312.08 361.975 312.387 361.682L435.513 263.963C435.82 263.671 436.32 263.683 436.718 263.793L583.151 321.49C583.548 321.599 583.838 322.007 583.929 322.409L607.236 477.825L607.234 477.925C607.224 478.325 607.117 478.623 606.809 478.915ZM436.462 266.087L314.355 363.031L337.494 517.143L482.638 574.408L604.744 477.464L581.605 323.352L436.462 266.087Z"
                        fill="white"
                    />
                    <path
                        d="M620.965 468.658L499.516 583.723C499.209 584.016 498.806 584.106 498.409 583.996L337.826 536.356C337.428 536.247 337.136 535.939 337.045 535.537L297.909 372.932C297.819 372.529 297.929 372.132 298.236 371.839L419.683 256.874C419.99 256.581 420.392 256.491 420.79 256.601L581.373 304.241C581.771 304.351 582.063 304.658 582.153 305.06L621.29 467.666C621.287 467.766 621.287 467.766 621.285 467.866C621.377 468.168 621.17 468.463 620.965 468.658ZM420.731 259L300.306 373.09L339.177 534.289L498.47 581.497L618.895 467.407L580.127 306.111L420.731 259Z"
                        fill="white"
                    />
                    <path
                        d="M635.064 456.599L517.074 589.754C516.767 590.047 516.362 590.237 515.964 590.127L341.382 554.548C340.985 554.439 340.592 554.129 340.502 553.727L283.91 384.993C283.82 384.59 283.83 384.19 284.139 383.798L402.13 250.643C402.437 250.35 402.842 250.16 403.239 250.27L577.822 285.848C578.219 285.958 578.612 286.268 578.702 286.67L635.294 455.404C635.291 455.504 635.389 455.606 635.386 455.706C635.379 456.006 635.269 456.404 635.064 456.599ZM403.476 252.876L286.312 384.951L342.435 552.373L515.726 587.621L632.89 455.545L576.766 288.123L403.476 252.876Z"
                        fill="white"
                    />
                    <path
                        d="M648.814 442.429L536.065 594.418C535.857 594.713 535.453 594.903 534.953 594.891L346.622 573.381C346.222 573.371 345.83 573.061 345.64 572.656L270.059 399.157C269.869 398.752 269.978 398.355 270.188 397.96L382.938 245.971C383.145 245.676 383.55 245.485 384.049 245.498L572.38 267.008C572.78 267.018 573.172 267.328 573.362 267.732L648.943 441.232C649.041 441.335 649.036 441.534 649.034 441.634C649.026 441.934 649.019 442.234 648.814 442.429ZM384.588 248.011L272.665 398.92L347.676 571.206L534.609 592.582L646.534 441.573L571.524 269.288L384.588 248.011Z"
                        fill="white"
                    />
                    <path
                        d="M662.117 426.152L556.79 597.729C556.581 598.124 556.176 598.314 555.776 598.304L354.246 592.976C353.846 592.966 353.451 592.756 353.261 592.351L257.055 415.546C256.865 415.142 256.874 414.742 257.084 414.347L362.41 242.77C362.62 242.375 363.025 242.185 363.425 242.195L564.954 247.524C565.354 247.533 565.749 247.743 565.94 248.148L662.143 425.053C662.238 425.255 662.236 425.355 662.331 425.557C662.326 425.757 662.221 425.955 662.117 426.152ZM364.068 244.511L259.369 415.003L354.902 590.691L555.132 595.988L659.829 425.596L564.296 249.908L364.068 244.511Z"
                        fill="white"
                    />
                    <path
                        d="M674.771 407.759L578.958 599.373C578.748 599.768 578.443 599.961 577.941 600.049L363.857 613.119C363.458 613.109 363.063 612.899 362.77 612.592L244.502 433.947C244.309 433.643 244.222 433.14 244.431 432.745L340.245 241.13C340.454 240.735 340.759 240.543 341.261 240.455L555.345 227.385C555.745 227.395 556.14 227.604 556.432 227.912L674.803 406.459C674.898 406.661 674.993 406.864 674.988 407.064C674.881 407.361 674.776 407.559 674.771 407.759ZM342.003 242.874L246.819 433.304L364.416 610.732L577.197 597.73L672.381 407.3L554.784 229.872L342.003 242.874Z"
                        fill="white"
                    />
                    <path
                        d="M686.38 387.033L602.674 598.95C602.565 599.348 602.157 599.638 601.755 599.728L376.064 633.421C375.662 633.511 375.267 633.301 374.974 632.994L232.991 454.669C232.699 454.362 232.711 453.862 232.821 453.465L316.527 241.548C316.636 241.15 317.044 240.86 317.446 240.77L543.137 207.078C543.539 206.988 543.934 207.197 544.227 207.504L686.212 385.729C686.407 385.934 686.402 386.134 686.495 386.436C686.49 386.636 686.385 386.833 686.38 387.033ZM318.489 243.096L235.215 453.723L376.325 630.926L600.709 597.502L683.886 386.772L542.776 209.57L318.489 243.096Z"
                        fill="white"
                    />
                    <path
                        d="M696.746 363.98L627.644 596.36C627.534 596.758 627.227 597.05 626.825 597.14L390.769 653.786C390.367 653.876 389.97 653.767 389.677 653.459L222.723 477.725C222.431 477.418 222.341 477.016 222.45 476.618L291.552 244.238C291.662 243.841 291.969 243.548 292.372 243.458L528.427 186.812C528.829 186.722 529.227 186.832 529.519 187.139L696.473 362.873C696.668 363.078 696.761 363.38 696.756 363.58C696.853 363.683 696.848 363.883 696.746 363.98ZM293.522 245.487L224.852 476.577L390.928 651.389L625.674 595.112L694.344 364.021L528.268 189.209L293.522 245.487Z"
                        fill="white"
                    />
                    <path
                        d="M705.671 338.591L653.977 591.303C653.868 591.7 653.558 592.093 653.156 592.183L408.279 674.121C407.877 674.211 407.477 674.201 407.084 673.891L213.904 503.017C213.612 502.71 213.421 502.305 213.531 501.908L265.225 249.196C265.335 248.799 265.644 248.406 266.047 248.316L510.923 166.378C511.325 166.288 511.725 166.298 512.118 166.607L705.4 337.384C705.595 337.589 705.788 337.893 705.781 338.193C705.676 338.391 705.673 338.491 705.671 338.591ZM267.397 250.35L215.935 501.767L408.14 671.616L651.805 590.149L703.164 338.83L510.962 168.88L267.397 250.35Z"
                        fill="white"
                    />
                    <path
                        d="M712.75 310.853L681.376 583.467C681.366 583.867 681.057 584.259 680.652 584.45L428.7 693.923C428.295 694.113 427.898 694.003 427.503 693.793L206.926 530.652C206.63 530.445 206.44 530.04 206.453 529.54L237.824 257.026C237.834 256.626 238.144 256.233 238.548 256.043L490.398 146.667C490.802 146.477 491.2 146.587 491.595 146.797L712.275 309.841C712.57 310.048 712.762 310.353 712.753 310.753L712.75 310.853ZM240.002 257.979L208.862 529.199L428.361 691.414L679.101 582.411L710.241 311.191L490.739 149.077L240.002 257.979Z"
                        fill="white"
                    />
                    <path
                        d="M717.586 280.865L709.65 572.659C709.64 573.059 709.43 573.454 709.025 573.644L452.242 713.008C451.837 713.198 451.437 713.188 451.042 712.978L202.092 560.647C201.697 560.437 201.507 560.032 201.516 559.632L209.452 267.839C209.462 267.439 209.672 267.044 210.077 266.854L466.96 127.492C467.365 127.302 467.765 127.312 468.16 127.522L717.11 279.854C717.405 280.061 717.598 280.366 717.688 280.768C717.586 280.865 717.586 280.865 717.586 280.865ZM211.834 268.597L203.933 558.991L451.698 710.694L707.368 571.903L715.269 281.509L467.504 129.806L211.834 268.597Z"
                        fill="white"
                    />
                    <path
                        d="M720.074 248.616L738.702 558.564C738.692 558.964 738.483 559.359 738.175 559.651L478.905 731.164C478.6 731.357 478.098 731.444 477.703 731.235L199.802 592.899C199.407 592.69 199.214 592.385 199.126 591.882L180.498 281.934C180.508 281.535 180.718 281.14 181.025 280.847L440.296 109.334C440.6 109.142 441.103 109.054 441.498 109.264L719.499 247.601C719.794 247.809 720.084 248.216 720.074 248.616ZM182.885 282.493L201.448 591.039L478.161 728.845L736.315 558.005L717.753 249.459L441.039 111.653L182.885 282.493Z"
                        fill="white"
                    />
                    <path
                        d="M767.714 542.068L508.805 747.9C508.498 748.193 507.998 748.181 507.6 748.071L200.263 627.221C199.866 627.111 199.575 626.704 199.485 626.302L150.96 299.518C150.87 299.115 151.079 298.72 151.387 298.428L410.396 92.5985C410.703 92.3059 411.203 92.3181 411.601 92.4279L718.938 213.278C719.335 213.387 719.625 213.795 719.716 214.197L768.141 540.979L768.139 541.078C768.229 541.481 768.019 541.876 767.714 542.068ZM411.242 94.8198L153.452 299.879L201.711 625.256L507.859 745.676L765.747 540.72L717.389 215.24L411.242 94.8198Z"
                        fill="white"
                    />
                    <path
                        d="M797.346 520.684L542.047 762.915C541.74 763.207 541.338 763.298 540.94 763.188L203.976 663.52C203.579 663.41 203.286 663.103 203.196 662.701L121.528 320.902C121.438 320.5 121.547 320.102 121.855 319.81L377.05 77.6763C377.358 77.3837 377.76 77.2935 378.157 77.4032L715.124 176.971C715.521 177.081 715.814 177.388 715.904 177.79L797.572 519.589C797.57 519.689 797.57 519.689 797.568 519.789C797.76 520.094 797.65 520.491 797.346 520.684ZM378.199 79.805L123.925 321.061L205.325 661.553L540.999 760.789L795.273 519.533L713.873 179.041L378.199 79.805Z"
                        fill="white"
                    />
                    <path
                        d="M826.874 495.4L578.749 775.618C578.442 775.91 578.037 776.101 577.639 775.991L211.246 701.711C210.848 701.602 210.456 701.292 210.366 700.89L92.0993 346.292C92.0091 345.89 92.0189 345.49 92.3286 345.097L340.453 64.8798C340.761 64.5872 341.165 64.397 341.563 64.5068L707.956 138.786C708.354 138.896 708.746 139.206 708.837 139.608L827.103 494.205C827.1 494.305 827.198 494.408 827.196 494.508C827.188 494.808 827.081 495.105 826.874 495.4ZM341.704 66.911L94.5011 346.251L212.297 699.636L577.398 773.584L824.601 494.244L706.803 140.959L341.704 66.911Z"
                        fill="white"
                    />
                    <path
                        d="M855.705 465.798L618.809 785.903C618.601 786.198 618.197 786.388 617.697 786.376L222.573 741.602C222.173 741.593 221.781 741.283 221.591 740.878L63.3661 375.901C63.1759 375.496 63.2857 375.099 63.4954 374.704L300.392 54.5995C300.599 54.3045 301.004 54.1144 301.504 54.1266L696.627 98.8998C697.027 98.9095 697.42 99.2192 697.61 99.624L855.837 464.501C855.934 464.604 855.93 464.804 855.927 464.904C855.917 465.304 855.812 465.501 855.705 465.798ZM301.943 56.6381L65.7727 375.66L223.429 739.323L617.256 783.964L853.425 464.942L695.769 101.279L301.943 56.6381Z"
                        fill="white"
                    />
                    <path
                        d="M883.549 431.67L662.052 792.863C661.843 793.258 661.438 793.448 661.038 793.438L238.077 782.594C237.677 782.585 237.282 782.375 237.092 781.97L35.6269 409.933C35.4367 409.529 35.4465 409.129 35.6562 408.734L257.05 47.6384C257.26 47.2434 257.665 47.0533 258.065 47.0631L681.026 57.9067C681.426 57.9164 681.821 58.1262 682.011 58.5309L883.476 430.567C883.571 430.77 883.569 430.87 883.664 431.072C883.759 431.275 883.654 431.472 883.549 431.67ZM258.706 49.4795L38.0384 409.492L238.933 780.315L660.495 791.124L881.262 431.113L680.268 60.2888L258.706 49.4795Z"
                        fill="white"
                    />
                    <path
                        d="M909.899 393.099L708.681 796.3C708.472 796.695 708.167 796.888 707.664 796.976L258.452 824.702C258.052 824.692 257.657 824.482 257.365 824.175L9.37265 448.6C9.18004 448.295 9.09229 447.793 9.30201 447.398L210.519 44.1966C210.729 43.8016 211.034 43.609 211.536 43.5213L660.648 15.7927C661.048 15.8024 661.443 16.0122 661.736 16.3194L909.828 391.897C909.923 392.099 910.018 392.302 910.013 392.502C910.008 392.702 910.004 392.902 909.899 393.099ZM212.28 45.8401L11.6891 447.956L259.008 822.415L706.921 794.657L907.512 392.54L660.19 18.1822L212.28 45.8401Z"
                        fill="white"
                    />
                    </svg>
                </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer