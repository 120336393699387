import React, { useEffect } from "react";
import SideHeaders from "../Component/Headers/SideHeaders";
import SideFooter from "../Component/Footers/SideFooter";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

const PrivacyPolicyApp = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <>
            <SideHeaders />

            <div className="lg:pt-[60px] lg:pb-[60px] pb-[180px]">
                <div className="theme-container mx-auto">
                    <div className="body-content-wrapper lg:col-span-8 mb-10 lg:mb-0">
                        <div className="grid items-center">
                            <div className="article-area">
                                <h2 className="headline-default text-primary-900">
                                    Privacy Policy
                                </h2>
                                <p className="sm:text-sm sm:leading-8 text-sm font-bold spline-sans text-primary-500 mb-5">
                                    Last Updated: 3rd September, 2024
                                </p>
                                <p className="text-base leading-[27px] text-primary-100 mb-[20px]">
                                    Your privacy is important to us. This Privacy Policy explains how WizHull collects, uses, and protects your personal information when you use our services. We are committed to ensuring that your privacy is protected.
                                </p>

                                <p className="items-center pb-[5px]">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        1. Information We Collect
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>- Contact information including name, email address, phone number, and address.</li>
                                    <li>- Financial information including income, assets, and liabilities for mortgage processing.</li>
                                    <li>- Employment information, including job title, employer, and work history.</li>
                                    <li>- Information about your property preferences and requirements.</li>
                                    <li>- Usage data such as details of your visits to our Website and the resources that you access, including, but not limited to, traffic data, location data, weblogs, and other communication data.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        2. How We Use Your Information
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>- To provide, maintain, and improve our services, including processing real estate leads and facilitating transactions.</li>
                                    <li>- To communicate with you, respond to your inquiries, and provide updates.</li>
                                    <li>- To customize your experience and provide tailored property recommendations based on your preferences.</li>
                                    <li>- To comply with applicable laws, regulations, and legal requests.</li>
                                    <li>- With your consent, to send you promotional offers, updates, and other marketing communications.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        3. Information Sharing
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>- Third-party service providers such as marketing services, data analysis providers, and customer service providers who assist us in delivering our services.</li>
                                    <li>- Financial institutions and mortgage lenders in connection with your mortgage application and real estate transactions.</li>
                                    <li>- Government authorities or law enforcement agencies when required by law to protect our legal rights or in compliance with legal processes.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        4. Security
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>We are committed to ensuring that your information is secure. We have implemented appropriate technical and organizational measures to safeguard the personal information we collect against unauthorized access, disclosure, or loss. These measures include encryption, secure servers, and regular security audits.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        5. Your Rights
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>- Request access to the personal information we hold about you.</li>
                                    <li>- Request correction of any inaccuracies in your personal information.</li>
                                    <li>- Request deletion of your personal information, subject to any legal obligations to retain certain data.</li>
                                    <li>- Opt-out of receiving marketing communications from us at any time by following the unsubscribe instructions included in the emails or by contacting us directly.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        6. Cookies and Tracking
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>Our Website uses cookies and similar tracking technologies to enhance user experience, analyze website usage, and provide customized services. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        7. Data Retention
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, including any legal, accounting, or reporting requirements. The criteria used to determine the retention periods include the nature of the information, the purpose for which it was collected, and any legal or regulatory obligations.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        8. Changes to This Privacy Policy
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</li>
                                </ul>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        9. Contact Us
                                    </span>
                                </p>
                                <ul className="pl-3">
                                    <li>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@wizhull.com">info@wizhull.com</a>.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SideFooter />
        </>
    );
}

export default PrivacyPolicyApp;
