import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "./Pages/Home"
import Aos from "aos"
import "aos/dist/aos.css"
import "./assets/css/swiper.min.css"
import "react-multi-carousel/lib/styles.css"

import { ChatProvider } from "./Context/ChatProvider"
import FAB from "./Component/Footers/FAB"
import ChatBox from "./Component/Headers/chatBox"
import About from "./Pages/About"
import Services from "./Pages/Services"
// import FAQ from "./Pages/FAQ"
import Contact from "./Pages/Contact"
import TermsConditions from "./Pages/TermsConditions"
import PrivacyPolicy from "./Pages/PrivacyPolicy"
import PrivacyPolicyApp from "./Pages/PrivacyPolicyApp"
import Blog from "./Pages/Blog"
import BlogDetails from "./Pages/BlogDetails"
import AdPage60Minute from "./Pages/AdPage60Minute"
import CalculatorPage from "./Pages/CalculatorPage"
import TermsAndConditionsApp from "./Pages/TermsConditionsApp"


let getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const App = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000
    })
  }, [])

  return (
    <>
      <ChatProvider>
        <Router>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/services' element={<Services />}/>
            {/* <Route path='/faq' element={<FAQ />}/> */}
            <Route path='/contact' element={<Contact />}/>
            <Route path='/blog' element={<Blog />}/>
            <Route path='/blog/:blogId' element={<BlogDetails/>}/>
            <Route path='/terms-conditions' element={<TermsConditions />}/>
            <Route path='/terms-conditions-app' element={<TermsAndConditionsApp />}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
            <Route path='/privacy-policy-app' element={<PrivacyPolicyApp />}/>
            <Route path='/calculator' element={<CalculatorPage />}/>
            <Route path='/mortage-under-60-minutes' element={<AdPage60Minute />}/>
          </Routes>
        </Router>
        <FAB/>
        <ChatBox />
      </ChatProvider>
    </>
  )
}

export default App