import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'
import { Card, Col, Input, Row, Slider, Typography, InputNumber } from "antd"

const interest = 4.1

const Calculator = () => {
    const [loanAmount, setLoanAmount] = useState(0)
    const [downPayment, setDownPayment] = useState(0)
    const [inputValue, setInputValue] = useState(1)
    const [monthlyLoanOutput, setMonthlyLoanOutput] = useState(0)
    const [totalLoanGiven, setTotalLoanGiven] = useState(0)

    const onChange = (newValue) => {
      setInputValue(newValue)
    }

    const calculateLoan = () => {
        if(loanAmount && downPayment) {
            let loan = loanAmount - downPayment
            let monthlyInterestRate = interest / 1200
            let tenureMonths = inputValue * 12
            
            let emi = loan * monthlyInterestRate * (Math.pow(1 + monthlyInterestRate, tenureMonths)) / (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

            setTotalLoanGiven((loan).toLocaleString())
            setMonthlyLoanOutput(emi.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
        }
    }
    
    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    useEffect(() => {
        calculateLoan()
    }, [loanAmount, downPayment, inputValue])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            <main className="w-full min-h-screen">
                <div className="lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]">
                    <div className="theme-container mx-auto body-content-wrapper lg:col-span-8 mb-10 lg:mb-0 colMobileCalculator" data-aos="fade-up" data-aos-delay="1000">
                        <Row gutter={{ xs: 2, sm: 4, md: 8, lg: 16 }} style={{ display: 'flex' }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', height: '100%' }}>
                                    <div className="calculatorColumn">
                                        <h2 className="headline-default text-primary-900 mb-[20px]">
                                            Mortgage Calculator
                                        </h2>
                                        
                                        <Typography.Paragraph style={{ margin: 0, fontSize: "18px", paddingBottom: "20px" }}>
                                            Calculate your mortgage payments based on the cost of the property, loan term, and interest rate
                                        </Typography.Paragraph>

                                        <p className="text-primary-500 font-bold lg:leading-8 spline-sans lg:text-xl text-lg uppercase mb-1">
                                            Your Loan Amount
                                        </p>
                                        <Typography.Paragraph style={{ margin: 0, fontSize: "12px", paddingBottom: "10px", fontWeight: "100" }}>
                                            Insert the amount of the property you plan to invest.
                                        </Typography.Paragraph>
                                        <Input
                                            type="number"
                                            placeholder="Loan Amount"
                                            value={loanAmount}
                                            suffix="AED"
                                            size="large"
                                            onChange={(e) => setLoanAmount(e.target.value)}
                                        />

                                        <p className="text-primary-500 font-bold lg:leading-8 spline-sans lg:text-xl text-lg uppercase mb-1 pt-[30px]">
                                            Your Deposit Amount
                                        </p>
                                        <Typography.Paragraph style={{ margin: 0, fontSize: "12px", paddingTop: "5px", paddingBottom: "10px", fontWeight: "100" }}>
                                            Insert the amount you are willing to deposit.
                                        </Typography.Paragraph>
                                        <Input
                                            type="number"
                                            placeholder="Down Payment"
                                            value={downPayment}
                                            suffix="AED"
                                            size="large"
                                            onChange={(e) => setDownPayment(e.target.value)}
                                        />

                                        <p className="text-primary-500 font-bold lg:leading-8 spline-sans lg:text-xl text-lg uppercase mb-1 pt-[30px]">
                                            Loan Term
                                        </p>
                                        <Typography.Paragraph style={{ margin: 0, fontSize: "12px", paddingTop: "5px", paddingBottom: "10px", fontWeight: "100" }}>
                                            Select the period term of the loan in years
                                        </Typography.Paragraph>
                                        <Row>
                                            <Col span={18}>
                                                <Slider
                                                    min={1}
                                                    max={25}
                                                    onChange={onChange}
                                                    value={typeof inputValue === 'number' ? inputValue : 0}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <InputNumber
                                                    min={1}
                                                    max={25}
                                                    style={{ marginLeft: '16px' }}
                                                    value={inputValue}
                                                    onChange={onChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Typography.Paragraph style={{ margin: 0, fontSize: "14px", paddingTop: "30px" }}>
                                            <strong>Disclaimer: </strong>
                                            Results from this calculator are based on assumptions and may not reflect exact figures. 
                                            Actual mortgage payments can vary due to factors like interest rates and fees. 
                                            For personalized advice, consult our financial advisor or mortgage professional. 
                                            Use of this tool does not guarantee financing.
                                        </Typography.Paragraph>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', height: '100%' }} className="calculated">
                                    <div className="calculatorColumn">
                                        <Typography.Title level={4}>
                                            Here's the amount you'll be getting out from the bank
                                        </Typography.Title>
                                        <h2 className="headline-default text-primary-900 mb-[20px]">
                                            <strong>{totalLoanGiven}</strong> <span style={{fontSize: "18px", fontWeight: "300"}}>DHS</span>
                                        </h2>
                                        
                                        <Typography.Title level={4}>
                                            This will be your monthly instalments:
                                        </Typography.Title>
                                        <h2 className="headline-default text-primary-900 mb-[20px]">
                                            <strong>{monthlyLoanOutput}</strong> <span style={{fontSize: "18px", fontWeight: "300"}}>DHS / month</span>
                                        </h2>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </main>

            <div className="footerCol">
                <div className="sz">
                    <div style={{display: "block", padding: "0 30px"}}>
                        <h5 style={{ fontSize: "12px" }}>
                            Total Loan (DHS):
                        </h5>
                        <h4 className="headline-default text-primary-900" style={{ fontSize: "24px" }}>
                            <strong>{totalLoanGiven}</strong>
                        </h4>
                    </div>
                    
                    <div style={{display: "block"}}>
                        <h5 style={{ fontSize: "12px" }}>
                            Monthly cost (DHS):
                        </h5>
                        <h4 className="headline-default text-primary-900" style={{ fontSize: "24px" }}>
                            <strong>{monthlyLoanOutput}</strong>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calculator