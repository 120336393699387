import React, { useEffect, useState } from "react"
import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

const PrivacyPolicy = () => {

    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            <SideHeaders />

            <div className="lg:pt-[60px] lg:pb-[60px] pb-[180px]">
                <div className="theme-container mx-auto">
                    <div className="body-content-wrapper lg:col-span-8 mb-10 lg:mb-0">
                        <div className="grid items-center">
                            <div className="article-area">
                                <h2 className="headline-default text-primary-900">
                                    Privacy Policy
                                </h2>
                                <p className="sm:text-sm sm:leading-8 text-sm font-bold spline-sans text-primary-500 mb-5">
                                    Last Updated: 18th January, 2024
                                </p>
                                <p className="text-base leading-[27px] text-primary-100 mb-[20px]">
                                    Your privacy is important to us. This Privacy Policy explains how WizHull
                                    collects, uses, and protects your personal information when you use our services. 
                                    We are committed to ensuring that your privacy is protected.
                                </p>

                                <p className="items-center pb-[5px]">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Information We Collect
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We may collect the following types of personal information:
                                    <ul className="pl-3">
                                        <li>- Contact information including name, email address, phone number, and address.</li>
                                        <li>- Financial information including income, assets, and liabilities.</li>
                                        <li>- Employment information.</li>
                                        <li>- Information about your property preferences and requirements.</li>
                                    </ul>
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        How We Use Your Information
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We use the information we collect for the following purposes:
                                    <ul className="pl-3">
                                        <li>- To provide and improve our services.</li>
                                        <li>- To communicate with you and respond to your inquiries.</li>
                                        <li>- To customize your experience and provide personalized recommendations.</li>
                                        <li>- To comply with legal and regulatory requirements.</li>
                                    </ul>
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Information Sharing
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We may share your personal information with:
                                    <ul className="pl-3">
                                        <li>- Third-party service providers who assist us in delivering our services.</li>
                                        <li>- Financial institutions and mortgage lenders in connection with your mortgage application.</li>
                                        <li>- Government authorities or law enforcement agencies when required by law.</li>
                                    </ul>
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Security
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We are committed to ensuring that your information is secure. We have 
                                    implemented suitable physical, electronic, and managerial procedures 
                                    to safeguard and secure the information we collect.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Changes to This Privacy Policy
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We may update this Privacy Policy from time to time. We will notify 
                                    you of any changes by posting the new Privacy Policy on this page.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Contact Us
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    If you have any questions or concerns about our Privacy Policy, please contact 
                                    us at <a href="mailto:info@wizhull.com">info@wizhull.com</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SideFooter />
        </>
    )
}

export default PrivacyPolicy