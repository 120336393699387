import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import "./assets/css/output.css"
import "./assets/css/style.css"
import ReactGA from 'react-ga'

ReactGA.initialize('G-Y4GJYDQNJN')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
reportWebVitals()