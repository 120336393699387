import React, { useEffect } from "react"

import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

// images
import tailorSolution from "../assets/img/tailorSolution.jpg"
import blog1 from "../assets/img/blog-1.png"
import blog2 from "../assets/img/blog-2.png"
import blog3 from "../assets/img/blog-3.png"
import blog6 from "../assets/img/blog-6.png"

// svg
import service1 from "../assets/img/service-1.svg"
import service2 from "../assets/img/service-2.svg"
import service3 from "../assets/img/service-3.svg"
import service4 from "../assets/img/service-4.svg"



const Services = () => {

    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])
    
    return (
        <>
            <SideHeaders />

            <main className="w-full min-h-screen">
                <section className="page-title-wrapper w-full h-[345px]">
                    <div className="theme-container mx-auto h-full">
                    <div
                        className="w-full h-full flex sm:flex-row flex-col sm:justify-between justify-center space-y-3 sm:space-y-0 items-center"
                    >
                        <h2 className="headline-default text-primary-900">Our Services</h2>
                        <ul className="breadcrumb-wrapper flex space-x-5 items-center">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                <div className="flex space-x-5 items-center">
                                    <span
                                    className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                    >Home</span
                                    >
                                    <span className="ico">
                                    <svg
                                        width="9"
                                        height="13"
                                        viewBox="0 0 9 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                        fill="#028835"
                                        />
                                    </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <div className="flex space-x-5 items-center">
                                <span
                                className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                >Our Services</span
                                >
                                <span className="ico">
                                <svg
                                    width="9"
                                    height="13"
                                    viewBox="0 0 9 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                    fill="#028835"
                                    />
                                </svg>
                                </span>
                            </div>
                        </li>
                        </ul>
                    </div>
                    </div>
                </section>
                <div className="lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]">
                    <div className="theme-container mx-auto">
                        <div className="body-content-wrapper lg:col-span-8 mb-10 lg:mb-0">
                            <div className="border-b border-primaryBorder pb-[60px] mb-[60px]">
                                <div
                                className="grid sm:grid-cols-2 grid-cols-1 gap-[30px] items-center mb-[60px]"
                                >
                                <div className="article-area">
                                    <p
                                    className="sm:text-xl sm:leading-8 text-lg font-bold spline-sans text-primary-500 mb-2"
                                    >
                                        What we offer
                                    </p>
                                    <h2 className="headline-default text-primary-900 mb-5">
                                        Future made for Your Homeownership Mortgage
                                    </h2>
                                    <p
                                    className="text-base leading-[27px] text-primary-100 mb-[60px]"
                                    >
                                        In the competitive world of mortgage brokerage, staying ahead means providing unmatched services that cater to the unique needs of each client. At WizHull, we pride ourselves on offering comprehensive solutions that simplify the mortgage process for our clients, whether they're first-time homebuyers, seasoned investors, or somewhere in between.
                                    </p>
                                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-[30px]">
                                    <div>
                                        <p className="flex space-x-2.5 items-center">
                                        <span>
                                            <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8ZM11.5938 6.625C11.9375 6.28125 11.9375 5.75 11.5938 5.40625C11.25 5.0625 10.7188 5.0625 10.375 5.40625L7 8.78125L5.59375 7.40625C5.25 7.0625 4.71875 7.0625 4.375 7.40625C4.03125 7.75 4.03125 8.28125 4.375 8.625L6.375 10.625C6.71875 10.9688 7.25 10.9688 7.59375 10.625L11.5938 6.625Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        <span
                                            className="text-lg font-bold spline-sans leading-7 text-primary-900"
                                            >Market Mastery</span
                                        >
                                        </p>
                                        <p className="text-base leading-[27px] text-primary-100">
                                            Expert guidance through personalized mortgage strategies designed to meet your unique needs.
                                        </p>
                                    </div>
                                    <div>
                                        <p className="flex space-x-2.5 items-center">
                                        <span>
                                            <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8ZM11.5938 6.625C11.9375 6.28125 11.9375 5.75 11.5938 5.40625C11.25 5.0625 10.7188 5.0625 10.375 5.40625L7 8.78125L5.59375 7.40625C5.25 7.0625 4.71875 7.0625 4.375 7.40625C4.03125 7.75 4.03125 8.28125 4.375 8.625L6.375 10.625C6.71875 10.9688 7.25 10.9688 7.59375 10.625L11.5938 6.625Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        <span
                                            className="text-lg font-bold spline-sans leading-7 text-primary-900"
                                            >Tailored Solutions</span
                                        >
                                        </p>
                                        <p className="text-base leading-[27px] text-primary-100">
                                        Unrivaled access to competitive rates and insightful advice, powered by deep industry expertise.                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="thumb">
                                    <div className="w-full h-[400px] rounded overflow-hidden">
                                    <img
                                        src={tailorSolution}
                                        alt="service"
                                        className="w-full h-full object-cover"
                                    />
                                    </div>
                                </div>
                                </div>
                                <p className="text-base leading-[27px] text-primary-100 mb-2.5">
                                    Understanding the challenges of securing a mortgage, WizHull is dedicated to simplifying this complex journey for you. Our seasoned professionals are committed to delivering comprehensive mortgage solutions, designed to meet your unique needs. From securing the most competitive rates to managing the intricate paperwork, WizHull is by your side every step of the way. We aim to ensure a seamless and supportive experience, demystifying the process and empowering you with the knowledge to make informed decisions.
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    At WizHull, we take pride in our personalized approach, recognizing that each client's path to homeownership is unique. Our team not only navigates the market to find the best financial solutions but also provides clear, straightforward guidance through the entire mortgage process. By partnering with us, you gain a dedicated ally, committed to facilitating your journey toward securing a mortgage with expertise, care, and unparalleled support.                                </p>
                            </div>
                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-[30px]">
                                <div
                                className="w-full rounded overflow-hidden border border-primaryBorder group"
                                >
                                <div className="w-full px-10 pt-10 pb-[50px]">
                                    <h2
                                    className="font-bold text-gray-900 spline-sans text-lg leading-7 mb-2.5"
                                    >
                                    Expertise You Can Rely On
                                    </h2>
                                    <p className="text-base leading-[27px] text-primary-100">
                                    Our experienced mortgage brokers are committed to finding your ideal mortgage solution, expertly navigating the market's complexities for you.                                    </p>
                                </div>
                                <div className="w-full h-[275px] relative">
                                    <div className="w-full h-full">
                                    <img
                                        src={blog1}
                                        alt="blog"
                                        className="w-full h-full object-cover"
                                    />
                                    </div>
                                    <div
                                    className="w-[60px] h-[60px] border border-primaryBorder rounded flex justify-center items-center absolute left-10 -top-[30px] bg-white z-10"
                                    >
                                    <img src={service1} alt="" />
                                    </div>
                                    <div
                                    className="w-full h-full flex justify-center bg-primary-500 bg-opacity-80 items-center absolute left-0 top-0 opacity-0 group-hover:opacity-100 common-trans"
                                    >
                                    {/* <a href="#"> */}
                                        <div
                                        className="w-[60px] h-[60px] rounded-full bg-white flex justify-center items-center"
                                        >
                                        <span>
                                            <svg
                                            width="15"
                                            height="13"
                                            viewBox="0 0 15 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M13.6875 7.71875L8.6875 12.7188C8.5 12.9062 8.25 13 8 13C7.71875 13 7.46875 12.9062 7.28125 12.7188C6.875 12.3438 6.875 11.6875 7.28125 11.3125L10.5625 8H1C0.4375 8 0 7.5625 0 7C0 6.46875 0.4375 6 1 6H10.5625L7.28125 2.71875C6.875 2.34375 6.875 1.6875 7.28125 1.3125C7.65625 0.90625 8.3125 0.90625 8.6875 1.3125L13.6875 6.3125C14.0938 6.6875 14.0938 7.34375 13.6875 7.71875Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                    {/* {/* </a> */}
                                    </div>
                                </div>
                                </div>
                                <div
                                className="w-full rounded overflow-hidden border border-primaryBorder group"
                                >
                                <div className="w-full px-10 pt-10 pb-[50px]">
                                    <h2
                                    className="font-bold text-gray-900 spline-sans text-lg leading-7 mb-2.5"
                                    >
                                    Tailored Risk Solutions
                                    </h2>
                                    <p className="text-base leading-[27px] text-primary-100">
                                    We provide personalized risk assessment and management strategies, designed to safeguard your financial well-being against market volatilities.
                                    </p>
                                </div>
                                <div className="w-full h-[275px] relative">
                                    <div className="w-full h-full">
                                    <img
                                        src={blog2}
                                        alt="blog"
                                        className="w-full h-full object-cover"
                                    />
                                    </div>
                                    <div
                                    className="w-[60px] h-[60px] border border-primaryBorder rounded flex justify-center items-center absolute left-10 -top-[30px] bg-white z-10"
                                    >
                                    <img src={service2} alt="" />
                                    </div>
                                    <div
                                    className="w-full h-full flex justify-center bg-primary-500 bg-opacity-80 items-center absolute left-0 top-0 opacity-0 group-hover:opacity-100 common-trans"
                                    >
                                    {/* <a href="#"> */}
                                        <div
                                        className="w-[60px] h-[60px] rounded-full bg-white flex justify-center items-center"
                                        >
                                        <span>
                                            <svg
                                            width="15"
                                            height="13"
                                            viewBox="0 0 15 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M13.6875 7.71875L8.6875 12.7188C8.5 12.9062 8.25 13 8 13C7.71875 13 7.46875 12.9062 7.28125 12.7188C6.875 12.3438 6.875 11.6875 7.28125 11.3125L10.5625 8H1C0.4375 8 0 7.5625 0 7C0 6.46875 0.4375 6 1 6H10.5625L7.28125 2.71875C6.875 2.34375 6.875 1.6875 7.28125 1.3125C7.65625 0.90625 8.3125 0.90625 8.6875 1.3125L13.6875 6.3125C14.0938 6.6875 14.0938 7.34375 13.6875 7.71875Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                    {/* </a> */}
                                    </div>
                                </div>
                                </div>
                                <div
                                className="w-full rounded overflow-hidden border border-primaryBorder group"
                                >
                                <div className="w-full px-10 pt-10 pb-[50px]">
                                    <h2
                                    className="font-bold text-gray-900 spline-sans text-lg leading-7 mb-2.5"
                                    >
                                    Saving Made Simple
                                    </h2>
                                    <p className="text-base leading-[27px] text-primary-100">
                                    Through strategic planning and negotiation, we strive to reduce your mortgage costs, helping you save money in the long run.
                                    </p>
                                </div>
                                <div className="w-full h-[275px] relative">
                                    <div className="w-full h-full">
                                    <img
                                        src={blog3}
                                        alt="blog"
                                        className="w-full h-full object-cover"
                                    />
                                    </div>
                                    <div
                                    className="w-[60px] h-[60px] border border-primaryBorder rounded flex justify-center items-center absolute left-10 -top-[30px] bg-white z-10"
                                    >
                                    <img src={service3} alt="" />
                                    </div>
                                    <div
                                    className="w-full h-full flex justify-center bg-primary-500 bg-opacity-80 items-center absolute left-0 top-0 opacity-0 group-hover:opacity-100 common-trans"
                                    >
                                    {/* <a href="#"> */}
                                        <div
                                        className="w-[60px] h-[60px] rounded-full bg-white flex justify-center items-center"
                                        >
                                        <span>
                                            <svg
                                            width="15"
                                            height="13"
                                            viewBox="0 0 15 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M13.6875 7.71875L8.6875 12.7188C8.5 12.9062 8.25 13 8 13C7.71875 13 7.46875 12.9062 7.28125 12.7188C6.875 12.3438 6.875 11.6875 7.28125 11.3125L10.5625 8H1C0.4375 8 0 7.5625 0 7C0 6.46875 0.4375 6 1 6H10.5625L7.28125 2.71875C6.875 2.34375 6.875 1.6875 7.28125 1.3125C7.65625 0.90625 8.3125 0.90625 8.6875 1.3125L13.6875 6.3125C14.0938 6.6875 14.0938 7.34375 13.6875 7.71875Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                    {/* </a> */}
                                    </div>
                                </div>
                                </div>
                                <div
                                className="w-full rounded overflow-hidden border border-primaryBorder group"
                                >
                                <div className="w-full px-10 pt-10 pb-[50px]">
                                    <h2
                                    className="font-bold text-gray-900 spline-sans text-lg leading-7 mb-2.5"
                                    >
                                    Comprehensive Coverage
                                    </h2>
                                    <p className="text-base leading-[27px] text-primary-100">
                                    From mortgage options to contingency planning, we offer a range of services to protect your investment from any eventuality.
                                    </p>
                                </div>
                                <div className="w-full h-[275px] relative">
                                    <div className="w-full h-full">
                                    <img
                                        src={blog6}
                                        alt="blog"
                                        className="w-full h-full object-cover"
                                    />
                                    </div>
                                    <div
                                    className="w-[60px] h-[60px] border border-primaryBorder rounded flex justify-center items-center absolute left-10 -top-[30px] bg-white z-10"
                                    >
                                    <img src={service4} alt="" />
                                    </div>
                                    <div
                                    className="w-full h-full flex justify-center bg-primary-500 bg-opacity-80 items-center absolute left-0 top-0 opacity-0 group-hover:opacity-100 common-trans"
                                    >
                                    {/* <a href="#"> */}
                                        <div
                                        className="w-[60px] h-[60px] rounded-full bg-white flex justify-center items-center"
                                        >
                                        <span>
                                            <svg
                                            width="15"
                                            height="13"
                                            viewBox="0 0 15 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M13.6875 7.71875L8.6875 12.7188C8.5 12.9062 8.25 13 8 13C7.71875 13 7.46875 12.9062 7.28125 12.7188C6.875 12.3438 6.875 11.6875 7.28125 11.3125L10.5625 8H1C0.4375 8 0 7.5625 0 7C0 6.46875 0.4375 6 1 6H10.5625L7.28125 2.71875C6.875 2.34375 6.875 1.6875 7.28125 1.3125C7.65625 0.90625 8.3125 0.90625 8.6875 1.3125L13.6875 6.3125C14.0938 6.6875 14.0938 7.34375 13.6875 7.71875Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                    {/* </a> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <SideFooter />
            </>
    )
}

export default Services