import React, { useState } from "react"
import Lottie from 'react-lottie'
import animationData from "../../assets/img/bot.json"
import ReactGA from 'react-ga'

// context
import { useChatProvider } from "../../Context/ChatProvider"

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

const FAB = () => {
    const { setValue } = useChatProvider()
    const handleClick = () => {
        setValue(true)
        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on FAB for chatbot',
            label: 'FAB',
        })
    }

    return (
        <div className="FloatingAB" onClick={handleClick}>
            <Lottie options={defaultOptions}
                height={80}
                width={80}
            />
        </div>
    )
}

export default FAB