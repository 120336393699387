import React, { useEffect, useRef, useState } from "react"
import Carousel from "react-multi-carousel"
import CountUp from "react-countup"
import useMessage from "antd/es/message/useMessage"
import { Spin } from "antd"
import MainHeader from "../Component/Headers/MainHeader";
import Footer from "../Component/Footers/Footer"
import { sendContact } from "../Actions/commonController"
import ReactGA from 'react-ga'
import { useLocation } from "react-router-dom"

// images
import heroBackgroundImage from "../assets/img/img-hero.jpg"
import aboutThumb from "../assets/img/about-thumb.png"
import insu1 from "../assets/img/insu-1.png"
import insu2 from "../assets/img/insu-2.png"
import insu3 from "../assets/img/insu-3.png"
import funFactBG from "../assets/img/fun-fact-bg.png"
import funFactThumb from "../assets/img/fun-fact-thum.png"
import testimonial1 from "../assets/img/testimonial-1.png"
import testimonial2 from "../assets/img/testimonial-2.png"
import testimonial3 from "../assets/img/testimonial-3.png"
import testimonial4 from "../assets/img/testimonial-4.png"
import getInThumb from "../assets/img/get-in-thumb.png"
import getInThumb2 from "../assets/img/get-in-thumb-2.png"

// svg
import service1 from "../assets/img/service-1.svg"
import service2 from "../assets/img/service-2.svg"
import service3 from "../assets/img/service-3.svg"
import service4 from "../assets/img/service-4.svg"
import project from "../assets/img/project.svg"
import client from "../assets/img/client.svg"
import members from "../assets/img/members.svg"
import award from "../assets/img/award.svg"
import progress1 from "../assets/img/progress-1.svg"
import progress2 from "../assets/img/progress-2.svg"
import progress3 from "../assets/img/progress-3.svg"
import progress4 from "../assets/img/progress-4.svg"


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
}

const initialValue = {
    name: "",
    phone: "",
    email: "",
    message: ""
}

const Home = () => {
    const [lead, setLead] = useState(false)
    const [messageApi, messageTrigger] = useMessage()
    const [loader, setLoader] = useState(false)
    const [formValue, setFormValue] = useState(initialValue)
    const { name, phone, email, message } = formValue

    let mySectionRef = useRef(null);

    let scrollToSection = () => {
        window.scrollTo({
            top: mySectionRef.current.offsetTop,
            behavior: 'smooth',
        })
    }

    const successMessage = () => {
        messageApi.open({
            type: 'success',
            content: 'Your request has been submitted successfully! You will be receiving a call shortly regarding your inquiry.',
            duration: 7,
        })
    }

    const loadingMessage = () => {
        messageApi.open({
            type: 'loading',
            content: 'Submitting your details...',
            duration: 3,
        })
    }

    const errorMessage = () => {
        messageApi.open({
          type: 'error',
          content: 'There was an error while submitting the form.',
        })
    }

    const handleChange = (e) => {
        try {
            let { name, value } = e.target;
            setFormValue({ ...formValue, [name]: value });
        } catch (err) {
            console.log("An error has occured when inputting data in the form", err.message)
        }
    }

    const handleFormSubmit = async (e) => {
        try {
            ReactGA.event({
                category: 'Button Click',
                action: 'Clicked on form submit',
                label: 'Home Page',
            })

            e.preventDefault()
            setLoader(true)
            loadingMessage()

            let formData = formValue
            let data = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                message: formData.message,
                page: "home"
            }

            let { status } = await sendContact(data)

            if(status == "success") {
                setLead(true)
                messageApi.destroy()
                successMessage()
            } else {
                messageApi.destroy()
                errorMessage()
            }

            setLoader(false)
        } catch(err) {
            console.log("An error has occured when submitting form!", err.message)
        }
    }

    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            {messageTrigger}
            <MainHeader />
            <section className="hero-area w-full relative">
                <div
                className="h-full flex xl:justify-center justify-start items-center relative z-20"
                >
                <div className="theme-container xl:mx-auto">
                    <h1
                    data-aos="fade-up"
                    className="text-primary-900 xl:text-[96px] sm:text-6xl text-5xl font-bold xl:leading-[95px] mb-5"
                    > 
                    Secure your<br />
                    Future with us
                    </h1>
                    <p className="text-primary-900 text-base leading-7 mb-9">
                    Unlock your path to homeownership with us. Your future, secured through 
                    <br />
                    smart mortgage solutions
                    </p>
                    <div
                    className="flex flex-col sm:flex-row sm:space-x-10 space-y-10 sm:space-y-0 sm:items-center"
                    >
                    {/* <a href="project.html"> */}
                        <div
                        onClick={() => {scrollToSection()}}
                        className="px-[46px] py-[20px] bg-primary-500 rounded-lg text-white text-lg spline-sans leading-none hover:bg-primary-900 common-trans inline"
                        >
                        <span>Apply now</span>
                        </div>
                    {/* </a> */}
                    </div>
                </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    className="hero-image-1 w-full h-full absolute left-0 top-0 z-10 bg-no-repeat bg-cover"
                    style={{backgroundImage: `url(${heroBackgroundImage})`}}
                ></div>
            </section>

            <section className="about-area w-full lg:py-[120px] py-[60px]">
                <div className="theme-container mx-auto">
                <div
                    className="w-full grid xl:grid-cols-2 grid-cols-1 lg:gap-[66px] gap-[30px] items-center relative"
                >
                    <div className="">
                    <div
                        className="thumbnail-area 2xl:w-[721px] w-full 2xl:absolute 2xl:-left-[150px] -top-8 relative"
                    >
                        <img
                            src={aboutThumb}
                            alt="about"
                            className="relative z-10 w-full h-full"
                        />
                        <div
                        className="about-shape gear-2 xl:w-[385px] xl:h-[385px] md:w-[500px] md:h-[500px] w-[250px] h-[250px]"
                        ></div>
                        <div
                        className="w-[250px] h-[94px] rounded-[16px] bg-primary-500 flex justify-center items-center absolute sm:top-[432px] top-[200px] sm:right-[156px] right-5 z-10"
                        >
                        <div>
                            <div className="flex space-x-6 items-center">
                            <span>
                                <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <circle cx="20" cy="20" r="20" fill="white" />
                                <path
                                    d="M26.332 25.7943C25.544 25.5133 24.258 25.4943 23.688 25.2813C23.2492 25.1672 22.8347 24.974 22.465 24.7113C22.3097 24.0566 22.2424 23.3839 22.265 22.7113C22.558 22.4302 22.7955 22.0964 22.965 21.7273C23.1351 21.0941 23.2479 20.4468 23.302 19.7933C23.302 19.7933 23.766 19.9933 23.949 19.0623C24.104 18.2623 24.399 17.8393 24.322 17.2483C24.245 16.6573 23.914 16.7983 23.914 16.7983C24.273 15.9711 24.4135 15.0655 24.322 14.1683C24.3166 13.591 24.1924 13.0209 23.9571 12.4935C23.7218 11.9662 23.3806 11.493 22.9545 11.1032C22.5285 10.7135 22.0268 10.4156 21.4806 10.2281C20.9345 10.0406 20.3556 9.96749 19.78 10.0133C19.2044 9.96746 18.6254 10.0402 18.0789 10.227C17.5324 10.4138 17.0301 10.7107 16.603 11.0994C16.1758 11.4881 15.833 11.9603 15.5956 12.4868C15.3583 13.0133 15.2315 13.5829 15.223 14.1603C15.1325 15.0567 15.2701 15.9614 15.623 16.7903C15.623 16.7903 15.293 16.6493 15.215 17.2403C15.137 17.8313 15.427 18.2603 15.586 19.0603C15.769 19.9953 16.233 19.7913 16.233 19.7913C16.2878 20.4447 16.4005 21.092 16.57 21.7253C16.7396 22.0944 16.9771 22.4282 17.27 22.7093C17.2927 23.3819 17.2254 24.0546 17.07 24.7093C16.7014 24.9723 16.2865 25.1633 15.847 25.2723C15.285 25.4903 13.998 25.5183 13.203 25.7993C12.2445 26.1586 11.4205 26.8055 10.844 27.6514C10.2676 28.4973 9.96683 29.5008 9.98304 30.5243H29.545C29.5643 29.5005 29.2656 28.4958 28.6902 27.6487C28.1148 26.8017 27.2909 26.1538 26.332 25.7943Z"
                                    fill="#028835"
                                />
                                <path
                                    d="M11.63 22.3684C12.4561 22.3573 13.2651 22.132 13.978 21.7144C12.888 20.0624 13.478 18.1354 13.254 16.3354C13.243 15.9259 13.1472 15.5231 12.9728 15.1524C12.7983 14.7817 12.549 14.4511 12.2405 14.1816C11.9319 13.912 11.5709 13.7093 11.1801 13.5862C10.7894 13.4631 10.3773 13.4224 9.97 13.4664H9.942C9.53708 13.428 9.12858 13.4729 8.74171 13.5985C8.35485 13.7241 7.99783 13.9276 7.69271 14.1966C7.38758 14.4655 7.14083 14.7942 6.96768 15.1622C6.79453 15.5303 6.69866 15.9299 6.686 16.3364C6.461 18.1294 7.086 20.2744 5.969 21.7154C6.68409 22.1261 7.49988 22.3284 8.324 22.2994C8.36668 22.6942 8.34302 23.0934 8.254 23.4804C8.01199 23.682 7.72069 23.8156 7.41 23.8674C6.79373 24.0008 6.18571 24.1697 5.589 24.3734C5.1109 24.6233 4.71018 24.999 4.43013 25.4601C4.15007 25.9212 4.00134 26.45 4 26.9894H9.647C10.1603 26.0724 10.9468 25.3383 11.897 24.8894C12.4477 24.6587 13.0371 24.5345 13.634 24.5234C13.634 24.5234 13.901 24.1014 13.022 23.9394C12.5548 23.8369 12.1016 23.6787 11.672 23.4684C11.538 23.3184 11.63 22.3684 11.63 22.3684Z"
                                    fill="#028835"
                                />
                                <path
                                    d="M27.9417 22.3684C27.1157 22.3573 26.3066 22.132 25.5938 21.7144C26.6838 20.0624 26.0937 18.1354 26.3177 16.3354C26.3288 15.9259 26.4245 15.5231 26.599 15.1524C26.7734 14.7817 27.0227 14.4511 27.3313 14.1816C27.6398 13.912 28.0009 13.7093 28.3916 13.5862C28.7824 13.4631 29.1944 13.4224 29.6017 13.4664H29.6297C30.0347 13.428 30.4432 13.4729 30.83 13.5985C31.2169 13.7241 31.5739 13.9276 31.879 14.1966C32.1842 14.4655 32.4309 14.7942 32.6041 15.1622C32.7772 15.5303 32.8731 15.9299 32.8857 16.3364C33.1107 18.1294 32.4857 20.2744 33.6027 21.7154C32.8877 22.1261 32.0719 22.3284 31.2477 22.2994C31.2051 22.6942 31.2287 23.0934 31.3177 23.4804C31.5598 23.682 31.8511 23.8156 32.1617 23.8674C32.778 24.0008 33.386 24.1697 33.9827 24.3734C34.4608 24.6233 34.8616 24.999 35.1416 25.4601C35.4217 25.9212 35.5704 26.45 35.5717 26.9894H29.9247C29.4115 26.0724 28.6249 25.3383 27.6747 24.8894C27.1241 24.6587 26.5347 24.5345 25.9377 24.5234C25.9377 24.5234 25.6707 24.1014 26.5497 23.9394C27.0169 23.8369 27.4702 23.6787 27.8997 23.4684C28.0337 23.3184 27.9417 22.3684 27.9417 22.3684Z"
                                    fill="#028835"
                                />
                                </svg>
                            </span>
                            <div>
                                <p className="text-white text-xl font-bold leading-[26px]">
                                5000+
                                </p>
                                <p className="text-base leading-5 text-white">
                                Satisfied Client
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="about-article-area">
                    <h2 className="headline-default text-primary-900 mb-10">
                        Mortgages tailored to your <br className="lg:block hidden" />
                        homeownership goals
                    </h2>
                    <div
                        className="xl:w-full max-w-4xl sm:p-[30px] p-5 border border-primaryBorder rounded-[10px] flex flex-col space-y-5 mb-5"
                    >
                        <div className="item sm:flex sm:space-x-5 sm:items-center">
                        <div
                            className="w-[60px] h-[60px] bg-primary-50 sm:flex hidden justify-center items-center rounded"
                        >
                            <span>
                            <svg
                                width="18"
                                height="13"
                                viewBox="0 0 18 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M17.3594 0.640625C17.8672 1.10938 17.8672 1.92969 17.3594 2.39844L7.35938 12.3984C6.89062 12.9062 6.07031 12.9062 5.60156 12.3984L0.601562 7.39844C0.09375 6.92969 0.09375 6.10938 0.601562 5.64062C1.07031 5.13281 1.89062 5.13281 2.35938 5.64062L6.5 9.74219L15.6016 0.640625C16.0703 0.132812 16.8906 0.132812 17.3594 0.640625Z"
                                fill="#028835"
                                />
                            </svg>
                            </span>
                        </div>
                        <div className="flex-1">
                            <div className="flex justify-between items-center mb-2.5">
                            <span className="text-lg text-gray-900 font-bold spline-sans"
                                >Swift Mortgage Assistance</span
                            >
                            <span className="text-lg text-gray-900 font-bold spline-sans"
                                >95%</span
                            >
                            </div>
                            <div
                            className="w-full relative overflow-hidden h-[5px] rounded-[10px] bg-gray-100"
                            >
                            <div
                                style={{width: "95%"}}
                                className="absolute left-0 top-0 bg-primary-500 h-full rounded-[10px]"
                            ></div>
                            </div>
                        </div>
                        </div>
                        <div className="item sm:flex sm:space-x-5 sm:items-center">
                        <div
                            className="w-[60px] h-[60px] bg-primary-50 sm:flex hidden justify-center items-center rounded"
                        >
                            <span>
                            <svg
                                width="18"
                                height="13"
                                viewBox="0 0 18 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M17.3594 0.640625C17.8672 1.10938 17.8672 1.92969 17.3594 2.39844L7.35938 12.3984C6.89062 12.9062 6.07031 12.9062 5.60156 12.3984L0.601562 7.39844C0.09375 6.92969 0.09375 6.10938 0.601562 5.64062C1.07031 5.13281 1.89062 5.13281 2.35938 5.64062L6.5 9.74219L15.6016 0.640625C16.0703 0.132812 16.8906 0.132812 17.3594 0.640625Z"
                                fill="#028835"
                                />
                            </svg>
                            </span>
                        </div>
                        <div className="flex-1">
                            <div className="flex justify-between items-center mb-2.5">
                            <span className="text-lg text-gray-900 font-bold spline-sans"
                                >Efficient Mortgage Support</span
                            >
                            <span className="text-lg text-gray-900 font-bold spline-sans"
                                >90%</span
                            >
                            </div>
                            <div
                            className="w-full relative overflow-hidden h-[5px] rounded-[10px] bg-gray-100"
                            >
                            <div
                                style={{width: "90%"}}
                                className="absolute left-0 top-0 bg-primary-500 h-full rounded-[10px]"
                            ></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <p className="xl:w-full max-w-4xl text-base text-primary-100 mb-14">
                        At Wizhull, we take pride in our mortgage assistance 
                        services. With a 95% success rate for fast response mortgage applications 
                        and a 90% rate for efficient mortgage support, we're dedicated to 
                        helping you achieve your homeownership goals
                    </p>
                    </div>
                </div>
                </div>
            </section>

            <section className="service-area w-full lg:py-[120px] py-[60px]">
                <div className="theme-container mx-auto h-full items-center">
                <div
                    className="w-full grid xl:grid-cols-2 grid-cols-1 gap-[60px] xl:gap-0 items-center"
                >
                    <div className="grid-item">
                    <h2 className="headline-default text-primary-900 mb-5">
                        Why Choose Our <br />
                        Mortgage Services?
                    </h2>
                    <p className="text-base text-primary-100 mb-14">
                        We offer a comprehensive range of mortgage solutions tailored<br /> 
                        to your needs. With years of experience in the industry, our<br /> 
                        team is committed to guiding you through the homeownership journey. <br />
                        Explore the benefits of choosing us for your mortgage needs.
                    </p>
                    </div>
                    <div className="grid-item">
                    <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[30px] gap-5">
                        <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            className="service-item p-[30px] rounded-[10px] bg-white group"
                        >
                        {/* <a href="service-details.html"> */}
                            <div className="flex justify-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded flex justify-center items-center bg-primary-50"
                            >
                                <img src={service1} alt="" />
                            </div>
                            </div>
                            <h2
                            className="text-lg leading-7 font-bold spline-sans text-center text-primary-900 mb-5 group-hover:underline"
                            >
                            Flexible Options
                            </h2>
                            <p className="text-base leading-7 text-center text-primary-100">
                            Customized mortgage plans to suit your financial goals
                            </p>
                        {/* </a> */}
                        </div>
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="service-item p-[30px] rounded-[10px] bg-white group"
                        >
                        {/* <a href="service-details.html"> */}
                            <div className="flex justify-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded flex justify-center items-center bg-primary-50"
                            >
                                <img src={service2} alt="" />
                            </div>
                            </div>
                            <h2
                            className="text-lg leading-7 font-bold spline-sans text-center text-primary-900 mb-5 group-hover:underline"
                            >
                            Competitive Rates
                            </h2>
                            <p className="text-base leading-7 text-center text-primary-100">
                            Access competitive interest rates for your dream home
                            </p>
                        {/* </a> */}
                        </div>
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="service-item p-[30px] rounded-[10px] bg-white group"
                        >
                        {/* <a href="service-details.html"> */}
                            <div className="flex justify-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded flex justify-center items-center bg-primary-50"
                            >
                                <img src={service3} alt="" />
                            </div>
                            </div>
                            <h2
                            className="text-lg leading-7 font-bold spline-sans text-center text-primary-900 mb-5 group-hover:underline"
                            >
                            Fast Approvals
                            </h2>
                            <p className="text-base leading-7 text-center text-primary-100">
                            Streamlined processes for quick mortgage approvals
                            </p>
                        {/* </a> */}
                        </div>
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="service-item p-[30px] rounded-[10px] bg-white group"
                        >
                        {/* <a href="service-details.html"> */}
                            <div className="flex justify-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded flex justify-center items-center bg-primary-50"
                            >
                                <img src={service4} alt="" />
                            </div>
                            </div>
                            <h2
                            className="text-lg leading-7 font-bold spline-sans text-center text-primary-900 mb-5 group-hover:underline"
                            >
                            Expert Guidance
                            </h2>
                            <p className="text-base leading-7 text-center text-primary-100">
                            Our mortgage experts are here to assist you every step of the way
                            </p>
                        {/* </a> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="life-insu-area w-full lg:py-[120px] pt-[60px] pb-[100px] overflow-hidden">
                <div className="text-center">
                <h2 className="headline-default text-primary-900 lg:mb-[60px] mb-[30px]">
                    Fill your life with <br />
                    security and joy
                </h2>
                <Carousel draggable={true} infinite={true} autoPlay={true} centerMode={true} responsive={responsive} itemClass="p-4">
                    <div
                        className="w-full lg:h-[550px] h-[300px] flex justify-center items-center group"
                        style={{
                            backgroundImage: `url(${insu1})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat"
                        }}
                        >
                        {/* <a href="#"> */}
                            <div
                            className="px-[30px] py-[20px] bg-primary-500 rounded text-lg spline-sans font-semibold text-white opacity-0 group-hover:opacity-100 common-trans"
                            >
                            Legacy Protection
                            </div>
                        {/* </a> */}
                    </div>
                    <div
                    className="w-full lg:h-[550px] h-[300px] flex justify-center items-center group"
                    style={{
                        backgroundImage: `url(${insu2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }}
                    >
                    {/* <a href="#"> */}
                        <div
                        className="px-[30px] py-[20px] bg-primary-500 rounded text-lg spline-sans font-semibold text-white opacity-0 group-hover:opacity-100 common-trans"
                        >
                        Family's Financial Security
                        </div>
                    {/* </a> */}
                    </div>
                    <div
                    className="w-full lg:h-[550px] h-[300px] flex justify-center items-center group"
                    style={{
                        backgroundImage: `url(${insu3})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }}
                    >
                    {/* <a href="#"> */}
                        <div
                        className="px-[30px] py-[20px] bg-primary-500 rounded text-lg spline-sans font-semibold text-white opacity-0 group-hover:opacity-100 common-trans"
                        >
                        Peace of Mind
                        </div>
                    {/* </a> */}
                    </div>
                    <div
                    className="w-full lg:h-[550px] h-[300px] flex justify-center items-center group"
                    style={{
                        backgroundImage: `url(${insu2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }}
                    >
                    {/* <a href="#"> */}
                        <div
                        className="px-[30px] py-[20px] bg-primary-500 rounded text-lg spline-sans font-semibold text-white opacity-0 group-hover:opacity-100 common-trans"
                        >
                        Security Gift
                        </div>
                    {/* </a> */}
                    </div>
                </Carousel>
                </div>
            </section>

            <section className="fun-fact-area lg:pt-[120px] xl:pb-0 lg:pb-[120px] py-[60px] xl:min-h-[1000px]"
                style={{
                    backgroundImage: `url(${funFactBG})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}
            >
                <div className="theme-container mx-auto relative">
                    <div className="w-full flex flex-wrap lg:flex-nowrap gap-[30px] lg:gap-0 justify-between items-center xl:mb-[100px] lg:mb-[60px] mb-[30px]">
                        <div className="item flex space-x-5 items-center">
                            <div className="xl:w-[80px] xl:h-[80px] w-16 h-16 rounded-full bg-primary-500 flex justify-center items-center">
                                <img src={project} alt="" />
                            </div>
                            <div className="flex-1">
                                <p 
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    className="xl:text-[40px] font-bold text-white text-2xl xl:leading-[55px]">
                                <CountUp start={0} end={100} delay={0} enableScrollSpy={true}>
                                    {({ countUpRef }) => (
                                        <div>
                                        <span ref={countUpRef} />K+
                                        </div>
                                    )}
                                </CountUp>                                </p>
                                <p className="xl:text-base text-sm text-white leading-7">
                                    Complete Projects
                                </p>
                            </div>
                        </div>
                        <div className="w-[5px] h-[160px] bg-[#1A5E52] xl:block hidden"></div>
                        <div className="item flex space-x-5 items-center">
                            <div className="xl:w-[80px] xl:h-[80px] w-16 h-16 rounded-full bg-primary-500 flex justify-center items-center">
                                <img src={client} alt="" />
                            </div>
                            <div className="flex-1">
                                <p 
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    className="xl:text-[40px] font-bold text-white text-2xl xl:leading-[55px]">
                                <CountUp start={0} end={100} delay={0} enableScrollSpy={true}>
                                    {({ countUpRef }) => (
                                        <div>
                                        <span ref={countUpRef} />K+
                                        </div>
                                    )}
                                </CountUp>                                </p>
                                <p className="xl:text-base text-sm text-white leading-7">
                                    Client Satisfaction
                                </p>
                            </div>
                        </div>
                        <div className="w-[5px] h-[160px] bg-[#1A5E52] xl:block hidden"></div>
                        <div className="item flex space-x-5 items-center">
                            <div className="xl:w-[80px] xl:h-[80px] w-16 h-16 rounded-full bg-primary-500 flex justify-center items-center">
                                <img src={members} alt="" />
                            </div>
                            <div className="flex-1">
                                <p 
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    className="xl:text-[40px] font-bold text-white text-2xl xl:leading-[55px]">
                                <CountUp start={0} end={100} delay={0} enableScrollSpy={true}>
                                    {({ countUpRef }) => (
                                        <div>
                                        <span ref={countUpRef} />K+
                                        </div>
                                    )}
                                </CountUp>
                                </p>
                                <p className="xl:text-base text-sm text-white leading-7">
                                    Active Members
                                </p>
                            </div>
                        </div>
                        <div className="w-[5px] h-[160px] bg-[#1A5E52] xl:block hidden"></div>
                        <div className="item flex space-x-5 items-center">
                            <div className="xl:w-[80px] xl:h-[80px] w-16 h-16 rounded-full bg-primary-500 flex justify-center items-center">
                                <img src={award} alt="" />
                            </div>
                            <div className="flex-1">
                                <p 
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    className="xl:text-[40px] font-bold text-white text-2xl xl:leading-[55px]">
                                <CountUp start={0} end={100} delay={0} enableScrollSpy={true}>
                                    {({ countUpRef }) => (
                                        <div>
                                        <span ref={countUpRef} />K+
                                        </div>
                                    )}
                                </CountUp>
                                </p>
                                <p className="xl:text-base text-sm text-white leading-7">
                                    Winning Award
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="faq-wrapper w-full xl:absolute xl:flex items-center">
                        <div className="w-[740px] xl:block hidden rounded-[10px]">
                        <div className="w-full h-[888px]">
                            <img
                                src={funFactThumb}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </div>
                        </div>
                        <div className="flex-1">
                        <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            className="faq-main-wrapper xl:w-[630px] sm:px-[60px] sm:py-[80px] px-3 py-10 bg-white rounded xl:absolute top-20 xl:right-10"
                        >
                            <h2 className="headline-default text-primary-900 mb-[42px]">
                                Navigating Your Mortgage Journey
                            </h2>
                            <div className="w-full flex flex-col space-y-5">
                            <div
                                className="faq-item active w-full overflow-hidden rounded border border-primaryBorder"
                            >
                                <button
                                    type="button"
                                    className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                >
                                <span
                                    className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                    >What is the first step in getting a mortgage?</span
                                >
                                <span className="group-hover:text-white">
                                    <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="fill-current"
                                    >
                                    <path
                                        d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                    />
                                    </svg>
                                </span>
                                </button>
                                <div className="faq-body w-full bg-secondary">
                                <p
                                    className="sm:text-base text-sm leading-7 text-primary-100"
                                >
                                    The first step in getting a mortgage is to assess your financial situation. 
                                    Calculate your budget, gather financial documents, and check your credit score.
                                </p>
                                </div>
                            </div>
                            <div
                                className="faq-item w-full overflow-hidden rounded border border-primaryBorder"
                            >
                                <button
                                type="button"
                                className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                >
                                <span
                                    className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                    >How can I improve my chances of mortgage approval?</span
                                >
                                <span className="group-hover:text-white">
                                    <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="fill-current"
                                    >
                                    <path
                                        d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                    />
                                    </svg>
                                </span>
                                </button>
                                <div className="faq-body w-full bg-secondary">
                                <p
                                    className="sm:text-base text-sm leading-7 text-primary-100"
                                >
                                To improve your chances of mortgage approval, maintain a good credit score, 
                                save for a down payment, and reduce existing debt. It's also crucial to choose
                                a reputable lender.
                                </p>
                                </div>
                            </div>
                            <div
                                className="faq-item w-full overflow-hidden rounded border border-primaryBorder"
                            >
                                <button
                                type="button"
                                className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                >
                                <span
                                    className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                    >What types of mortgages are available?</span
                                >
                                <span className="group-hover:text-white">
                                    <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="fill-current"
                                    >
                                    <path
                                        d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                    />
                                    </svg>
                                </span>
                                </button>
                                <div className="faq-body w-full bg-secondary">
                                <p
                                    className="sm:text-base text-sm leading-7 text-primary-100"
                                >
                                    There are various types of mortgages, including fixed-rate and adjustable-rate. 
                                    Each has its own terms and benefits, so it's essential to explore 
                                    your options and choose the one that best fits your needs.
                                </p>
                                </div>
                            </div>
                            <div
                                className="faq-item w-full overflow-hidden rounded border border-primaryBorder"
                            >
                                <button
                                type="button"
                                className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                >
                                <span
                                    className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                    >What documents do I need to apply for a mortgage?</span
                                >
                                <span className="group-hover:text-white">
                                    <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="fill-current"
                                    >
                                    <path
                                        d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                    />
                                    </svg>
                                </span>
                                </button>
                                <div className="faq-body w-full bg-secondary">
                                <p
                                    className="sm:text-base text-sm leading-7 text-primary-100"
                                >
                                    When applying for a mortgage, you'll typically need documents like salary certificates, 
                                    payslips, proof of identification, and bank statements. Your lender will 
                                    provide a specific list of required documents during the application process.
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="progress-step xl:pt-[340px] lg:pt-[60px]">
                <div className="progress-step-wrapper py-[60px]">
                <div className="theme-container mx-auto">
                    <h2
                    className="text-center headline-default text-primary-900 lg:mb-[60px] mb-[30px]"
                    >
                    Applying for a Mortgage <br />
                    Made Simple
                    </h2>
                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 sm:gap-[30px] gap-5">
                        <div
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                            className="item p-[30px] bg-white rounded"
                        >
                            <div className="flex justify-between items-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                            >
                                <img src={progress1} alt="progress" />
                            </div>
                            <h2 className="counter spline-sans">01</h2>
                            </div>
                            <h2
                            className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                            >
                            Financial Assessment
                            </h2>
                            <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                            Evaluate finances and credit to set a realistic budget.
                            </p>
                        </div>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="200"
                            className="item p-[30px] bg-white rounded"
                        >
                            <div className="flex justify-between items-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                            >
                                <img src={progress4} alt="progress" />
                            </div>
                            <h2 className="counter spline-sans">02</h2>
                            </div>
                            <h2
                            className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                            >
                            Mortgage Preapproval
                            </h2>
                            <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                            Get preapproved to clarify your budget and impress sellers.
                            </p>
                        </div>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="300"
                            className="item p-[30px] bg-white rounded"
                        >
                            <div className="flex justify-between items-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                            >
                                <img src={progress2} alt="progress" />
                            </div>
                            <h2 className="counter spline-sans">03</h2>
                            </div>
                            <h2
                            className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                            >
                            Home Search
                            </h2>
                            <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                            Find your ideal home with a real estate agent's help.
                            </p>
                        </div>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="400"
                            className="item p-[30px] bg-white rounded"
                        >
                            <div className="flex justify-between items-center mb-5">
                            <div
                                className="w-[60px] h-[60px] rounded-full bg-secondary flex items-center justify-center"
                            >
                                <img src={progress3} alt="progress" />
                            </div>
                            <h2 className="counter spline-sans">04</h2>
                            </div>
                            <h2
                            className="xl:text-lg text-md font-bold spline-sans text-gray-700 mb-5"
                            >
                            Closing Process
                            </h2>
                            <p className="xl:text-base text-sm xl:leading-7 text-primary-100">
                            Complete the mortgage process and become a homeowner.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            {/* <section className="financ-section py-[60px]">
                <div className="theme-container mx-auto">
                    <div className="w-full relative">
                        <h2
                        className="headline-default text-primary-900 lg:mb-[60px] mb-[30px]"
                        >
                        The road to financial <br />
                        freedom
                        </h2>
                        <div className="financ-swiper overflow-hidden">
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <div className="item sm:p-10 p-5 border border-primaryBorder rounded">
                                        <div className="flex justify-between items-start mb-5">
                                        <div className="flex space-x-5 items-center">
                                            <div className="w-[80px] h-[80px] rounded-full border border-primary-500">
                                                <img src={testimonial1} alt="" className="w-full h-full object-cover"/>
                                            </div>
                                            <div className="flex-1">
                                                <div className="flex items-center mb-2.5">
                                                    <span>
                                                    <svg
                                                        width="16"
                                                        height="15"
                                                        viewBox="0 0 16 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                        d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                        fill="#FFDF00"
                                                        />
                                                    </svg>
                                                    </span>
                                                    <span>
                                                    <svg
                                                        width="16"
                                                        height="15"
                                                        viewBox="0 0 16 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                        d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                        fill="#FFDF00"
                                                        />
                                                    </svg>
                                                    </span>
                                                    <span>
                                                    <svg
                                                        width="16"
                                                        height="15"
                                                        viewBox="0 0 16 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                        d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                        fill="#FFDF00"
                                                        />
                                                    </svg>
                                                    </span>
                                                    <span>
                                                    <svg
                                                        width="16"
                                                        height="15"
                                                        viewBox="0 0 16 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                        d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                        fill="#FFDF00"
                                                        />
                                                    </svg>
                                                    </span>
                                                    <span>
                                                    <svg
                                                        width="16"
                                                        height="15"
                                                        viewBox="0 0 16 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                        d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                        fill="#FFDF00"
                                                        />
                                                    </svg>
                                                    </span>
                                                </div>
                                                <h2
                                                    className="text-gray-700 text-lg spline-sans font-semibold"
                                                >
                                                    John Smith
                                                </h2>
                                                <span className="text-base leading-7 text-primary-100">Real Estate Agent</span>
                                            </div>
                                        </div>
                                        <span className="sm:inline-block hidden">
                                            <svg
                                            width="35"
                                            height="26"
                                            viewBox="0 0 35 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M35 16.125C35 21.3594 30.7812 25.5 25.625 25.5H25C23.5938 25.5 22.5 24.4062 22.5 23C22.5 21.6719 23.5938 20.5 25 20.5H25.625C27.9688 20.5 30 18.5469 30 16.125V15.5H25C22.1875 15.5 20 13.3125 20 10.5V5.5C20 2.76562 22.1875 0.5 25 0.5H30C32.7344 0.5 35 2.76562 35 5.5V8V10.5V16.125ZM15 16.125C15 21.3594 10.7812 25.5 5.625 25.5H5C3.59375 25.5 2.5 24.4062 2.5 23C2.5 21.6719 3.59375 20.5 5 20.5H5.625C7.96875 20.5 10 18.5469 10 16.125V15.5H5C2.1875 15.5 0 13.3125 0 10.5V5.5C0 2.76562 2.1875 0.5 5 0.5H10C12.7344 0.5 15 2.76562 15 5.5V8V10.5V16.125Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                        <p className="text-primary-100 text-base leading-7">
                                            As a real estate agent, I always recommend Wiz to my clients. 
                                            Their mortgage experts are top-notch, ensuring quick approvals and happy clients.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div
                                        className="item sm:p-10 p-5 border border-primaryBorder rounded"
                                    >
                                        <div className="flex justify-between items-start mb-5">
                                        <div className="flex space-x-5 items-center">
                                            <div
                                            className="w-[80px] h-[80px] rounded-full border border-primary-500"
                                            >
                                            <img
                                                src={testimonial2}
                                                alt=""
                                                className="w-full h-full object-cover"
                                            />
                                            </div>
                                            <div className="flex-1">
                                            <div className="flex items-center mb-2.5">
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                            </div>
                                            <h2
                                                className="text-gray-700 text-lg spline-sans font-semibold"
                                            >
                                                Emily Carter
                                            </h2>
                                            <span className="text-base leading-7 text-primary-100"
                                                >Financial Advisor</span
                                            >
                                            </div>
                                        </div>
                                        <span className="sm:inline-block hidden">
                                            <svg
                                            width="35"
                                            height="26"
                                            viewBox="0 0 35 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M35 16.125C35 21.3594 30.7812 25.5 25.625 25.5H25C23.5938 25.5 22.5 24.4062 22.5 23C22.5 21.6719 23.5938 20.5 25 20.5H25.625C27.9688 20.5 30 18.5469 30 16.125V15.5H25C22.1875 15.5 20 13.3125 20 10.5V5.5C20 2.76562 22.1875 0.5 25 0.5H30C32.7344 0.5 35 2.76562 35 5.5V8V10.5V16.125ZM15 16.125C15 21.3594 10.7812 25.5 5.625 25.5H5C3.59375 25.5 2.5 24.4062 2.5 23C2.5 21.6719 3.59375 20.5 5 20.5H5.625C7.96875 20.5 10 18.5469 10 16.125V15.5H5C2.1875 15.5 0 13.3125 0 10.5V5.5C0 2.76562 2.1875 0.5 5 0.5H10C12.7344 0.5 15 2.76562 15 5.5V8V10.5V16.125Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                        <p className="text-primary-100 text-base leading-7">
                                        Wizhull offers competitive rates and tailored mortgage solutions. 
                                        They've been a trusted partner in helping my clients secure their 
                                        dream homes.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div
                                        className="item sm:p-10 p-5 border border-primaryBorder rounded"
                                    >
                                        <div className="flex justify-between items-start mb-5">
                                        <div className="flex space-x-5 items-center">
                                            <div
                                            className="w-[80px] h-[80px] rounded-full border border-primary-500"
                                            >
                                            <img
                                                src={testimonial3}
                                                alt=""
                                                className="w-full h-full object-cover"
                                            />
                                            </div>
                                            <div className="flex-1">
                                            <div className="flex items-center mb-2.5">
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                                <span>
                                                <svg
                                                    width="16"
                                                    height="15"
                                                    viewBox="0 0 16 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M10.418 4.87891L14.3281 5.45312C14.6562 5.50781 14.9297 5.72656 15.0391 6.05469C15.1484 6.35547 15.0664 6.71094 14.8203 6.92969L11.9766 9.74609L12.6602 13.7383C12.7148 14.0664 12.5781 14.3945 12.3047 14.5859C12.0312 14.8047 11.6758 14.8047 11.375 14.668L7.875 12.7812L4.34766 14.668C4.07422 14.8047 3.69141 14.8047 3.44531 14.5859C3.17188 14.3945 3.03516 14.0664 3.08984 13.7383L3.74609 9.74609L0.902344 6.92969C0.65625 6.71094 0.574219 6.35547 0.683594 6.05469C0.792969 5.72656 1.06641 5.50781 1.39453 5.45312L5.33203 4.87891L7.08203 1.24219C7.21875 0.941406 7.51953 0.75 7.875 0.75C8.20312 0.75 8.50391 0.941406 8.64062 1.24219L10.418 4.87891Z"
                                                    fill="#FFDF00"
                                                    />
                                                </svg>
                                                </span>
                                            </div>
                                            <h2
                                                className="text-gray-700 text-lg spline-sans font-semibold"
                                            >
                                                Sarah Johnson
                                            </h2>
                                            <span className="text-base leading-7 text-primary-100"
                                                >Homebuyer</span
                                            >
                                            </div>
                                        </div>
                                        <span className="sm:inline-block hidden">
                                            <svg
                                            width="35"
                                            height="26"
                                            viewBox="0 0 35 26"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M35 16.125C35 21.3594 30.7812 25.5 25.625 25.5H25C23.5938 25.5 22.5 24.4062 22.5 23C22.5 21.6719 23.5938 20.5 25 20.5H25.625C27.9688 20.5 30 18.5469 30 16.125V15.5H25C22.1875 15.5 20 13.3125 20 10.5V5.5C20 2.76562 22.1875 0.5 25 0.5H30C32.7344 0.5 35 2.76562 35 5.5V8V10.5V16.125ZM15 16.125C15 21.3594 10.7812 25.5 5.625 25.5H5C3.59375 25.5 2.5 24.4062 2.5 23C2.5 21.6719 3.59375 20.5 5 20.5H5.625C7.96875 20.5 10 18.5469 10 16.125V15.5H5C2.1875 15.5 0 13.3125 0 10.5V5.5C0 2.76562 2.1875 0.5 5 0.5H10C12.7344 0.5 15 2.76562 15 5.5V8V10.5V16.125Z"
                                                fill="#028835"
                                            />
                                            </svg>
                                        </span>
                                        </div>
                                        <p className="text-primary-100 text-base leading-7">
                                        I'm thrilled with the support I received. They made my first-time home 
                                        buying experience smooth and stress-free. Highly recommended!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section ref={mySectionRef} className="getin-touch-wrapper w-full lg:py-[120px] py-[60px]">
                <div className="w-full relative">
                <div className="w-full grid grid-cols-2 absolute">
                    <div className="img-1 w-full h-full">
                    <img
                        src={getInThumb}
                        alt="thumb-1"
                        className="w-full h-full"
                    />
                    </div>
                    <div className="img-1 w-full h-full">
                    <img
                        src={getInThumb2}
                        alt="thumb-2"
                        className="w-full h-full"
                    />
                    </div>
                </div>
                </div>
                <div
                    className="theme-container mx-auto relative z-10 lg:top-[120px] top-[60px]"
                >
                    <div className="w-full flex xl:justify-end justify-center">
                        <div
                            className="sm:w-[630px] w-full sm:px-[60px] sm:py-[80px] px-5 py-8 rounded-[10px] bg-white relative xl:left-[35px]"
                            style={{boxShadow: "0px 16px 52px rgba(0, 0, 0, 0.07)"}}
                        >
                            {
                                lead ?
                                    <h2 className="headline-default text-primary-900 font-bold">
                                        Form Submitted Successfully!
                                    </h2>
                                :
                                    <>
                                        <h2 className="headline-default text-primary-900 mb-10 font-bold">
                                            Fill your life with security <br className="sm:block hidden" />
                                            and joy
                                        </h2>
                                        <div className="w-full">
                                            <form action="#" className="w-full" id="contactForm">
                                            <label className="w-full mb-5 block">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={name}
                                                    onChange={handleChange}
                                                    className="border border-primaryBorder rounded h-[68px] w-full focus:outline-none focus:ring-0 placeholder:text-primary-100 p-5 text-base"
                                                    placeholder="Your Name"
                                                />
                                            </label>
                                            <label className="w-full mb-5 block">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    value={phone}
                                                    onChange={handleChange}
                                                    className="border border-primaryBorder rounded h-[68px] w-full focus:outline-none focus:ring-0 placeholder:text-primary-100 p-5 text-base"
                                                    placeholder="Phone Number"
                                                />
                                            </label>
                                            <label className="w-full mb-5 block">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                    className="border border-primaryBorder rounded h-[68px] w-full focus:outline-none focus:ring-0 placeholder:text-primary-100 p-5 text-base"
                                                    placeholder="E-mail"
                                                />
                                            </label>
                                            <label className="w-full mb-5 block">
                                                <textarea
                                                    placeholder="Your Message"
                                                    name="message"
                                                    value={message}
                                                    onChange={handleChange}
                                                    className="border border-primaryBorder rounded h-[160px] w-full focus:outline-none focus:ring-0 placeholder:text-primary-100 p-5 text-base"
                                                ></textarea>
                                            </label>
                                            {
                                                !loader ?
                                                    <button
                                                        onClick={handleFormSubmit}
                                                        type="submit"
                                                        className="w-full h-[68px] rounded text-white text-lg font-semibold spline-sans bg-primary-500 hover:bg-primary-900 common-trans"
                                                    >
                                                        Submit Now
                                                    </button>
                                                :
                                                <button
                                                    disabled
                                                    className="w-full h-[68px] rounded text-white text-lg font-semibold spline-sans bg-primary-500 hover:bg-primary-900 common-trans"
                                                >
                                                    <Spin />
                                                </button>
                                            }
                                            </form>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="blog-area w-full lg:py-[60px] py-[60px]">
            </section>

            <Footer />
            </>
    )
}

export default Home