import React, { useEffect, useState } from "react"
import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Link, useLocation, useParams } from "react-router-dom"
import ReactGA from 'react-ga'

import { fetchBlogDetail, fetchRandomBlog } from "../Actions/commonController"
import { Skeleton } from "antd"
import { Helmet } from "react-helmet"
import { FacebookShareButton, TwitterShareButton, InstapaperShareButton } from "react-share"

const secKey = process.env.REACT_APP_STRAPI_KEY


const BlogDetails = () => {
    const [blogData, setBlogData] = useState({})
    const [randomBlog, setRandomBlog] = useState({})
    const [loader, setLoader] = useState(false)

    const { blogId } = useParams()

    const loadData = async() => {
        try {
            setLoader(true)

            let { data, status } = await fetchBlogDetail(secKey, blogId)
            if(status == "success") setBlogData(data)

            setLoader(false)
        } catch (err) {
            console.log("Error: ", err.message)
        }
    }

    const loadRandomBlog = async() => {
        try {
            let { data, status } = await fetchRandomBlog(secKey)
            if(status == "success") setRandomBlog(data)
        } catch (err) {
            console.log("Error: ", err.message)
        }
    }

    const scrollTop = () => window.scrollTo(0, 0)

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    useEffect(() => {
        if(blogId) loadData()
        loadRandomBlog()
    }, [blogId])

    return(
        <>
            <SideHeaders />

            <main className="w-full min-h-screen">
                <Helmet>
                    <title>{blogData?.attributes?.Seo?.metaTitle}</title>
                    <meta name="description" content={blogData?.attributes?.Seo?.metaDescription} key="description" />
                    <meta name="keywords" content={blogData?.attributes?.Seo?.keywords} />
                    <meta
                        name="twitter:card"
                        content={"https://blog.wizhull.com" + blogData?.attributes?.Image?.data?.attributes?.formats?.large?.url}
                        key="twitter:card"
                    />
                    <meta property="og:url" content={"./blog/" + blogId} key="og:url" />
                    <meta property="og:title" content={blogData?.attributes?.Seo?.metaTitle} key="og:title" />
                    <meta
                        property="og:description"
                        content={blogData?.attributes?.Seo?.metaDescription}
                        key="og:description"
                    />
                    <meta property="og:image" content={"https://blog.wizhull.com/" + blogData?.attributes?.Image?.data?.attributes?.formats?.large?.url} key="og:image" />
                    <link rel="canonical" href={"./blog/" + blogId} />

                    {blogData?.attributes?.Seo?.preventIndexing && (
                        <>
                        <meta name="robots" content="noindex"></meta>
                        <meta name="googlebot" content="noindex"></meta>
                        </>
                    )}
                </Helmet>
                <section className="page-title-wrapper w-full h-[345px]">
                    <div className="theme-container mx-auto h-full">
                        <div className="w-full h-full flex sm:flex-row flex-col sm:justify-between justify-center space-y-3 sm:space-y-0 items-center">
                            <h2 className="headline-default text-primary-900">Blog Details</h2>
                            <ul className="breadcrumb-wrapper flex space-x-5 items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <div className="flex space-x-5 items-center">
                                            <span className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans">
                                                Home
                                            </span>
                                            <span className="ico">
                                                <svg
                                                    width="9"
                                                    height="13"
                                                    viewBox="0 0 9 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                                    fill="#028835"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <div className="flex space-x-5 items-center">
                                        <span
                                        className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                        >Blog Details</span
                                        >
                                        <span className="ico">
                                        <svg
                                            width="9"
                                            height="13"
                                            viewBox="0 0 9 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                            fill="#028835"
                                            />
                                        </svg>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <div className="lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]">
                    <div className="theme-container mx-auto">
                        <div className="lg:grid grid-cols-12 lg:gap-[30px]">
                            <div className="body-content-wrapper lg:col-span-12 col-span-12 mb-[80px] lg:mb-0">
                                <div className="pb-[50px]">
                                    <div>
                                    <p
                                        className="sm:text-[30px] font-semibold spline-sans sm:leading-9 text-2xl text-primary-900 mb-2"
                                    >
                                        {
                                            loader ?
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            : 
                                                blogData?.attributes?.Title
                                        }
                                    </p>
                                    <ul className="flex space-x-5 items-center mb-[30px]">
                                        <li>
                                        <span className="text-base leading-[27px] text-primary-100"
                                            >By Mortgage Agent</span
                                        >
                                        </li>
                                        <li className="flex space-x-5 items-center">
                                            <span>
                                                <svg
                                                width="19"
                                                height="14"
                                                viewBox="0 0 19 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M17.875 7.46875L14.875 13.4688C14.7188 13.8125 14.375 14 13.9688 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0H5.65625C6.1875 0 6.6875 0.21875 7.0625 0.59375L8.59375 2H13C14.0938 2 15 2.90625 15 4V5H13.5V4C13.5 3.75 13.25 3.5 13 3.5H8L6 1.65625C5.90625 1.5625 5.78125 1.5 5.65625 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V11L3.71875 6.5625C3.875 6.21875 4.21875 6 4.59375 6H17C17.7188 6 18.2188 6.78125 17.875 7.46875Z"
                                                    fill="#028835"
                                                />
                                                </svg>
                                            </span>
                                            <span className="text-base leading-[27px] text-primary-100">
                                                <span className="pr-5">Categories: </span>
                                                {
                                                    loader ?
                                                        <Skeleton active paragraph={{ rows: 0 }} />
                                                    :
                                                        blogData?.attributes?.categories?.data?.map((item) => (
                                                            <span className="px-3 font-bold">
                                                                {item.attributes.Title}
                                                            </span>
                                                        ))
                                                }
                                            </span>
                                        </li>
                                        {/* <li className="flex space-x-5 items-center">
                                            <span>
                                                <svg
                                                width="22"
                                                height="16"
                                                viewBox="0 0 22 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M7.5 0C11.0625 0 14 2.46875 14 5.5C14 8.5625 11.0625 11 7.5 11C6.90625 11 6.34375 10.9375 5.78125 10.8125C4.84375 11.4062 3.46875 12 1.75 12C1.4375 12 1.15625 11.8438 1.0625 11.5312C0.9375 11.25 0.96875 10.9375 1.1875 10.6875C1.21875 10.6875 1.90625 9.9375 2.40625 8.9375C1.53125 8 1 6.8125 1 5.5C1 2.46875 3.90625 0 7.5 0ZM6.125 9.34375C6.59375 9.46875 7.03125 9.5 7.5 9.5C10.25 9.5 12.5 7.71875 12.5 5.5C12.5 3.3125 10.25 1.5 7.5 1.5C4.71875 1.5 2.5 3.3125 2.5 5.5C2.5 6.625 3.03125 7.4375 3.5 7.90625L4.25 8.6875L3.75 9.65625C3.625 9.84375 3.5 10.0625 3.375 10.2812C3.9375 10.125 4.46875 9.875 5 9.53125L5.53125 9.21875L6.125 9.34375ZM14.7812 4.03125C18.25 4.15625 21 6.5625 21 9.5C21 10.8125 20.4375 12 19.5625 12.9375C20.0625 13.9375 20.75 14.6875 20.7812 14.6875C21 14.9375 21.0312 15.25 20.9062 15.5312C20.8125 15.8438 20.5312 16 20.2188 16C18.5 16 17.125 15.4062 16.1875 14.8125C15.625 14.9375 15.0625 15 14.5 15C11.9375 15 9.71875 13.75 8.65625 11.9375C9.1875 11.875 9.71875 11.75 10.1875 11.5625C11.0625 12.75 12.6562 13.5 14.5 13.5C14.9375 13.5 15.375 13.4688 15.8438 13.3438L16.4375 13.2188L16.9688 13.5312C17.5 13.875 18.0312 14.125 18.5938 14.2812C18.4688 14.0625 18.3438 13.8438 18.2188 13.6562L17.7188 12.6875L18.4688 11.9062C18.9375 11.4375 19.5 10.625 19.5 9.5C19.5 7.4375 17.5 5.75 14.9688 5.53125L15 5.5C15 5 14.9062 4.5 14.7812 4.03125Z"
                                                    fill="#028835"
                                                />
                                                </svg>
                                            </span>
                                            <span className="text-base leading-[27px] text-primary-100"
                                                >Comment</span
                                            >
                                        </li> */}
                                    </ul>
                                    </div>
                                    <div className="w-full h-[438px] mb-[30px]">
                                        {
                                            loader ?
                                                <Skeleton active paragraph={{ rows: 10 }} />
                                            :
                                                <img
                                                    src={"https://blog.wizhull.com/" + blogData?.attributes?.Image?.data?.attributes?.formats?.large?.url}
                                                    alt=""
                                                    className="w-full h-full"
                                                    style={{ objectFit: "cover" }}
                                                />
                                        }
                                    </div>
                                    {
                                        blogData?.attributes?.Description?.map((item) => (
                                            <div className={item.type == "paragraph" ? "blogParagraph" : "blogHeader"}>
                                                {item.children.map((child) => (
                                                    <span className={child?.bold && "font-bold"}>
                                                        {child.text}
                                                    </span>
                                                ))}
                                            </div>
                                        ))
                                    }
                                    <div className="flex space-x-2.5 items-center pt-5">
                                        <span
                                            className="text-lg spline-sans font-bold leading-7 text-primary-900"
                                            >Share:
                                        </span>
                                        <FacebookShareButton url={`https://wizhull.com/blog/${blogId}`}>
                                            <div className="w-[50px] h-[50px] rounded flex justify-center items-center text-primary-900 bg-primary-50 hover:text-white hover:bg-primary-900 common-trans">
                                                <span>
                                                    <svg
                                                        width="10"
                                                        height="16"
                                                        viewBox="0 0 10 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="fill-current"
                                                    >
                                                        <path d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </FacebookShareButton>
                                        <TwitterShareButton url={`https://wizhull.com/blog/${blogId}`}>
                                            <div className="w-[50px] h-[50px] rounded flex justify-center items-center text-primary-900 bg-primary-50 hover:text-white hover:bg-primary-900 common-trans">
                                                <span>
                                                    <svg
                                                        width="16"
                                                        height="14"
                                                        viewBox="0 0 16 14"
                                                        fill="none"
                                                        className="fill-current"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.3438 3.75C14.3438 3.90625 14.3438 4.03125 14.3438 4.1875C14.3438 8.53125 11.0625 13.5 5.03125 13.5C3.15625 13.5 1.4375 12.9688 0 12.0312C0.25 12.0625 0.5 12.0938 0.78125 12.0938C2.3125 12.0938 3.71875 11.5625 4.84375 10.6875C3.40625 10.6562 2.1875 9.71875 1.78125 8.40625C2 8.4375 2.1875 8.46875 2.40625 8.46875C2.6875 8.46875 3 8.40625 3.25 8.34375C1.75 8.03125 0.625 6.71875 0.625 5.125V5.09375C1.0625 5.34375 1.59375 5.46875 2.125 5.5C1.21875 4.90625 0.65625 3.90625 0.65625 2.78125C0.65625 2.15625 0.8125 1.59375 1.09375 1.125C2.71875 3.09375 5.15625 4.40625 7.875 4.5625C7.8125 4.3125 7.78125 4.0625 7.78125 3.8125C7.78125 2 9.25 0.53125 11.0625 0.53125C12 0.53125 12.8438 0.90625 13.4688 1.5625C14.1875 1.40625 14.9062 1.125 15.5312 0.75C15.2812 1.53125 14.7812 2.15625 14.0938 2.5625C14.75 2.5 15.4062 2.3125 15.9688 2.0625C15.5312 2.71875 14.9688 3.28125 14.3438 3.75Z"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </TwitterShareButton>
                                        <InstapaperShareButton url={`https://wizhull.com/blog/${blogId}`}>
                                            <div className="w-[50px] h-[50px] rounded flex justify-center items-center text-primary-900 bg-primary-50 hover:text-white hover:bg-primary-900 common-trans">
                                                <span>
                                                    <svg
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 15 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="fill-current"
                                                    >
                                                    <path
                                                        d="M8 3.40625C6 3.40625 4.40625 5.03125 4.40625 7C4.40625 9 6 10.5938 8 10.5938C9.96875 10.5938 11.5938 9 11.5938 7C11.5938 5.03125 9.96875 3.40625 8 3.40625ZM8 9.34375C6.71875 9.34375 5.65625 8.3125 5.65625 7C5.65625 5.71875 6.6875 4.6875 8 4.6875C9.28125 4.6875 10.3125 5.71875 10.3125 7C10.3125 8.3125 9.28125 9.34375 8 9.34375ZM12.5625 3.28125C12.5625 3.75 12.1875 4.125 11.7188 4.125C11.25 4.125 10.875 3.75 10.875 3.28125C10.875 2.8125 11.25 2.4375 11.7188 2.4375C12.1875 2.4375 12.5625 2.8125 12.5625 3.28125ZM14.9375 4.125C14.875 3 14.625 2 13.8125 1.1875C13 0.375 12 0.125 10.875 0.0625C9.71875 0 6.25 0 5.09375 0.0625C3.96875 0.125 3 0.375 2.15625 1.1875C1.34375 2 1.09375 3 1.03125 4.125C0.96875 5.28125 0.96875 8.75 1.03125 9.90625C1.09375 11.0312 1.34375 12 2.15625 12.8438C3 13.6562 3.96875 13.9062 5.09375 13.9688C6.25 14.0312 9.71875 14.0312 10.875 13.9688C12 13.9062 13 13.6562 13.8125 12.8438C14.625 12 14.875 11.0312 14.9375 9.90625C15 8.75 15 5.28125 14.9375 4.125ZM13.4375 11.125C13.2188 11.75 12.7188 12.2188 12.125 12.4688C11.1875 12.8438 9 12.75 8 12.75C6.96875 12.75 4.78125 12.8438 3.875 12.4688C3.25 12.2188 2.78125 11.75 2.53125 11.125C2.15625 10.2188 2.25 8.03125 2.25 7C2.25 6 2.15625 3.8125 2.53125 2.875C2.78125 2.28125 3.25 1.8125 3.875 1.5625C4.78125 1.1875 6.96875 1.28125 8 1.28125C9 1.28125 11.1875 1.1875 12.125 1.5625C12.7188 1.78125 13.1875 2.28125 13.4375 2.875C13.8125 3.8125 13.7188 6 13.7188 7C13.7188 8.03125 13.8125 10.2188 13.4375 11.125Z"
                                                    />
                                                    </svg>
                                                </span>
                                            </div>
                                        </InstapaperShareButton>
                                    </div>
                                </div>
                                {/* <p className="sm:text-[30px] font-semibold spline-sans sm:leading-9 text-2xl text-primary-900 mb-2 pt-5">
                                    Leave a comment
                                </p>
                                <div className="sm:grid grid-cols-2 gap-[30px] mb-[30px]">
                                    <div className="flex flex-col space-y-5 mb-5 sm:mb-0">
                                    <label for="name">
                                        <input
                                        id="name"
                                        type="text"
                                        placeholder="Your Name"
                                        className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                        />
                                    </label>
                                    <label for="email">
                                        <input
                                        id="email"
                                        type="email"
                                        placeholder="E-mail"
                                        className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                        />
                                    </label>
                                    <label for="phone">
                                        <input
                                        id="phone"
                                        type="text"
                                        placeholder="Phone Number"
                                        className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                        />
                                    </label>
                                    </div>
                                    <div>
                                        <textarea
                                            name="message"
                                            id="message"
                                            placeholder="Write your message"
                                            className="w-full h-full rounded border border-primaryBorder focus:outline-none focus:ring-0 p-5 placeholder:text-primary-100 text-black"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="mb-[60px]">
                                    <a href="#">
                                        <div
                                            className="rounded border border-primaryBorder hover:border-transparent hover:bg-primary-500 common-trans group focus:outline-none focus:ring-0 w-full sm:h-[68px] h-[50px] text-black flex justify-center items-center"
                                        >
                                            <span
                                            className="sm:text-lg text-sm font-semibold spline-sans text-gray-800 group-hover:text-white common-trans"
                                            >Submit Now</span
                                            >
                                        </div>
                                    </a>
                                </div> */}
                                <div className="grid sm:grid-cols-2 grid-cols-1 gap-[30px]">
                                    {
                                        randomBlog && randomBlog.length >= 0 && randomBlog.map((item) => (
                                            
                                            <Link to={"/blog/" + item?.attributes.slug} onClick={() => {scrollTop()}}>
                                                <div className="item group">
                                                    <div className="w-full">
                                                        <div className="w-full h-[275px] rounded overflow-hidden relative mb-5">
                                                            <img
                                                                src={"https://blog.wizhull.com/"+item.attributes.Image.data.attributes.formats.medium.url}
                                                                alt=""
                                                                className="w-full h-full"
                                                                style={{objectFit: "cover"}}
                                                            />
                                                            <div className="w-full h-full flex justify-center bg-primary-500 bg-opacity-80 items-center absolute left-0 top-0 opacity-0 group-hover:opacity-100 common-trans">
                                                                <div className="w-[60px] h-[60px] rounded-full bg-white flex justify-center items-center">
                                                                    <span>
                                                                    <svg
                                                                        width="15"
                                                                        height="13"
                                                                        viewBox="0 0 15 13"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                        d="M13.6875 7.71875L8.6875 12.7188C8.5 12.9062 8.25 13 8 13C7.71875 13 7.46875 12.9062 7.28125 12.7188C6.875 12.3438 6.875 11.6875 7.28125 11.3125L10.5625 8H1C0.4375 8 0 7.5625 0 7C0 6.46875 0.4375 6 1 6H10.5625L7.28125 2.71875C6.875 2.34375 6.875 1.6875 7.28125 1.3125C7.65625 0.90625 8.3125 0.90625 8.6875 1.3125L13.6875 6.3125C14.0938 6.6875 14.0938 7.34375 13.6875 7.71875Z"
                                                                        fill="#028835"
                                                                        ></path>
                                                                    </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="flex space-x-5 items-center mb-5" style={{ overflow: "overlay" }}>
                                                            <li>
                                                                <span className="text-base leading-[27px] text-primary-100" style={{ display: "block", width: "145px" }}>
                                                                    By Mortgage Agent
                                                                </span>
                                                            </li>
                                                            <li className="flex space-x-5 items-center">
                                                                <div className="h-2.5 rounded-full bg-primary-500"></div>
                                                                {
                                                                    item?.attributes?.categories?.data?.map((item) => (
                                                                        <span className="text-base text-primary-100">
                                                                            {item.attributes.Title}
                                                                        </span>
                                                                    ))
                                                                }
                                                            </li>
                                                        </ul>
                                                        <h2
                                                            className="xl:text-lg xl:leading-7 text-md font-bold spline-sans text-primary-900 mb-5"
                                                        >
                                                            { item?.attributes?.Title }
                                                        </h2>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <SideFooter />
        </>
    )
}

export default BlogDetails