import React, { useState, useEffect } from "react"
import Carousel from "react-multi-carousel"
import CountUp from "react-countup"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

// images
import aboutThumb from "../assets/img/about-thumb.png"
import counter1 from "../assets/img/counter-1.png"
import counter2 from "../assets/img/counter-2.png"
import counter3 from "../assets/img/counter-3.png"
import counter4 from "../assets/img/counter-4.png"
import funFactThumb from "../assets/img/fun-fact-thum.png"
import testimonial1 from "../assets/img/testimonial-1.png"
import testimonial4 from "../assets/img/testimonial-4.png"
import testimonial5 from "../assets/img/testimonial-5.png"
import testimonial6 from "../assets/img/testimonial-6.png"

// svg
import progress1 from "../assets/img/progress-1.svg"
import progress2 from "../assets/img/progress-2.svg"
import progress3 from "../assets/img/progress-3.svg"
import progress4 from "../assets/img/progress-4.svg"
import Footer from "../Component/Footers/Footer"
import SideHeaders from "../Component/Headers/SideHeaders"

import SideFooter from "../Component/Footers/SideFooter"


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
}

const About = () => {
    const [accordian, setAccordian] = useState()

    const handleClick = (num) => {
        if(accordian == num) setAccordian(0)
        else setAccordian(num)
    }

    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            <SideHeaders />

            <main className="w-full min-h-screen">
                <section className="page-title-wrapper w-full h-[345px]">
                    <div className="theme-container mx-auto h-full">
                        <div className="w-full h-full flex sm:flex-row flex-col sm:justify-between justify-center space-y-3 sm:space-y-0 items-center">
                            <h2 className="headline-default text-primary-900">
                                About
                            </h2>
                            <ul className="breadcrumb-wrapper flex space-x-5 items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <div className="flex space-x-5 items-center">
                                            <span className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans">
                                                Home
                                            </span>
                                            <span className="ico">
                                                <svg
                                                    width="9"
                                                    height="13"
                                                    viewBox="0 0 9 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                                    fill="#028835"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <div className="flex space-x-5 items-center">
                                            <span className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans">
                                                About
                                            </span>
                                            <span className="ico">
                                                <svg
                                                    width="9"
                                                    height="13"
                                                    viewBox="0 0 9 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                                    fill="#028835"
                                                />
                                                </svg>
                                            </span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div className="about-us-page-wrapper lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]">
                    <section className="about-area w-full lg:pb-[120px] pb-[60px]">
                    <div className="theme-container mx-auto">
                        <div className="w-full grid xl:grid-cols-2 grid-cols-1 lg:gap-[66px] gap-[30px] items-center relative">
                        <div className="">
                            <div className="thumbnail-area 2xl:w-[721px] w-full 2xl:absolute 2xl:-left-[150px] -top-8 relative">
                                <img
                                    src={aboutThumb}
                                    alt="about"
                                    className="relative z-10 w-full h-full"
                                />
                            <div className="about-shape gear-2 xl:w-[385px] xl:h-[385px] md:w-[500px] md:h-[500px] w-[250px] h-[250px]"></div>
                            {/* <div className="w-[250px] h-[94px] rounded-[16px] bg-primary-500 flex justify-center items-center absolute sm:top-[432px] top-[200px] sm:right-[156px] right-5 z-10">
                                <div>
                                    <div className="flex space-x-6 items-center">
                                        <span>
                                            <svg
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="20" cy="20" r="20" fill="white" />
                                                <path
                                                    d="M26.332 25.7943C25.544 25.5133 24.258 25.4943 23.688 25.2813C23.2492 25.1672 22.8347 24.974 22.465 24.7113C22.3097 24.0566 22.2424 23.3839 22.265 22.7113C22.558 22.4302 22.7955 22.0964 22.965 21.7273C23.1351 21.0941 23.2479 20.4468 23.302 19.7933C23.302 19.7933 23.766 19.9933 23.949 19.0623C24.104 18.2623 24.399 17.8393 24.322 17.2483C24.245 16.6573 23.914 16.7983 23.914 16.7983C24.273 15.9711 24.4135 15.0655 24.322 14.1683C24.3166 13.591 24.1924 13.0209 23.9571 12.4935C23.7218 11.9662 23.3806 11.493 22.9545 11.1032C22.5285 10.7135 22.0268 10.4156 21.4806 10.2281C20.9345 10.0406 20.3556 9.96749 19.78 10.0133C19.2044 9.96746 18.6254 10.0402 18.0789 10.227C17.5324 10.4138 17.0301 10.7107 16.603 11.0994C16.1758 11.4881 15.833 11.9603 15.5956 12.4868C15.3583 13.0133 15.2315 13.5829 15.223 14.1603C15.1325 15.0567 15.2701 15.9614 15.623 16.7903C15.623 16.7903 15.293 16.6493 15.215 17.2403C15.137 17.8313 15.427 18.2603 15.586 19.0603C15.769 19.9953 16.233 19.7913 16.233 19.7913C16.2878 20.4447 16.4005 21.092 16.57 21.7253C16.7396 22.0944 16.9771 22.4282 17.27 22.7093C17.2927 23.3819 17.2254 24.0546 17.07 24.7093C16.7014 24.9723 16.2865 25.1633 15.847 25.2723C15.285 25.4903 13.998 25.5183 13.203 25.7993C12.2445 26.1586 11.4205 26.8055 10.844 27.6514C10.2676 28.4973 9.96683 29.5008 9.98304 30.5243H29.545C29.5643 29.5005 29.2656 28.4958 28.6902 27.6487C28.1148 26.8017 27.2909 26.1538 26.332 25.7943Z"
                                                    fill="#028835"
                                                />
                                                <path
                                                    d="M11.63 22.3684C12.4561 22.3573 13.2651 22.132 13.978 21.7144C12.888 20.0624 13.478 18.1354 13.254 16.3354C13.243 15.9259 13.1472 15.5231 12.9728 15.1524C12.7983 14.7817 12.549 14.4511 12.2405 14.1816C11.9319 13.912 11.5709 13.7093 11.1801 13.5862C10.7894 13.4631 10.3773 13.4224 9.97 13.4664H9.942C9.53708 13.428 9.12858 13.4729 8.74171 13.5985C8.35485 13.7241 7.99783 13.9276 7.69271 14.1966C7.38758 14.4655 7.14083 14.7942 6.96768 15.1622C6.79453 15.5303 6.69866 15.9299 6.686 16.3364C6.461 18.1294 7.086 20.2744 5.969 21.7154C6.68409 22.1261 7.49988 22.3284 8.324 22.2994C8.36668 22.6942 8.34302 23.0934 8.254 23.4804C8.01199 23.682 7.72069 23.8156 7.41 23.8674C6.79373 24.0008 6.18571 24.1697 5.589 24.3734C5.1109 24.6233 4.71018 24.999 4.43013 25.4601C4.15007 25.9212 4.00134 26.45 4 26.9894H9.647C10.1603 26.0724 10.9468 25.3383 11.897 24.8894C12.4477 24.6587 13.0371 24.5345 13.634 24.5234C13.634 24.5234 13.901 24.1014 13.022 23.9394C12.5548 23.8369 12.1016 23.6787 11.672 23.4684C11.538 23.3184 11.63 22.3684 11.63 22.3684Z"
                                                    fill="#028835"
                                                />
                                                <path
                                                    d="M27.9417 22.3684C27.1157 22.3573 26.3066 22.132 25.5938 21.7144C26.6838 20.0624 26.0937 18.1354 26.3177 16.3354C26.3288 15.9259 26.4245 15.5231 26.599 15.1524C26.7734 14.7817 27.0227 14.4511 27.3313 14.1816C27.6398 13.912 28.0009 13.7093 28.3916 13.5862C28.7824 13.4631 29.1944 13.4224 29.6017 13.4664H29.6297C30.0347 13.428 30.4432 13.4729 30.83 13.5985C31.2169 13.7241 31.5739 13.9276 31.879 14.1966C32.1842 14.4655 32.4309 14.7942 32.6041 15.1622C32.7772 15.5303 32.8731 15.9299 32.8857 16.3364C33.1107 18.1294 32.4857 20.2744 33.6027 21.7154C32.8877 22.1261 32.0719 22.3284 31.2477 22.2994C31.2051 22.6942 31.2287 23.0934 31.3177 23.4804C31.5598 23.682 31.8511 23.8156 32.1617 23.8674C32.778 24.0008 33.386 24.1697 33.9827 24.3734C34.4608 24.6233 34.8616 24.999 35.1416 25.4601C35.4217 25.9212 35.5704 26.45 35.5717 26.9894H29.9247C29.4115 26.0724 28.6249 25.3383 27.6747 24.8894C27.1241 24.6587 26.5347 24.5345 25.9377 24.5234C25.9377 24.5234 25.6707 24.1014 26.5497 23.9394C27.0169 23.8369 27.4702 23.6787 27.8997 23.4684C28.0337 23.3184 27.9417 22.3684 27.9417 22.3684Z"
                                                    fill="#028835"
                                                />
                                            </svg>
                                        </span>
                                        <div>
                                        <p className="text-white text-xl font-bold leading-[26px]">
                                            <CountUp start={0} end={5000} delay={0} enableScrollSpy={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                    <span ref={countUpRef} />+
                                                    </div>
                                                )}
                                            </CountUp>
                                        </p>
                                        <p className="text-base leading-5 text-white">
                                            Satisfied Client
                                        </p> 
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="about-article-area">
                            <h2 className="headline-default text-primary-900 mb-10">
                            Mortgage  that fits your <br className="lg:block hidden" />
                            needs
                            </h2>
                            <div
                            className="xl:w-full max-w-4xl sm:p-[30px] p-5 border border-primaryBorder rounded-[10px] flex flex-col space-y-5 mb-5"
                            >
                            <div className="item sm:flex sm:space-x-5 sm:items-center">
                                <div
                                    className="w-[60px] h-[60px] bg-primary-50 sm:flex hidden justify-center items-center rounded"
                                >
                                <span>
                                    <svg
                                    width="18"
                                    height="13"
                                    viewBox="0 0 18 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M17.3594 0.640625C17.8672 1.10938 17.8672 1.92969 17.3594 2.39844L7.35938 12.3984C6.89062 12.9062 6.07031 12.9062 5.60156 12.3984L0.601562 7.39844C0.09375 6.92969 0.09375 6.10938 0.601562 5.64062C1.07031 5.13281 1.89062 5.13281 2.35938 5.64062L6.5 9.74219L15.6016 0.640625C16.0703 0.132812 16.8906 0.132812 17.3594 0.640625Z"
                                        fill="#028835"
                                    />
                                    </svg>
                                </span>
                                </div>
                                <div className="flex-1">
                                <div className="flex justify-between items-center mb-2.5">
                                    <span
                                    className="text-lg text-gray-900 font-bold spline-sans"
                                    >Competitive Rates: </span
                                    >
                                    <span
                                    className="text-lg text-gray-900 font-bold spline-sans"
                                    >95%</span
                                    >
                                </div>
                                <div
                                    className="w-full relative overflow-hidden h-[5px] rounded-[10px] bg-gray-100"
                                >
                                    <div
                                    style={{width: "95%"}}
                                    className="absolute left-0 top-0 bg-primary-500 h-full rounded-[10px]"
                                    ></div>
                                </div>
                                </div>
                            </div>
                            <div className="item sm:flex sm:space-x-5 sm:items-center">
                                <div
                                className="w-[60px] h-[60px] bg-primary-50 sm:flex hidden justify-center items-center rounded"
                                >
                                <span>
                                    <svg
                                    width="18"
                                    height="13"
                                    viewBox="0 0 18 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M17.3594 0.640625C17.8672 1.10938 17.8672 1.92969 17.3594 2.39844L7.35938 12.3984C6.89062 12.9062 6.07031 12.9062 5.60156 12.3984L0.601562 7.39844C0.09375 6.92969 0.09375 6.10938 0.601562 5.64062C1.07031 5.13281 1.89062 5.13281 2.35938 5.64062L6.5 9.74219L15.6016 0.640625C16.0703 0.132812 16.8906 0.132812 17.3594 0.640625Z"
                                        fill="#028835"
                                    />
                                    </svg>
                                </span>
                                </div>
                                <div className="flex-1">
                                <div className="flex justify-between items-center mb-2.5">
                                    <span
                                    className="text-lg text-gray-900 font-bold spline-sans"
                                    >Fast & Easy Approval</span
                                    >
                                    <span
                                    className="text-lg text-gray-900 font-bold spline-sans"
                                    >90%</span
                                    >
                                </div>
                                <div
                                    className="w-full relative overflow-hidden h-[5px] rounded-[10px] bg-gray-100"
                                >
                                    <div
                                    style={{width: "90%"}}
                                    className="absolute left-0 top-0 bg-primary-500 h-full rounded-[10px]"
                                    ></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <p className="xl:w-full max-w-4xl text-base text-primary-100 mb-14">
                            We're not just a mortgage company. we're your partners in making homeownership a reality. Whether you're a first-time buyer or looking to refinance.Ready to take the first step towards your new home? Contact us today for a consultation, and let's make your homeownership dreams a reality! 
                    
                            </p>
                            <div>
                                <Link to="/contact">
                                    <div
                                    className="px-[42px] py-[23px] bg-primary-500 hover:bg-primary-900 common-trans rounded-lg inline"
                                    >
                                    <span className="text-lg text-white">Consult us</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

                    <section className="fun-fact-area-two relative">
                        <div className="w-full lg:py-[150px] py-[60px] sm:h-[748px] h-full">
                            <div className="theme-container mx-auto relative">
                                <div
                                    className="grid sm:grid-cols-3 grid-cols-1 sm:gap-[30px] gap-[60px]"
                                >
                                    <div className="counter-item bg-white rounded flex justify-center items-center h-[150px] relative">
                                        <div>
                                            <h2
                                            className="font-bold spline-sans lg:text-[44px] lg:leading-[55px] text-2xl text-primary-900 text-center mb-1.5"
                                            >
                                            <CountUp start={0} end={20} delay={0} enableScrollSpy={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                    <span ref={countUpRef} />+
                                                    </div>
                                                )}
                                            </CountUp>
                                            </h2>
                                            <p className="text-base text-primary-100">Succesful Deals</p>
                                        </div>
                                        <div
                                            style={{left: "calc(50% - 32px)", bottom: "calc(100% - 32px)"}}
                                            className="w-[64px] h-[64px] rounded-full bg-primary-500 flex justify-center items-center absolute"
                                        >
                                            <img src={counter2} alt="ico" />
                                        </div>
                                    </div>
                                    {/* <div
                                    className="counter-item bg-white rounded flex justify-center items-center h-[150px] relative"
                                    >
                                    <div>
                                        <h2 className="font-bold spline-sans lg:text-[44px] lg:leading-[55px] text-2xl text-primary-900 text-center mb-1.5">
                                            <CountUp start={0} end={5} delay={0} enableScrollSpy={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                    <span ref={countUpRef} />+
                                                    </div>
                                                )}
                                            </CountUp>
                                        </h2>
                                        <p className="text-base text-primary-100">
                                            
                                        </p>
                                    </div>
                                    <div
                                        style={{left: "calc(50% - 32px)", bottom: "calc(100% - 32px)"}}
                                        className="w-[64px] h-[64px] rounded-full bg-primary-500 flex justify-center items-center absolute"
                                    >
                                        <img src={counter3} alt="ico" />
                                    </div>
                                    </div> */}
                                    <div className="counter-item bg-white rounded flex justify-center items-center h-[150px] relative">
                                        <div>
                                            <h2 className="font-bold spline-sans lg:text-[44px] lg:leading-[55px] text-2xl text-primary-900 text-center mb-1.5">
                                                <CountUp start={0} end={10} delay={0} enableScrollSpy={true}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                        <span ref={countUpRef} />+
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </h2>
                                            <p className="text-base text-primary-100">Happy Clients</p>
                                        </div>
                                        <div
                                            style={{left: "calc(50% - 32px)", bottom: "calc(100% - 32px)"}}
                                            className="w-[64px] h-[64px] rounded-full bg-primary-500 flex justify-center items-center absolute"
                                        >
                                            <img src={counter4} alt="ico" />
                                        </div>
                                    </div>
                                    <div className="counter-item bg-white rounded flex justify-center items-center h-[150px] relative">
                                        <div>
                                            <h2 className="font-bold spline-sans lg:text-[44px] lg:leading-[55px] text-2xl text-primary-900 text-center mb-1.5">
                                                <CountUp start={0} end={30} delay={0} enableScrollSpy={true}>
                                                    {({ countUpRef }) => (
                                                        <div>
                                                        <span ref={countUpRef} />+
                                                        </div>
                                                    )}
                                                </CountUp>
                                            </h2>
                                            <p className="text-base text-primary-100">Team member</p>
                                        </div>
                                    <div
                                        style={{left: "calc(50% - 32px)", bottom: "calc(100% - 32px)"}}
                                        className="w-[64px] h-[64px] rounded-full bg-primary-500 flex justify-center items-center absolute"
                                    >
                                        <img src={counter1} alt="ico" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section
                    className="faq-section-main-wrapper w-full lg:-mt-[327px] sm:-mt-[466px] my-[60px] relative z-10"
                    >
                    <div className="theme-container mx-auto">
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="faq-two xl:w-[5000px] w-full bg-white sm:p-[60px] p-0 rounded-[10px] flex xl:flex-row flex-col xl:space-x-[75px] space-y-[75px] xl:space-y-0 xl:items-center"
                        >

                        <div
                            className="sm:w-[525px] w-full xl:h-[741px] h-[500px] relative rounded-[10px] overflow-hidden"
                        >
                            <img
                            src={funFactThumb}
                            alt="thumb"
                            className="w-full h-full object-cover"
                            />
                            <div
                                className="w-[268px] h-[87px] rounded-full p-3 bg-white absolute right-5 bottom-5"
                                style={{ boxShadow: "0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06)" }}
                            >
                            <div className="flex space-x-[15px] items-center">
                                <div
                                className="w-[63px] h-[63px] rounded-full bg-secondary flex justify-center items-center"
                                >
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M33.4049 19.0345C33.6025 27.8649 25.8057 35.6541 16.9828 35.4717C8.388 35.5173 0.993959 28.5033 0.560759 19.9161C-0.123241 10.7588 7.8028 2.39958 16.9752 2.59718C17.188 2.59718 17.3552 2.77198 17.3552 2.97718V18.6545H33.0325C33.2377 18.6545 33.4049 18.8217 33.4049 19.0345Z"
                                    fill="#028835"
                                    />
                                    <path
                                    d="M35.4816 16.9646C35.4816 17.1698 35.3144 17.337 35.1016 17.337H19.0443C18.8391 17.337 18.6719 17.1698 18.6719 16.9646V0.907344C18.6719 0.694544 18.8391 0.527344 19.0443 0.527344C28.1103 0.527344 35.4816 7.89858 35.4816 16.9646Z"
                                    fill="#004C3F"
                                    />
                                </svg>
                                </div>
                                <div>
                                    <span className="text-base font-bold text-gray-700 block">
                                        Explore Mortgages
                                    </span>
                                    <span className="text-base text-gray-700 block">
                                        Visualize Options
                                    </span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div>
                            <p
                            className="text-primary-500 font-bold sm:leading-8 spline-sans sm:text-xl text-md uppercase mb-2"
                            >
                            ASk Anything
                            </p>
                            <h2 className="headline-default text-primary-900 mb-[60px]">
                                Mortgage that goes the <br className="sm:block hidden" />
                                extra mile
                            </h2>
                            
                            <div className="xl:w-[570px] w-full flex flex-col space-y-5">
                                <div className="w-full flex flex-col space-y-5">
                                    <div onClick={() => {handleClick(1)}} className="faq-item active w-full overflow-hidden rounded border border-primaryBorder">
                                        <button
                                            type="button"
                                            className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                        >
                                        <span
                                            className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                            >What is the first step in getting a mortgage?</span
                                        >
                                        <span className="group-hover:text-white">
                                            <svg
                                            width="14"
                                            height="9"
                                            viewBox="0 0 14 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="fill-current"
                                            >
                                            <path
                                                d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                            />
                                            </svg>
                                        </span>
                                        </button>
                                        <div className={`faq-body w-full bg-secondary ${accordian == 1 ? "active" : ""}`}>
                                        <p
                                            className="sm:text-base text-sm leading-7 text-primary-100"
                                        >
                                            The first step in getting a mortgage is to assess your financial situation. 
                                            Calculate your budget, gather financial documents, and check your credit score.
                                        </p>
                                        </div>
                                    </div>
                                    <div onClick={() => {handleClick(2)}} className="faq-item w-full overflow-hidden rounded border border-primaryBorder">
                                        <button
                                        type="button"
                                        className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                        >
                                        <span
                                            className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                            >How can I improve my chances of mortgage approval?</span
                                        >
                                        <span className="group-hover:text-white">
                                            <svg
                                            width="14"
                                            height="9"
                                            viewBox="0 0 14 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="fill-current"
                                            >
                                            <path
                                                d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                            />
                                            </svg>
                                        </span>
                                        </button>
                                        <div className={`faq-body w-full bg-secondary ${accordian == 2 ? "active" : ""}`}>
                                        <p
                                            className="sm:text-base text-sm leading-7 text-primary-100"
                                        >
                                        To improve your chances of mortgage approval, maintain a good credit score, 
                                        save for a down payment, and reduce existing debt. It's also crucial to choose
                                        a reputable lender.
                                        </p>
                                        </div>
                                    </div>
                                    <div onClick={() => {handleClick(3)}} className="faq-item w-full overflow-hidden rounded border border-primaryBorder">
                                        <button
                                        type="button"
                                        className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                        >
                                        <span
                                            className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                            >What types of mortgages are available?</span
                                        >
                                        <span className="group-hover:text-white">
                                            <svg
                                            width="14"
                                            height="9"
                                            viewBox="0 0 14 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="fill-current"
                                            >
                                            <path
                                                d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                            />
                                            </svg>
                                        </span>
                                        </button>
                                        <div className={`faq-body w-full bg-secondary ${accordian == 3 ? "active" : ""}`}>
                                        <p
                                            className="sm:text-base text-sm leading-7 text-primary-100"
                                        >
                                            There are various types of mortgages, including fixed-rate and adjustable-rate. 
                                            Each has its own terms and benefits, so it's essential to explore 
                                            your options and choose the one that best fits your needs.
                                        </p>
                                        </div>
                                    </div>
                                    <div onClick={() => {handleClick(4)}} className="faq-item w-full overflow-hidden rounded border border-primaryBorder">
                                        <button
                                        type="button"
                                        className="faq-btn p-5 flex justify-between group hover:bg-primary-500 items-center w-full common-trans sm:text-center text-start"
                                        >
                                        <span
                                            className="sm:text-lg text-sm spline-sans font-semibold group-hover:text-white"
                                            >What documents do I need to apply for a mortgage?</span
                                        >
                                        <span className="group-hover:text-white">
                                            <svg
                                            width="14"
                                            height="9"
                                            viewBox="0 0 14 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="fill-current"
                                            >
                                            <path
                                                d="M7.6875 7.6875C7.3125 8.09375 6.65625 8.09375 6.28125 7.6875L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L7 5.5625L11.2812 1.28125C11.6562 0.875 12.3125 0.875 12.6875 1.28125C13.0938 1.65625 13.0938 2.3125 12.6875 2.6875L7.6875 7.6875Z"
                                            />
                                            </svg>
                                        </span>
                                        </button>
                                        <div className={`faq-body w-full bg-secondary ${accordian == 4 ? "active" : ""}`}>
                                        <p className="sm:text-base text-sm leading-7 text-primary-100">
                                            When applying for a mortgage, you'll typically need documents like salary certificates, 
                                            payslips, proof of identification, and bank statements. Your lender will 
                                            provide a specific list of required documents during the application process.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

                    {/* <section className="testimonial-section-two w-full lg:py-[120px] py-[60px]">
                        <div className="theme-container mx-auto">
                            <div className="w-full relative">
                            <h2
                                className="headline-default text-primary-900 mb-[60px] text-center"
                            >
                                What our awesome <br />
                                customers say
                            </h2>
                            <div className="test-two-swiper-wrapper w-full overflow-hidden">
                                <Carousel draggable={true} infinite={true} autoPlay={true} centerMode={true} responsive={responsive} itemclassName="p-4">
                                    <div className="swiper-slide px-5">
                                        <div
                                            className="testimonial-item sm:p-10 p-5 border border-primaryBorder rounded"
                                        >
                                        <p
                                            className="quote text-base leading-7 text-center mb-[30px]"
                                        >
                                            We were guided through the mortgage process with ease & secured a great rate, thanks to WizHull's clear explanations.
                                        </p>
                                        <div className="flex justify-center mb-5">
                                            <div className="flex space-x-2 items-center">
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mb-5">
                                            <img src={testimonial1} width={60} alt="" />
                                        </div>
                                        <h2
                                            className="quote-user text-lg leading-7 font-bold spline-sans text-center mb-1.5"
                                        >
                                            Tom Henderson
                                        </h2>
                                        <p className="quote-user-desig text-base text-center">
                                            Smooth Mortgage Process
                                        </p>
                                        </div>
                                    </div>
                                    <div className="swiper-slide px-5">
                                        <div
                                        className="testimonial-item sm:p-10 p-5 border border-primaryBorder rounded"
                                        >
                                        <p
                                            className="quote text-base leading-7 text-center mb-[30px]"
                                        >
                                            WizHull streamlined my refinancing, offering expert advice that significantly reduced my monthly payments.
                                        </p>
                                        <div className="flex justify-center mb-5">
                                            <div className="flex space-x-2 items-center">
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mb-5">
                                            <img src={testimonial5} width={60} alt="" />
                                        </div>
                                        <h2
                                            className="quote-user text-lg leading-7 font-bold spline-sans text-center mb-1.5"
                                        >
                                            Michael Liu
                                        </h2>
                                        <p className="quote-user-desig text-base text-center">
                                            Refinancing Expertise
                                        </p>
                                        </div>
                                    </div>
                                    <div className="swiper-slide px-5">
                                        <div
                                        className="testimonial-item sm:p-10 p-5 border border-primaryBorder rounded"
                                        >
                                        <p
                                            className="quote text-base leading-7 text-center mb-[30px]"
                                        >
                                            As a real estate investor, I've relied on WizHull for tailored financing solutions that have been crucial to my success.
                                        </p>
                                        <div className="flex justify-center mb-5">
                                            <div className="flex space-x-2 items-center">
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mb-5">
                                            <img src={testimonial6} width={60} alt="" />
                                        </div>
                                        <h2
                                            className="quote-user text-lg leading-7 font-bold spline-sans text-center mb-1.5"
                                        >
                                            Jack Cooper
                                        </h2>
                                        <p className="quote-user-desig text-base text-center">
                                            Tailored Financing Solutions
                                        </p>
                                        </div>
                                    </div>
                                    <div className="swiper-slide px-5">
                                        <div
                                        className="testimonial-item sm:p-10 p-5 border border-primaryBorder rounded"
                                        >
                                        <p
                                            className="quote text-base leading-7 text-center mb-[30px]"
                                        >
                                            Despite being self-employed, WizHull secured us a mortgage that fit our unique needs, making our dream home a reality.
                                        </p>
                                        <div className="flex justify-center mb-5">
                                            <div className="flex space-x-2 items-center">
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            <span>
                                                <svg
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M11.9062 4.71875L16.375 5.375C16.75 5.4375 17.0625 5.6875 17.1875 6.0625C17.3125 6.40625 17.2188 6.8125 16.9375 7.0625L13.6875 10.2812L14.4688 14.8438C14.5312 15.2188 14.375 15.5938 14.0625 15.8125C13.75 16.0625 13.3438 16.0625 13 15.9062L9 13.75L4.96875 15.9062C4.65625 16.0625 4.21875 16.0625 3.9375 15.8125C3.625 15.5938 3.46875 15.2188 3.53125 14.8438L4.28125 10.2812L1.03125 7.0625C0.75 6.8125 0.65625 6.40625 0.78125 6.0625C0.90625 5.6875 1.21875 5.4375 1.59375 5.375L6.09375 4.71875L8.09375 0.5625C8.25 0.21875 8.59375 0 9 0C9.375 0 9.71875 0.21875 9.875 0.5625L11.9062 4.71875Z"
                                                    fill="#FFBA08"
                                                />
                                                </svg>
                                            </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-center mb-5">
                                            <img src={testimonial4} width={60} alt="" />
                                        </div>
                                        <h2
                                            className="quote-user text-lg leading-7 font-bold spline-sans text-center mb-1.5"
                                        >
                                            David Kim
                                        </h2>
                                        <p className="quote-user-desig text-base text-center">
                                            Specialized Mortgage Support
                                        </p>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                            </div>
                        </div>
                    </section> */}

                    <section
                    className="process-section-two w-full lg:pt-[60px] lg:pb-0 pb-[60px]"
                    >
                    <div className="theme-container mx-auto">
                        <div className="w-full relative">
                        <h2
                            className="headline-default text-primary-900 mb-[60px] text-center"
                        >
                            Protection that's <br />
                            personalized for you
                        </h2>
                        <div
                            className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[30px]"
                        >
                            <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                            className="item group px-[30px] py-10 rounded border border-primaryBorder relative overflow-hidden"
                            >
                            <div
                                className="icon-thumb flex justify-center mt-2.5 mb-[90px] relative z-10"
                            >
                                <img
                                src={progress1}
                                alt=""
                                className="w-[62px] h-[62px]"
                                />
                            </div>
                            <p
                                className="text-lg font-semibold spline-sans text-center text-[#E3E3E3] mb-5"
                            >
                                01
                            </p>
                            <p
                                className="text-lg font-bold spline-sans text-center text-primary-900 mb-5"
                            >
                                Flexible Application Process
                            </p>
                            <p className="text-primary-100 text-base text-center">
                                Our streamlined online application makes getting a mortgage easier.
                            </p>
                            <div
                                style={{left: "calc(50% - 185px)"}}
                                className="circle-shape group-hover:w-full group-hover:h-full w-[372px] h-[372px] rounded-full absolute bottom-[237px]"
                            ></div>
                            </div>
                            <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="200"
                            className="item group px-[30px] py-10 rounded border border-primaryBorder relative overflow-hidden"
                            >
                            <div
                                className="icon-thumb flex justify-center mt-2.5 mb-[90px] relative z-10"
                            >
                                <img
                                src={progress2}
                                alt=""
                                className="w-[62px] h-[62px]"
                                />
                            </div>
                            <p
                                className="text-lg font-semibold spline-sans text-center text-[#E3E3E3] mb-5"
                            >
                                02
                            </p>
                            <p
                                className="text-lg font-bold spline-sans text-center text-primary-900 mb-5"
                            >
                                Transparent Communication
                            </p>
                            <p className="text-primary-100 text-base text-center">
                                We ensure clarity and peace of mind, keeping you informed every step of the way.                            </p>
                            <div
                                style={{left: "calc(50% - 185px)"}}
                                className="circle-shape group-hover:w-full group-hover:h-full w-[372px] h-[372px] rounded-full absolute bottom-[237px]"
                            ></div>
                            </div>
                            <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="300"
                            className="item group px-[30px] py-10 rounded border border-primaryBorder relative overflow-hidden"
                            >
                            <div
                                className="icon-thumb flex justify-center mt-2.5 mb-[90px] relative z-10"
                            >
                                <img
                                src={progress3}
                                alt=""
                                className="w-[62px] h-[62px]"
                                />
                            </div>
                            <p
                                className="text-lg font-semibold spline-sans text-center text-[#E3E3E3] mb-5"
                            >
                                03
                            </p>
                            <p
                                className="text-lg font-bold spline-sans text-center text-primary-900 mb-5"
                            >
                                Customized Financial Solutions
                            </p>
                            <p className="text-primary-100 text-base text-center">
                                Tailored mortgage options to fit your unique needs and financial goals.
                            </p>
                            <div
                                style={{left: "calc(50% - 185px)"}}
                                className="circle-shape group-hover:w-full group-hover:h-full w-[372px] h-[372px] rounded-full absolute bottom-[237px]"
                            ></div>
                            </div>
                            <div
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="400"
                            className="item group px-[30px] py-10 rounded border border-primaryBorder relative overflow-hidden"
                            >
                            <div
                                className="icon-thumb flex justify-center mt-2.5 mb-[90px] relative z-10"
                            >
                                <img
                                src={progress4}
                                alt=""
                                className="w-[62px] h-[62px]"
                                />
                            </div>
                            <p
                                className="text-lg font-semibold spline-sans text-center text-[#E3E3E3] mb-5"
                            >
                                04
                            </p>
                            <p
                                className="text-lg font-bold spline-sans text-center text-primary-900 mb-5"
                            >
                                Dedicated Mortgage Advisors
                            </p>
                            <p className="text-primary-100 text-base text-center">
                                Personalized guidance from dedicated advisors who are committed to your success.
                            </p>
                            <div
                                style={{left: "calc(50% - 185px)"}}
                                className="circle-shape group-hover:w-full group-hover:h-full w-[372px] h-[372px] rounded-full absolute bottom-[237px]"
                            ></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
                </div>
            </main>

            <SideFooter />
            </>
    )
}

export default About