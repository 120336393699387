import React, { useEffect, useState, useRef } from "react"
import { Input, Space, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { checkThread, saveChatbotLead, sendMessage } from "../../Actions/commonController";
import Modal from "antd/es/modal/Modal"
import Avatar from "antd/es/avatar/avatar"
import chatbotIcon from "../../assets/img/chatbotIcon.png"

// context
import { useChatProvider } from "../../Context/ChatProvider"

const ChatBox = () => {
    const { value, setValue } = useChatProvider()

    const [fullMessage, setFullMessage] = useState(false)
    const [messages, setMessages] = useState([])
    const [chatID, setChatID] = useState(null)
    const [loader, setLoader] = useState(false)
    const [animLoader, setAnimLoader] = useState(false)
    const [messageInput, setMessageInput] = useState("")

    const handleClose = () => setValue(false)

    const divRef = useRef(null);

    useEffect(() => {
        let id = localStorage.getItem("chatID")
        setChatID(id)
        loadThread(id)
    }, [])

    const loadThread = async(id) => {
        try {
            let data = { id }

            setLoader(true)
            let { status, cid, content } = await checkThread(data)
            if(status == "success") {
                if(!id) localStorage.setItem("chatID", cid)
                setChatID(cid)
            } else if(status == "delete") {
                localStorage.removeItem("chatID")
            } else {
                let mesgContent = content
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((item) => {
                    let role = item.role === "assistant" ? "bot" : "user"
                    let text = item.content[0].text.value

                    return {
                        type: role,
                        message: text
                    }
                })
                setMessages(mesgContent)
            }
            setLoader(false)
        } catch (err) {
            console.log("some weird error has occured: ", err.message)
        }
    }

    const handleKeyPress = (e) => {
        if(e.key == 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        try {
            if(loader) return
            if(messageInput == "") return
            setLoader(true)
            let data = {
                cid: chatID,
                message: messageInput
            }
            setMessageInput("")

            let currMessages = [...messages, {
                type: "user",
                message: messageInput
            }]

            setTimeout(() => {
                setAnimLoader(true)
            }, 1000);

            setMessages(currMessages)

            setTimeout(() => {
                scrollToBottom()
            }, 1100);

            let { status, content } = await sendMessage(data)

            if(status == "success") {
                let response = content[0].content[0].text.value
                let checkData = response.split('ACTION_SAVE_LEAD')
                if(checkData.length > 1) {
                    let jsonData = checkData[1]
                    let leadData = JSON.parse(jsonData)

                    let data = {
                        name: leadData["Name"],
                        email: leadData["Email"],
                        phone: leadData["Phone Number"],
                        salary: leadData["Salary"],
                        empname: leadData["Employer Name"],
                        dob: leadData["Date of Birth"]
                    }

                    setFullMessage(true)
                    localStorage.removeItem("chatID")
                    setMessages([...currMessages, {
                        type: "bot",
                        message: checkData[0]
                    }])
                    console.log("wow", data, leadData)
                    await saveChatbotLead(data)
                } else {
                    setMessages([...currMessages, {
                        type: "bot",
                        message: response
                    }])
                }
            }

            setAnimLoader(false)
            setLoader(false)
        } catch (err) {
            console.log("an error has occured here: ", err.message)
        }
    }

    const refreshChat = () => {
        localStorage.removeItem("chatID")
        setMessages([])
        setFullMessage(false)

        loadThread(undefined)
    }

    const handleChange = async(e) => setMessageInput(e.target.value)

    const scrollToBottom = () => {
        const element = divRef.current;
        const duration = 500; // Duration of the animation in milliseconds
        const start = element.scrollTop;
        const end = element.scrollHeight - element.clientHeight;
        const startTime = performance.now();
    
        const animateScroll = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const scrollPosition = easeInOut(elapsedTime, start, end - start, duration);
          element.scrollTop = scrollPosition;
    
          if (elapsedTime < duration) {
            requestAnimationFrame(animateScroll);
          }
        };
    
        const easeInOut = (t, b, c, d) => {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        };
    
        requestAnimationFrame(animateScroll);
      };

    useEffect(() => {
        if (divRef.current) {
            scrollToBottom()
        }

        if(messages.length >= 25) setFullMessage(true)
    }, [messages])

    return(
        <>
            <Modal title={
                <>
                    <span className="pr-[18px]"><Avatar size={32} src={chatbotIcon}/>  Mortgage AI</span> 
                    <Button type="primary" style={{backgroundColor:"#008835"}} onClick={refreshChat}>Clear chat</Button>
                </>
                } open={value} onCancel={handleClose} footer={null}>
                <div className="chatBox" ref={divRef}>
                    <div className="messageBox bot">
                        <div className="botText">
                            Welcome to the Mortgage Chatbot! I'm here to help with your mortgage questions and needs. Feel free to ask anything about buying a home, refinancing, or mortgage options.
                        </div>
                    </div>
                    {
                        messages && messages.length > 0 && messages?.map((item) => (
                            <div className={`messageBox ${item.type}`}>
                                <div className={item.type == "bot" ? "botText" : "userText"}>
                                    {
                                        item.message.split("\n").map((it) => (
                                            <div className="py-1">{it}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                    {
                        animLoader && 
                            <div className="messageBox generate">
                                <div className="botText">
                                    <div class="my message">
                                        <span class="jumping-dots">
                                            <span class="dot-1"></span>
                                            <span class="dot-2"></span>
                                            <span class="dot-3"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    !fullMessage ?
                        <Space.Compact style={{ width: '100%' }}>
                            <Input defaultValue="" placeholder="Ask me anything!" name="message" value={messageInput} onChange={handleChange} onKeyDown={handleKeyPress}/>
                            <Button type="primary" style={{backgroundColor:"#008835"}} shape="circle" icon={<SendOutlined />} size={'large'} onClick={handleSubmit} />
                        </Space.Compact>
                    :
                    <Space.Compact style={{ width: '100%' }}>
                        <Button type="primary" style={{backgroundColor:"#008835", width: "100%", height: "fit-content", textWrap:"pretty"}} content="wow" onClick={refreshChat}>
                            Chat ended. Click to start a new chat.
                        </Button>
                    </Space.Compact>
                }
            </Modal>
        </>
    )
}

export default ChatBox