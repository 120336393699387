import React, { useEffect } from "react"
import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Link, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

const TermsConditions = () => {

    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            <SideHeaders />

            <div className="lg:pt-[60px] lg:pb-[60px] pb-[180px]">
                <div className="theme-container mx-auto">
                    <div className="body-content-wrapper lg:col-span-8 mb-10 lg:mb-0">
                        <div className="grid items-center">
                            <div className="article-area">
                                <h2 className="headline-default text-primary-900">
                                    Terms and Conditions
                                </h2>
                                <p className="sm:text-sm sm:leading-8 text-sm font-bold spline-sans text-primary-500 mb-5">
                                    Last Updated: 18th January, 2024
                                </p>
                                <p className="text-base leading-[27px] text-primary-100 mb-[20px]">
                                    These terms and conditions outline the rules and regulations for the use 
                                    of WizHull's Website, located at www.wizhull.com. By accessing this 
                                    website we assume you accept these terms and conditions. Do not continue 
                                    to use www.wizhull.com if you do not agree to take all of the terms and 
                                    conditions stated on this page.
                                </p>

                                <p className="items-center pb-[5px]">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        License
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    Unless otherwise stated, WizHull and/or its licensors 
                                    own the intellectual property rights for all material 
                                    on WizHull. All intellectual property rights are 
                                    reserved. You may access this from WizHull for your 
                                    own personal use subjected to restrictions set in 
                                    these terms and conditions.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        User Comments
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    This Agreement shall begin on the date hereof. Certain 
                                    parts of this website offer the opportunity for users 
                                    to post and exchange opinions and information in 
                                    certain areas of the website. WizHull does not 
                                    filter, edit, publish or review Comments prior to 
                                    their presence on the website. Comments do not reflect 
                                    the views and opinions of WizHull,its agents and/or 
                                    affiliates. To the extent permitted by applicable 
                                    laws, WizHull shall not be liable for the Comments 
                                    or for any liability, damages or expenses caused 
                                    and/or suffered as a result of any use of and/or 
                                    posting of and/or appearance of the Comments on 
                                    this website.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Hyperlinking to our Content
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    The following organizations may link to our Website 
                                    without prior written approval: Government agencies; 
                                    Search engines; News organizations; Online directory 
                                    distributors may link to our Website in the same 
                                    manner as they hyperlink to the Websites of other 
                                    listed businesses; and System wide Accredited 
                                    Businesses except soliciting non-profit organizations, 
                                    charity shopping malls, and charity fundraising 
                                    groups which may not hyperlink to our Web site.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Content Liability
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We shall not be hold responsible for any content that 
                                    appears on your Website. You agree to protect and 
                                    defend us against all claims that is rising on your 
                                    Website. No link(s) should appear on any Website 
                                    that may be interpreted as libelous, obscene or 
                                    criminal, or which infringes, otherwise violates, 
                                    or advocates the infringement or other violation 
                                    of, any third party rights.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                    Your Privacy
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    Please read <strong><Link to="/privacy-policy">Privacy Policy</Link></strong>
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Reservation of Rights
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We reserve the right to request that you remove all 
                                    links or any particular link to our Website. You 
                                    approve to immediately remove all links to our website 
                                    upon request. We also reserve the right to amen 
                                    these terms and conditions and it’s linking policy 
                                    at any time. By continuously linking to our Website, 
                                    you agree to be bound to and follow these linking 
                                    terms and conditions.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Removal of links from our website
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    If you find any link on our Website that is offensive 
                                    for any reason, you are free to contact and inform 
                                    us any moment. We will consider requests to remove 
                                    links but we are not obligated to or so or to respond 
                                    to you directly.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Disclaimer
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    To the maximum extent permitted by applicable law, we 
                                    exclude all representations, warranties and 
                                    conditions relating to our website and the use of 
                                    this website. Nothing in this disclaimer will:
                                    <ul className="pl-3">
                                        <li>- limit or exclude our or your liability for 
                                            death or personal injury;
                                        </li>
                                        <li>- limit or exclude our or your liability for 
                                            fraud or fraudulent misrepresentation;
                                        </li>
                                        <li>- limit any of our or your liabilities in 
                                            any way that is not permitted under applicable 
                                            law; or
                                        </li>
                                        <li>- exclude any of our or your liabilities 
                                            that may not be excluded under applicable law.
                                        </li>
                                    </ul>
                                </p>
                                <p className="text-base leading-[27px] py-2 text-primary-100">
                                    The limitations and prohibitions of liability set 
                                    in this Section and elsewhere in this disclaimer: 
                                    (a) are subject to the preceding paragraph; and 
                                    (b) govern all liabilities arising under the 
                                    disclaimer, including liabilities arising in 
                                    contract, in tort and for breach of statutory duty.
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    We will not be liable for any loss or damage of any nature.
                                </p>

                                <p className="items-center pb-[5px] pt-5">
                                    <span className="text-lg font-bold spline-sans leading-7 text-primary-900">
                                        Contact Us
                                    </span>
                                </p>
                                <p className="text-base leading-[27px] text-primary-100">
                                    If you have any queries regarding any of our terms, 
                                    please contact us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SideFooter />
        </>
    )
}

export default TermsConditions