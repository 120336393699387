import React, { createContext, useContext, useState } from 'react';

// Create a context
const ChatProviderContext = createContext()

// Create a provider component
export function ChatProvider({ children }) {
  const [value, setValue] = useState('');

  return (
    <ChatProviderContext.Provider value={{ value, setValue }}>
      {children}
    </ChatProviderContext.Provider>
  );
}

// Create a custom hook for consuming the context
export function useChatProvider() {
  return useContext(ChatProviderContext)
}