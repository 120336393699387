import React, { useEffect, useState } from "react"
import useMessage from "antd/es/message/useMessage"
import { sendContact } from "../Actions/commonController"
import ReactGA from 'react-ga'

import SideHeaders from "../Component/Headers/SideHeaders"
import SideFooter from "../Component/Footers/SideFooter"
import { Spin } from "antd"
import { Link, useLocation } from "react-router-dom"


const initialValue = {
    name: "",
    phone: "",
    email: "",
    message: ""
}

const Contact = () => {
    const [lead, setLead] = useState(false)
    const [messageApi, messageTrigger] = useMessage()
    const [loader, setLoader] = useState(false)
    const [formValue, setFormValue] = useState(initialValue)
    const { name, phone, email, message } = formValue

    const successMessage = () => {
        messageApi.open({
            type: 'success',
            content: 'Your request has been submitted successfully! You will be receiving a call shortly regarding your inquiry.',
            duration: 7,
        })
    }

    const loadingMessage = () => {
        messageApi.open({
            type: 'loading',
            content: 'Submitting your details...',
            duration: 3,
        })
    }

    const errorMessage = () => {
        messageApi.open({
          type: 'error',
          content: 'There was an error while submitting the form.',
        })
    }

    const handleChange = (e) => {
        try {
            let { name, value } = e.target;
            setFormValue({ ...formValue, [name]: value });
        } catch (err) {
            console.log("An error has occured when inputting data in the form", err.message)
        }
    }

    const handleFormSubmit = async (e) => {
        try {
            ReactGA.event({
                category: 'Button Click',
                action: 'Clicked on form submit',
                label: 'Contact Page',
            })
            e.preventDefault()
            setLoader(true)
            loadingMessage()

            let formData = formValue
            let data = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                message: formData.message,
                page: "contact"
            }

            let { status } = await sendContact(data)

            if(status == "success") {
                setLead(true)
                messageApi.destroy()
                successMessage()
            } else {
                messageApi.destroy()
                errorMessage()
            }

            setLoader(false)
        } catch(err) {
            console.log("An error has occured when submitting form!", err.message)
        }
    }
    
    // Scroll to top
    useEffect(() => { window.scrollTo(0, 0); }, [])

    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return (
        <>
            {messageTrigger}
            <SideHeaders />

            <main className="w-full min-h-screen">
                <section className="page-title-wrapper w-full h-[345px]">
                    <div className="theme-container mx-auto h-full">
                    <div
                        className="w-full h-full flex sm:flex-row flex-col sm:justify-between justify-center space-y-3 sm:space-y-0 items-center"
                    >
                        <h2 className="headline-default text-primary-900">Contact</h2>
                        <ul className="breadcrumb-wrapper flex space-x-5 items-center">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                <div className="flex space-x-5 items-center">
                                    <span
                                    className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                    >Home</span
                                    >
                                    <span className="ico">
                                    <svg
                                        width="9"
                                        height="13"
                                        viewBox="0 0 9 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                        fill="#028835"
                                        />
                                    </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <div className="flex space-x-5 items-center">
                                <span
                                className="sm:text-lg text-primary-500 sm:leading-[27px] text-sm font-semibold spline-sans"
                                >Contact</span >
                                <span className="ico">
                                <svg
                                    width="9"
                                    height="13"
                                    viewBox="0 0 9 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M2 12.5C1.71875 12.5 1.46875 12.4062 1.28125 12.2188C0.875 11.8438 0.875 11.1875 1.28125 10.8125L5.5625 6.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L7.6875 5.8125C8.09375 6.1875 8.09375 6.84375 7.6875 7.21875L2.6875 12.2188C2.5 12.4062 2.25 12.5 2 12.5Z"
                                    fill="#028835"
                                    />
                                </svg>
                                </span>
                            </div>
                        </li>
                        </ul>
                    </div>
                    </div>
                </section>

                <div
                    className="faq-page-wrapper lg:pt-[120px] lg:pb-[240px] pt-[60px] pb-[120px]"
                >
                    <div className="theme-container mx-auto">
                    <div className="grid sm:grid-cols-3 grid-cols-1 lg:gap-[30px] gap-5">
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="100"
                        style={{
                            boxShadow: "0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03)"}}
                        className="item w-full"
                        >
                        <div
                            className="w-full lg:h-[324px] h-[290px] relative overflow-hidden xl:bg-white bg-primary-50 rounded"
                        >
                            <div className="flex justify-center">
                            <div>
                                <div
                                className="mt-[50px] xl:mb-[90px] mb-10 flex justify-center relative z-10"
                                >
                                <span>
                                    <svg
                                    width="30"
                                    height="41"
                                    viewBox="0 0 30 41"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M13.125 39.0625C9.0625 33.9844 0 21.875 0 15C0 6.71875 6.64062 0 15 0C23.2812 0 30 6.71875 30 15C30 21.875 20.8594 33.9844 16.7969 39.0625C15.8594 40.2344 14.0625 40.2344 13.125 39.0625ZM15 20C17.7344 20 20 17.8125 20 15C20 12.2656 17.7344 10 15 10C12.1875 10 10 12.2656 10 15C10 17.8125 12.1875 20 15 20Z"
                                        fill="#028835"
                                    />
                                    </svg>
                                </span>
                                </div>
                                <p
                                className="sm:text-[30px] font-semibold spline-sans sm:leading-[36px] text-2xl text-center text-primary-900 mb-3"
                                >
                                Address
                                </p>
                                <p
                                className="text-base leading-[27px] text-primary-100 text-center"
                                >
                                    Office 112, 3rd Floor, Oasis Mall offices <br />
                                    Sheikh Zayed Road - Dubai
                                </p>
                                <div
                                className="w-[300px] h-[300px] rounded-full bg-primary-50 absolute left-[55px] bottom-[174px] xl:block hidden"
                                ></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="300"
                        style={{ boxShadow: "0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03)" }}
                        className="item w-full"
                        >
                        <div
                            className="w-full lg:h-[324px] h-[290px] relative overflow-hidden xl:bg-white bg-primary-50 rounded"
                        >
                            <div className="flex justify-center">
                            <div>
                                <div
                                className="mt-[50px] xl:mb-[90px] mb-10 flex justify-center relative z-10"
                                >
                                <span>
                                    <svg
                                    width="40"
                                    height="30"
                                    viewBox="0 0 40 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M36.25 0C38.2812 0 40 1.71875 40 3.75C40 5 39.375 6.09375 38.4375 6.79688L21.4844 19.5312C20.5469 20.2344 19.375 20.2344 18.4375 19.5312L1.48438 6.79688C0.546875 6.09375 0 5 0 3.75C0 1.71875 1.64062 0 3.75 0H36.25ZM16.9531 21.5625C18.75 22.8906 21.1719 22.8906 22.9688 21.5625L40 8.75V25C40 27.8125 37.7344 30 35 30H5C2.1875 30 0 27.8125 0 25V8.75L16.9531 21.5625Z"
                                        fill="#028835"
                                    />
                                    </svg>
                                </span>
                                </div>
                                <p
                                className="sm:text-[30px] font-semibold spline-sans sm:leading-[36px] text-2xl text-center text-primary-900 mb-3"
                                >
                                E-mail
                                </p>
                                <p
                                className="text-base leading-[27px] text-primary-100 text-center"
                                >
                                info@wizhull.com
                                </p>
                                <div
                                className="w-[300px] h-[300px] rounded-full bg-primary-50 absolute left-[55px] bottom-[174px] xl:block hidden"
                                ></div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500"
                        style={{ boxShadow: "0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03)" }}
                        className="item w-full"
                        >
                        <div
                            className="w-full lg:h-[324px] h-[290px] relative overflow-hidden xl:bg-white bg-primary-50 rounded"
                        >
                            <div className="flex justify-center">
                            <div>
                                <div
                                className="mt-[50px] xl:mb-[90px] mb-10 flex justify-center relative z-10"
                                >
                                <span>
                                    <svg
                                    width="41"
                                    height="42"
                                    viewBox="0 0 41 42"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M39.9219 31.2344L38.0469 39.125C37.8125 40.2969 36.875 41.0781 35.7031 41.0781C16.0156 41 0 24.9844 0 5.29688C0 4.125 0.703125 3.1875 1.875 2.95312L9.76562 1.07812C10.8594 0.84375 12.0312 1.46875 12.5 2.48438L16.1719 11C16.5625 12.0156 16.3281 13.1875 15.4688 13.8125L11.25 17.25C13.9062 22.6406 18.2812 27.0156 23.75 29.6719L27.1875 25.4531C27.8125 24.6719 28.9844 24.3594 30 24.75L38.5156 28.4219C39.5312 28.9688 40.1562 30.1406 39.9219 31.2344Z"
                                        fill="#028835"
                                    />
                                    </svg>
                                </span>
                                </div>
                                <p
                                className="sm:text-[30px] font-semibold spline-sans sm:leading-[36px] text-2xl text-center text-primary-900 mb-3"
                                >
                                Call us
                                </p>
                                <p
                                className="text-base leading-[27px] text-primary-100 text-center"
                                >
                                +971 4 873 0210
                                </p>
                                <div
                                className="w-[300px] h-[300px] rounded-full bg-primary-50 absolute left-[55px] bottom-[174px] xl:block hidden"
                                ></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <section className="w-full h-[650px] xl:-mt-[80px] mt-[60px]">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.550657659956!2d55.26191737581754!3d25.18464597771898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d22422ff83%3A0xc88d7cd3cacb721a!2sSilver%20Tower%20-%20Business%20Bay%20-%20Dubai!5e0!3m2!1sen!2sae!4v1707393465054!5m2!1sen!2sae"
                        style={{ border: "0", width: "100%", height: "100%" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    </section>
                    <section className="w-full lg:pt-[120px] pt-[60px] pb-[120px] lg:pb-0">
                    <div className="theme-container mx-auto">
                        <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                        <div className="mb-10 pr-[70px] lg:mb-0">
                            <p
                            className="text-primary-500 font-bold lg:leading-8 spline-sans lg:text-xl text-lg uppercase mb-2"
                            >
                            GET IN TOUCH
                            </p>
                            <h2 className="headline-default text-primary-900 mb-[30px]">
                            Your Reliable Ally in <br />Real Estate Financing
                            </h2>
                            <p className="text-base leading-[27px] text-primary-100">
                                In the intricate world of property investment, clarity and
                                trust are paramount. Wizhull stands as your steadfast guide,
                                ensuring that every step towards securing your mortgage
                                is marked by transparency and tailored advice. Our commitmen 
                                is to simplify the mortgage process, making it a straightforward
                                and stress-free experience. With Wizhull, you're not just
                                finding a loan; you're embarking on a clear path to homeownership,
                                supported by experts dedicated to protecting your interests
                                and fulfilling your dreams.
                            </p>
                        </div>
                        {
                            lead ?
                                <h2 className="headline-default text-primary-900 font-bold">
                                    Form Submitted Successfully!
                                </h2>
                            :
                                <div className="w-full">
                                    <div className="sm:flex sm:space-x-[30px] w-full mb-5">
                                        <label className="block w-full mb-5 sm:mb-0" for="Name">
                                            <input
                                                name="name"
                                                value={name}
                                                onChange={handleChange}
                                                type="text"
                                                placeholder="Your name"
                                                className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                            />
                                        </label>
                                        <label className="block w-full" for="email">
                                            <input
                                                name="email"
                                                type="email"
                                                value={email}
                                                onChange={handleChange}
                                                placeholder="E-mail"
                                                className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                            />
                                        </label>
                                    </div>
                                    <div className="sm:flex sm:space-x-[30px] w-full mb-5">
                                        <label className="block w-full mb-5 sm:mb-0" for="Name">
                                            <input
                                                name="phone"
                                                value={phone}
                                                onChange={handleChange}
                                                type="text"
                                                placeholder="Your Phone Number"
                                                className="w-full h-[68px] rounded border border-primaryBorder focus:outline-none focus:ring-0 px-5 placeholder:text-primary-100 text-black"
                                            />
                                        </label>
                                    </div>
                                    <div className="w-full h-[174px] mb-5">
                                        <textarea
                                            name="message"
                                            value={message}
                                            onChange={handleChange}
                                            id="message"
                                            placeholder="Write your message"
                                            className="w-full h-full rounded border border-primaryBorder focus:outline-none focus:ring-0 p-5 placeholder:text-primary-100 text-black"
                                        ></textarea>
                                    </div>
                                    <div>
                                        {
                                            !loader ?
                                                <div className="rounded bg-primary-500 hover:bg-primary-900 common-trans focus:outline-none focus:ring-0 w-full h-[68px] text-white flex justify-center items-center" onClick={handleFormSubmit}>
                                                    <span className="text-lg font-semibold spline-sans text-white">
                                                        Submit Now
                                                    </span>
                                                </div>
                                            :
                                                <div className="rounded bg-primary-500 hover:bg-primary-900 common-trans focus:outline-none focus:ring-0 w-full h-[68px] text-white flex justify-center items-center">
                                                    <span className="text-lg font-semibold spline-sans text-white">
                                                        <Spin />
                                                    </span>
                                                </div>
                                        }
                                    </div>
                                </div>
                        }
                        </div>
                    </div>
                    </section>
                </div>
            </main>

            <SideFooter />
            </>
    )
}

export default Contact