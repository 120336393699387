import React from 'react';
import { Link } from 'react-router-dom';
import whiteLogo from '../../assets/img/logoWhite.png'

const MiniBar = ({ active, changeActive }) => {
    return (
        <div className={`hamMenu ${active}`}>
            <div className='hamCancel' onClick={() => {changeActive()}}>
                X
            </div>
            <div className='hamImage'>
                <img src={whiteLogo} width={125}/>
            </div>
            <div className='hamContent'>
                <Link to="/home">Home</Link>
                <Link to="/about">About us</Link>
                <Link to="/services">Services</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/calculator">Mortgage Calculator</Link>
            </div>
        </div>
    )
}

export default MiniBar